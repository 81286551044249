import TYPES from './DatasetActionTypes';

const initialState = {
  active: undefined,
  dataset: undefined
}

const DatasetReducer = (state=initialState, action) => {
  switch(action.type){
    case TYPES.FETCH_DATASETS_SUCCESS:
      return Object.assign({}, state, {
        dataset: {...state.dataset, ...action.dataset}
      });
    case TYPES.UPDATE_DATASET_SUCCESS:
      // console.log('Reducer',state, action)
      let currentBo = action.dataset || action.payload.data;
      if(state.dataset){
        let newD = state.dataset.data.map(data => data.id === currentBo.id ? currentBo : data);
        return {...state, dataset: {data: newD, meta: state.dataset.meta}, active: currentBo}
      } else {
        return {...state, dataset: state.dataset, active: currentBo}
      }

    case TYPES.FETCH_DATASET_SUCCESS:
      return Object.assign({}, state, {
        active: action.dataset,
        dataset: state.dataset
      });
    case TYPES.CREATE_NEW_DATASET_SUCCESS:
      console.log(action.payload.data, state.dataset)
      let newData  = state.dataset;
      let tempMeta = JSON.parse(JSON.stringify(state.dataset.meta));
      tempMeta.totalCount+=1 ;
      tempMeta.count+=1 ;
      newData.data.unshift(action.payload.data)
      return Object.assign({}, state, {
        active: action.payload.data,
        dataset: {data:newData.data, meta: tempMeta}
      })
    case TYPES.DELETE_DATASET:
      console.log('ReducerStart',state, action)
      let tempStore = state.dataset
      let delTempTeam = JSON.parse(JSON.stringify(tempStore.meta));
      delTempTeam.totalCount-=1;
      delTempTeam.count-=1;
      let tempData = tempStore.data.filter(element => element.id !== action.id)
        let data = {data: tempData, meta: delTempTeam}
      console.log('ReducerEnd',data)
      return {...state, dataset:data, active: state.active};
    default:
      return state;
  }
}

export default DatasetReducer;
