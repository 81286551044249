import React, { Component } from 'react';
import RGL, { WidthProvider } from "react-grid-layout";
import BarChartType from './chart/barChart'
import PieChartType from './chart/pieChart'
import LineChartType from './chart/lineChart'
import {Icon} from 'antd';

const ReactGridLayout = WidthProvider(RGL);
export default class GridLayout extends Component {
  
  	state = {
		reports : null,
		layout 	: [
			// {i: '1', x: 0, y: 0, w: 8, h:20, minH:10, minW:6 },         // *** -- minH & maxH doesnt effect the grid items
			// {i: '2', x: 8, y: 0, w: 8, h: 20, minH: 10, minW: 6},
			// {i: '3', x: 16, y: 0, w: 8, h: 20, minH: 10, minW: 6},
		]
  	};

	componentDidMount()
	{
		let activePage = this.props.activePage
		if(activePage && activePage.draw_data){
			if(activePage.draw_data.layout && activePage.draw_data.layout.length > 0){
				this.setState({layout:activePage.draw_data.layout});
			}

		}
	}

	componentDidUpdate(nxtProps){
		// console.log('Nitish------->',this.props.pageData,nxtProps.pageData)
		let points;
		let _x;
		if(this.props.pageData && nxtProps.pageData){
			if(this.props.pageData.reports && this.props.pageData.reports.length > 0){
				// if(nxtProps.pageData.reports && nxtProps.pageData.reports.length > 0){
					if(JSON.stringify(this.props.pageData.reports) !== JSON.stringify(nxtProps.pageData.reports) ){
						points 	= [];
						_x		= 0;
						this.props.pageData.reports.map(report=>{
							// let _find = this.state.layout.find(pt=> pt.i === report.id);
							// if(_find){
							// 	return false;
							// }
							if(_x === '24')_x = 0;
							points.push({
								i 		: report.report.data.id,
								x		: _x,
								y		: 0,
								w		: 12,
								h 		: 20,
								minH 	:10,
								minW	:6
							})
							_x = _x+12;
						})
						this.setState({layout:[...this.state.layout, ...points]});
						this.props.changeLayout(points);
						// console.log('---Points----',points)
					}
				// }
			}
		}
	}

	onLayoutChange = (layout) => {
		this.setState({layout});
		this.props.changeLayout(layout);
	}
  
	onResize = (layout) => {
		this.setState({layout: layout});
		this.props.changeLayout(layout);
	};

	onSelectGraph = (e) => {
	    this.props.setActiveTab('Chart');
	    this.props.onSelectGraph(e);
    }
  
  	render() {
		let {pageData,onDeleteChartData} = this.props;
		// console.log('pageData...',pageData)
		if(!pageData || !pageData.reports){
			return null;
		}
	  	let graph = pageData.reports && pageData.reports.map(report=>{
	  		console.log('-----------', report)
			let id = report.report.data.id;
			let data = report.report_result ? report.report_result.data : [];
			let reportName = report.report.data.name;
			// console.log('Report',report)
			switch(report.report.data.chart){
				case 'Bar':
					return <div className='item gridDragHandler' key={id}  >
						<span className='editChart'>
							<Icon type='edit' id={id} onClick={this.onSelectGraph} />
							<Icon type='delete' id={id} onClick={onDeleteChartData}/>
						</span>
						<div className='report-name'>{reportName}</div>
						<BarChartType data={data}/>
					</div>
				case 'Line':
					return <div className='item gridDragHandler' key={id}>
						<span className='editChart'>
							<Icon type='edit' id={id} onClick={this.onSelectGraph} />
							<Icon type='delete' id={id} onClick={onDeleteChartData}/>
						</span>
						<div className='report-name'>{reportName}</div>
						<LineChartType data={data}/>
					</div>
				case 'Pie':
					return <div className='item gridDragHandler' key={id}>
						<span className='editChart'>
							<Icon type='edit' id={id} onClick={this.onSelectGraph} />
							<Icon type='delete' id={id} onClick={onDeleteChartData}/>
						</span>
						<div className='report-name'>{reportName}</div>
						<PieChartType data={data}/>
					</div>

				default:
					break;
			}
	  })

	  return (
        <ReactGridLayout
            rowHeight		= {10}
            cols			= {24}
            onResize		= {this.onResize}
            width			= {10}
            layout			= {this.state.layout}
			onLayoutChange	= {this.onLayoutChange}
            draggableHandle	= ".gridDragHandler"
			draggableCancel	= ".MyDragCancel"
        >
			{graph}
        </ReactGridLayout>
    );
  }
}