import React from 'react'
import {Input, Form, Modal, Button} from 'antd';
import { showNotification } from '../../../utils/commonFunctions';

class DatasetCreatorModal extends React.Component {

  state = {
    // showCreateFromTemplate: true,
    name: undefined,
    description: undefined,
    nameInvalid: true,
    descriptionInvalid: true,
    loading : false
  }

  componentDidMount() {
    // this.props.fetchProjectTemplates && this.props.fetchProjectTemplates();
  }
  //
  // showCreateNew = () => {
  //   this.setState({
  //     showCreateFromTemplate: false
  //   });
  // }
  //
  // showCreateFromTemplate = () => {
  //   this.setState({
  //     showCreateFromTemplate: true
  //   });
  // }

  setName = (e) => {
    let testRegExp = new RegExp('^[a-zA-Z0-9 ]*$');
    let nameInvalid = true;
    if (e.target.value && testRegExp.test(e.target.value)) {
      nameInvalid = false;
    }
    this.setState({
      name: e.target.value,
      nameInvalid,
    })
  }

  setDescription = (e) => {
    let descriptionInvalid = true;
    if (e.target.value) {
      descriptionInvalid = false;
    }
    this.setState({
      description: e.target.value,
      descriptionInvalid
    })
  }

  renderCreateNewForm = () => {
    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      labelAlign: "left"
    };

    const { nameInvalid, name, description } = this.state;

    return (
      <Form {...formLayout}>
        <Form.Item
          label={<span className='text-color'>Name <span style={{color: "#FF3D57"}}>*</span></span>}
          colon={false}
          validateStatus={name && nameInvalid ? "error" : "success"}
          help={name && nameInvalid && "Should be combination of numbers & alphabets only"}
        >
          <Input placeholder="Reimbursement" onChange={this.setName} size='large' className='custom-input' value={name} maxLength={50}/>
        </Form.Item>
        <Form.Item label={<span className='text-color'>Description</span>} style={{marginBottom:'0'}} colon={false}>
          <Input.TextArea
            onChange={this.setDescription}
            autosize={{maxRows: 10, minRows: 4}}
            value={description}
            placeholder="All your expenses reversed by us."
            className='custom-input'
          />
        </Form.Item>
        {/* <Form.Item label={<span className='text-color'>Category</span>} style={{marginBottom:'0'}} colon={false}>
          <Select
              getPopupContainer={(e)=>e.parentNode}
              placeholder="Select Category"
              className='custom-input'
              optionFilterProp="children"
              filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            <Select.Option value="Value">value</Select.Option>
          </Select>
        </Form.Item> */}
      </Form>
    );
  }

  // createFromTemplate = (index) => {
  //   const { templates } = this.props;
  //   console.log(templates[index]);
  //   this.props.createNewProject && this.props.createNewProject({
  //     data: {
  //       attributes: Object.assign({}, templates[index].attributes, {
  //         template: true
  //       })
  //     }
  //   })
  //   this.setState({
  //     showCreateFromTemplate: true,
  //     name: undefined,
  //     description: undefined,
  //     nameInvalid: true,
  //     descriptionInvalid: true,
  //   });
  // }

  createNewProject = () => {
    if(this.state.name.trim() === '') {
      showNotification('error', 'Dataset name cannot be blank')
      return false;
    }
    if(this.state.nameInvalid){
      showNotification('error', 'Special symbols not allowed')
      return false;
    }
    this.setState({loading:true})
    let _this = this
    setTimeout(function(){
      _this.props.createNewProject && _this.props.createNewProject({
        data: {
            name: _this.state.name,
            description: _this.state.description,
            category : []
        }
      });
      _this.setState({
        // showCreateFromTemplate: true,
        name: undefined,
        description: undefined,
        nameInvalid: true,
        descriptionInvalid: true,
        loading:false
      });
    },1000)
    
  }

  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        className="custom-modal"
        title={<span>Create Dataset</span>}
        onCancel={onCancel}
        footer={
          <>
            {<Button
              type="primary"
              style={{border: "none"}}
              onClick={this.createNewProject}
              loading={this.state.loading}
            >
                Create New
            </Button>}
          </>
        }
      >{this.renderCreateNewForm()}
      </Modal>
    )
  }
}

export default DatasetCreatorModal;
