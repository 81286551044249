import React, { Component } from 'react'
import {Row, Collapse} from 'antd'
// import moment from 'moment'
import RGL, { WidthProvider } from "react-grid-layout";
import Textbox from './fieldComponents/textbox';
import Label from './fieldComponents/label';
import Number from './fieldComponents/number';
import TextArea from './fieldComponents/textarea';
import DropdownSelect from './fieldComponents/dropdown';
import SingleSelectDate from './fieldComponents/singleSelectDate';
import File from './fieldComponents/file';
import Dataset from './fieldComponents/dataset'
import People from './fieldComponents/people'

const ReactGridLayout = WidthProvider(RGL);

export default class FormSections extends Component {

    addValue = (fieldId, val) => {
        let activeTask = Object.assign({}, this.props.activeTask)
        if(activeTask.fields){
            if(activeTask.fields[fieldId]){
                activeTask.fields[fieldId] = {value: {row_id: val}}
            }else{
                activeTask.fields[fieldId] = {
                    value : {
                        row_id : val
                    }
                }
            }
        }else{
            activeTask.fields = {
                [fieldId] : {
                    value : {
                        row_id : val
                    }
                }
            }
        }
        this.props.updateTask(activeTask)
    }

    render() {
        let { section, sectionFields, activeTask, mandatoryField, showErrorAlert, sectionIds } = this.props;
        // console.log("sectionFields", section, sectionFields);

        return (
            <Row>
                {
                    section &&
                        <>
                            {
                                //     <Row className='f-20' style={{lineHeight: '23px',color: 'rgb(68, 86, 108)',padding: '5px 10px'}}>{section.section_name}</Row>
                                //     <Row style={{fontSize:'12px',lineHeight: '23px',color: 'rgb(68, 86, 108)',padding: '0 10px'}}>{section.section_description}</Row>
                                // section.section_name.trim().toLowerCase() !== 'untitled section' &&
                                    <Collapse expandIconPosition='right' bordered='false' className='mb-10' defaultActiveKey={sectionIds}>
                                        <Collapse.Panel style={{background:'#F8FAFB'}} key={section.id} header={<div>
                                                {
                                                    section.section_name.trim().toLowerCase() &&
                                                    section.section_name.trim().toLowerCase() !== 'untitled section'
                                                    ? <div className='f-16 b'>{section.section_name}</div>
                                                    : <div className='f-16 b'>Untitled section</div>
                                                }
                                                {section.section_description.trim().toLowerCase() !== 'section information' && <div className='f-12'>{section.section_description}</div>}
                                            </div>}
                                        >
                                            {/* <ReactGridLayout
                                                rowHeight		= {10}
                                                cols			= {24}
                                                isDraggable     = {false}
                                                isResizable     = {false}
                                                width			= {10}
                                                layout			= {section.section_layout}
                                            > */}
                                                {
                                                    sectionFields.map(field=>{
                                                        let showError = mandatoryField && mandatoryField.includes(field.id);
                                                        switch(field.dataType){
                                                            case 'url' :
                                                            case 'telephone' :
                                                            case 'text' :
                                                            case 'email' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <Textbox
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}

                                                                        />
                                                                    </div>
                                                                )
                                                            case 'number' :
                                                                return (
                                                                <div className='item gridDraghandler mb-15' key={field.id}>
                                                                    <Label
                                                                        name      = {field.alias_name ? field.alias_name : field.name}
                                                                        mandatory = {field.mandatory}
                                                                    />
                                                                    <br/>
                                                                    <Number
                                                                        activeTask  = {activeTask}
                                                                        field       = {field}
                                                                        addValue    = {this.addValue}
                                                                        showError    = {showError}
                                                                        showErrorAlert={showErrorAlert}
                                                                    />
                                                                </div>
                                                            )
                                                            case 'dropdown' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <DropdownSelect
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                        />
                                                                    </div>
                                                                )
                                                            case 'textarea' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <TextArea
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                        />
                                                                    </div>
                                                                )
                                                            case 'file' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <br/>
                                                                        <File
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            attachments = {this.props.attachments}
                                                                            deleteAttachment = {this.props.deleteAttachment}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                            fetchAttachments={this.props.fetchAttachments}
                                                                        />
                                                                    </div>
                                                                )
                                                            case 'date' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <br/>
                                                                        <SingleSelectDate
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                        />

                                                                    </div>
                                                                )
                                                            case 'dataset' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <Dataset
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                        />
                                                                    </div>
                                                                )
                                                            case 'people' :
                                                                return (
                                                                    <div className='item gridDraghandler mb-15' key={field.id}>
                                                                        <Label
                                                                            name      = {field.alias_name ? field.alias_name : field.name}
                                                                            mandatory = {field.mandatory}
                                                                        />
                                                                        <People
                                                                            activeTask  = {activeTask}
                                                                            field       = {field}
                                                                            addValue    = {this.addValue}
                                                                            showError    = {showError}
                                                                            showErrorAlert={showErrorAlert}
                                                                        />
                                                                    </div>
                                                                )
                                                            default: return <div />;
                                                        }
                                                    })
                                                }
                                            {/* </ReactGridLayout> */}
                                        </Collapse.Panel>
                                    </Collapse>
                            }
                        </>
                }
            </Row>
        )
    }
}
