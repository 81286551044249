import React from 'react';
import FieldsList from "./FieldsList";
import {Input, Row} from "antd";

const XaxisSection = (props) => {
    return (
        <Row style={props.rowStyle}>
            <label className='f15' style={{color:'#44566C'}}>X-axis</label>
            <FieldsList fetchingFields={props.fetchingFields} fieldList={props.fieldList} mode="single" onSelectFields={props.onSelectFields} selectedFields={props.selectedFields}/>
            <Input placeholder="X-axis label" className='custom-input white' size='large' allowClear value={props.xAxisLabel} onChange={e=>props.onChangeLabel(e,"xAxis")}/>
        </Row>
    );
};

export default XaxisSection;
