import React, { Component } from 'react'
import {Row} from 'antd'

export default class Wwitch extends Component {

    state = {
        active : 'readonly',
        sectionShow: this.props.properties.show,
        sectionOpen: this.props.properties.open
    }

    componentDidMount(){
        if(this.props.properties.show){
            this.setState({active: this.props.properties.editable ? 'editable' : 'readonly'})
        }else{
            this.setState({active:'hidden'})
        }
    }

    change = (val) => {
        if(!this.props.disabled){
            this.setState({active:val})
            let obj = {show:true,editable:false}
            if(val === 'hidden'){
                obj.show = false
            }else{
                obj.editable = val === 'editable' ? true : false
            }
            this.props.setFieldProperties(obj, this.props.id, this.props.isSection)
        }
    }

    toggleSectionVisibility = () => {
        this.setState({sectionShow: !this.state.sectionShow})
        let obj = Object.assign({}, this.props.properties)
        obj.show = !this.state.sectionShow
        this.props.setFieldProperties(obj, this.props.id, this.props.isSection)
    }

    toggleSectionCollapse = () => {
        this.setState({sectionOpen: !this.state.sectionOpen})
        let obj = Object.assign({}, this.props.properties)
        obj.open = !this.state.sectionOpen
        this.props.setFieldProperties(obj, this.props.id, this.props.isSection)
    }

    render() {
        const {isSection} = this.props
        const {active, sectionShow, sectionOpen} = this.state
        return (
            <>
            {
                !isSection &&
                    <Row type='flex' className='custom-switch'>
                        <div className={active === 'readonly' ? 'active' : ''} onClick={e=>this.change('readonly')}>Read-Only</div>
                        <div className={active === 'editable' ? 'active' : ''} onClick={e=>this.change('editable')}>Editable</div>
                        <div className={active === 'hidden' ? 'active' : ''} onClick={e=>this.change('hidden')}>Hidden</div>
                    </Row>
            }
            {
                isSection && <>
                    <Row type='flex' className='custom-switch'>
                        <div className={sectionShow ? 'active' : ''} onClick={this.toggleSectionVisibility}>Show</div>
                        <div className={sectionShow ? '' : 'active' } onClick={this.toggleSectionVisibility}>Hide</div>
                    </Row>
                    <Row type='flex' className='custom-switch ml-10'>
                        <div className={sectionOpen ? 'active' : ''} onClick={this.toggleSectionCollapse}>Open</div>
                        <div className={sectionOpen ? '' : 'active'} onClick={this.toggleSectionCollapse}>Close</div>
                    </Row></>
            }
            </>
        )
    }
}
