import React from 'react';
import {Row} from 'antd'

const FilePreviewBody = (props) => {
    return  <td style={{width:'80%'}}>
                <Row type='flex' justify='center' align='middle' id='contentPreview' className='fh fw'>
                    {props.contentToDisplay}
                </Row>
            </td>
}

export default FilePreviewBody;
