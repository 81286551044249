import React, { Component } from 'react'
import {Row, Col, Switch} from 'antd'
import Configure from './configure'

const actionName = {
    'assigned'    : 'Assignment',
    'rejected'    : 'Rejection',
    'submitted'   : 'Submission',
    'stopped'     : 'Stop',
}
export default class Triggers extends Component {

    state = {
        configure   : false,
        activeStage : null,
        text        : null
    }

    setTrigger = (checked,stage, val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[stage][val].status = checked ? 'default' : 'off'
        this.props.updateSteps(steps) 
    }

    configure = (flag, stage, val) => {
        this.setState({configure: flag, activeStage: stage, text: val})
    }

    render() {
        const {activeStep, updateSteps, steps, allFields}   = this.props
        const {activeStage, configure, text}                = this.state
        // console.log(activeStep)
        return (
            <>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500'}} className='mt-20 mb-20'>
                    Add triggers to this task?
                </Row>
                {
                    !activeStage && !configure &&
                        <>
                            <Row style={{padding:30}} type='flex' align='middle'>
                                <Col span={6} style={{color:'#56667A',textShadow:'0 0',fontSize:16}}>Action</Col>
                                <Col span={6} className='center' style={{color:'#56667A',textShadow:'0 0',fontSize:16}}>SMS</Col>
                                <Col span={6} className='center' style={{color:'#56667A',textShadow:'0 0',fontSize:16}}>Webhook</Col>
                                <Col span={6} className='center' style={{color:'#56667A',textShadow:'0 0',fontSize:16}}>E-mail</Col>
                            </Row>
                            {
                                Object.keys(activeStep.triggers).map((stage, index) => {
                                    return  <React.Fragment key={index}>  
                                                <Row type='flex' style={{padding:30,background: activeStep.actions[stage].show ? 'transparent' : '#f2f2f2'}} className='triggers'>
                                                    <Col span={6} style={{color:'#44566C',textTransform:'capitalize'}}>{actionName[stage]}</Col>
                                                    <Col className='center' span={6} style={{color:'#44566C'}}>
                                                        {
                                                            activeStep.actions[stage].show 
                                                            ?
                                                                <>
                                                                    <Switch checked={activeStep.triggers[stage].sms.status === 'off' ? false : true} onChange={e=>this.setTrigger(e, stage, 'sms')} />
                                                                    {
                                                                        activeStep.triggers[stage].sms.status !== 'off' &&
                                                                            <Row className='mt-10 pointer' onClick={e=>this.configure(true, stage, 'SMS')} style={{fontSize:11,color:'#0081FF'}}>Configure SMS</Row>
                                                                    }
                                                                </>
                                                            :
                                                                <Switch checked={false} disabled />
                                                        }
                                                        
                                                    </Col>
                                                    <Col className='center' span={6} style={{color:'#44566C'}}>
                                                        {
                                                            activeStep.actions[stage].show 
                                                            ?
                                                                <>
                                                                    <Switch checked={activeStep.triggers[stage].webhook.status === 'off' ? false : true} onChange={e=>this.setTrigger(e, stage, 'webhook')}/>
                                                                    {
                                                                        activeStep.triggers[stage].webhook.status !== 'off' &&
                                                                            <Row className='mt-10 pointer' onClick={e=>this.configure(true, stage, 'Webhook')} style={{fontSize:11,color:'#0081FF'}}>Configure Webhook</Row>
                                                                    }
                                                                </>
                                                            :
                                                                <Switch checked={false} disabled />
                                                        }
                                                    </Col>
                                                    <Col className='center' span={6} style={{color:'#44566C'}}>
                                                        {
                                                            activeStep.actions[stage].show 
                                                            ?
                                                                <>
                                                                    <Switch checked={activeStep.triggers[stage].email.status === 'off' ? false : true} onChange={e=>this.setTrigger(e, stage, 'email')}/>
                                                                    {
                                                                        activeStep.triggers[stage].email.status !== 'off' &&
                                                                            <Row className='mt-10 pointer' onClick={e=>this.configure(true, stage, 'Email')} style={{fontSize:11,color:'#0081FF'}}>Configure Email</Row>
                                                                    }
                                                                </>
                                                            :
                                                                <Switch checked={false} disabled />
                                                        }
                                                    </Col>
                                                </Row>
                                                {
                                                    !activeStep.actions[stage].show &&
                                                        <Row style={{color:'#333'}} className='mt-10 mb-10 pl-20 ml-10'>
                                                            This action is disabled. Enable it from <span style={{textShadow:'0 0'}}>ACTIONS</span> to configure triggers. 
                                                        </Row>
                                                }
                                            </React.Fragment>
                                })
                            }
                        </>
                }
                {
                    activeStage && configure &&
                        <Configure
                            steps       = {steps} 
                            configure   = {this.configure} 
                            text        = {text}
                            allFields   = {allFields}
                            activeStep  = {activeStep}
                            activeStage = {activeStage}
                            updateSteps = {updateSteps}
                        />
                }
                    
            </>
        )
    }
}
