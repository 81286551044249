import React, { Component } from 'react';
import COLORS from './colors';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,ResponsiveContainer
} from 'recharts'

const defaultData = [
    {
        name: 'Page B', uv: 3000, pv: 1398, amt: 2210,
    },
    {
        name: 'Page C', uv: 2000, pv: 9800, amt: 2290,
    },
    {
        name: 'Page D', uv: 2780, pv: 3908, amt: 2000,
    },
    {
        name: 'Page E', uv: 1890, pv: 4800, amt: 2181,
    },
    {
        name: 'Page F', uv: 2390, pv: 3800, amt: 2500,
    },
    {
        name: 'Page G', uv: 3490, pv: 4300, amt: 2100,
    },
];

export default class BarChartType extends Component {
    render() {
        let {data} = this.props;
        let dataKey = [];
        // console.log('data ...', data)
        let barDataKy = [];
        let graphData = [];

        if(data.length > 0) {
            for (let j = 0; j < data.length; j++) {
                graphData[j] = {
                    x: data[j].xAxis.data[0].value,
                };

                for (let i = 0; i < data[j].yAxis.data.length; i++) {
                    graphData[j][data[j].yAxis.data[i].label] = +data[j].yAxis.data[i].value;
                    if (dataKey.length < data[j].yAxis.data.length) {
                        dataKey.push(data[j].yAxis.data[i].label);
                    }
                }
            }

            barDataKy = dataKey.map((key,i)=>{
                return <Bar key={key} dataKey={key} fill={COLORS[i % COLORS.length]} />
            })
        }else{
            graphData = defaultData;
            barDataKy.push(<Bar dataKey='uv' fill={COLORS[0]} />)
        }

        // console.log('yData', graphData)



        return (
            <ResponsiveContainer>
                <BarChart
                    data	= {graphData}
                    margin  = {{
                        top: 20, right: 20, left: 0, bottom: 20,
                    }}
                >
                    <CartesianGrid strokeDasharray="3" />
                    <XAxis dataKey="x" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {barDataKy}
                </BarChart>
            </ResponsiveContainer>
        )
    }
}
