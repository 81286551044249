import ACTIONS from "./FieldActionTypes";
import {store} from '../../config/Store';

export function fetchFieldsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function createFieldRequest(fieldInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:fieldInfo})
}
export function deleteFieldRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

export function fetchMoreFieldsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function updateFieldRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}

