import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import DatasetCreatorModal from './components/DatasetCreatorModal/';
import {createNewDataset } from './duck/DatasetActions';

class DatasetCreatorContainer extends React.Component {

  state = {
    modalVisible: false,
    creating: false,
    redirect: false
  }

  componentDidUpdate(prevProps){

    if(prevProps.currentBO){
      if (this.state.creating && this.props.currentBO && this.props.currentBO.id !== prevProps.currentBO.id) {
        this.setState({
          creating: false,
          redirect: true
        })
      }
    } else {
      if (this.state.creating && this.props.currentBO && this.props.currentBO.id) {
        this.setState({
          creating: false,
          redirect: true
        })
      }
    }
    if (this.state.creating && this.props.currentBO && prevProps.currentBO && this.props.currentBO.id !== prevProps.currentBO.id) {
      this.setState({
        creating: false,
        redirect: true
      })
    }
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
      redirect: false,
      creating: false,
    })
  }


  hideModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  createNewDataset = (currentBO) => {
    this.setState({
      modalVisible: false,
      creating: true
    })
    this.props.createNewDataset(currentBO);
  }

  render() {
    const { modalVisible, redirect } = this.state;
    const { currentBO } = this.props;

    return (
      <>
      {redirect && currentBO && <Redirect to={`/app/form/edit/dataset/${currentBO.id}`} push/>}
        <a href="#" onClick={this.showModal}>Dataset</a>
        <DatasetCreatorModal
          visible={modalVisible}
          onCancel={this.hideModal}
          createNewProject={this.createNewDataset}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentBO: state.dataset.active
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createNewDataset: (props) => dispatch(createNewDataset(props))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetCreatorContainer);
