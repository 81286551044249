import TYPES from "./MarketplaceAppActionTypes";

const fetchMarketplaceApp = (id) => {
  return {
    type: TYPES.FETCH_MARKETPLACE_APP,
    id
  }
}

const createMarketplaceApp = (app) => {
  return {
    type: TYPES.CREATE_MARKETPLACE_APP,
    app
  }
}

const createMarketplaceAppSuccess = (id) => {
  return {
    type: TYPES.CREATE_MARKETPLACE_APP_SUCCESS,
    id
  }
}

const fetchMarketplaceAppSuccess = (app) => {
  return {
    type: TYPES.FETCH_MARKETPLACE_APP_SUCCESS,
    app,
  }
}

const updateMarketplaceApp = (app) => {
  return {
    type: TYPES.UPDATE_MARLETPLACCE_APP,
    app
  }
}

const updateMarketplaceAppSuccess = (id) => {
  return {
    type: TYPES.UPDATE_MARLETPLACCE_APP_SUCCESS,
    id
  }
}

const updateMarketplaceAppFailed = (id) => {
  return {
    type: TYPES.UPDATE_MARLETPLACCE_APP_FAILED,
    id
  }
}

const fetchMarketplaceAppsSuccess = (apps) => {
  return {
    type: TYPES.FETCH_MARKETPLACE_APPS_SUCCESS,
    apps,
  };
}

const installApp = (app) => {
  return {
    type: TYPES.INSTALL_APP,
    app
  }
}

const installAppSuccess = (id) => {
  return {
    type: TYPES.INSTALL_APP_SUCCESS,
    id
  }
}

const installAppFailed = (id) => {
  return {
    type: TYPES.INSTALL_APP_FAILED,
    id
  }
}

export {
  fetchMarketplaceApp,
  createMarketplaceApp,
  fetchMarketplaceAppSuccess,
  createMarketplaceAppSuccess,
  updateMarketplaceApp,
  updateMarketplaceAppSuccess,
  updateMarketplaceAppFailed,
  fetchMarketplaceAppsSuccess,
  installApp,
  installAppFailed,
  installAppSuccess,
};
