import React from 'react';
import isUserAuthenticated from './utils/IsUserAuthenticated'
import {Redirect,Route} from "react-router-dom";

const RedirectPath = (props) => {
    return (
        <Route  {...props} render={(props)=>isUserAuthenticated() 
        ? 
            <Redirect to='/app/users' {...props} /> 
        : 
            <Redirect to='/admin' {...props} />} />
    );
};

export default RedirectPath;
