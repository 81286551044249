import React, { Component } from 'react'
import {Row, InputNumber, Select} from 'antd'

export default class Deadline extends Component {

    state = {
        duration        : this.props.activeStep.deadline.duration,
        duration_type   : this.props.activeStep.deadline.duration_type ? this.props.activeStep.deadline.duration_type : [],
        action          : this.props.activeStep.deadline.action ? this.props.activeStep.deadline.action : [],
    }

    setDuration = (val) => {
        this.setState({duration:val})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].deadline.duration = val
        this.props.updateSteps(steps)
    }

    setDurationType = (val) => {
        console.log(val)
        this.setState({duration_type:val ? val : []})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].deadline.duration_type = val ? val : null
        this.props.updateSteps(steps)
    }
    
    setAction = (val) => {
        this.setState({action:val ? val : []})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].deadline.action = val ? val : null
        this.props.updateSteps(steps)
    }

    render() {
        return (
            <>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500',marginBottom:'30px'}} className='mt-20 mb-20'>
                    What will be the deadline for this task?
                </Row>
                <Row style={{color:'#56667A'}} className='mb-20'>
                    Set deadline for this step
                </Row>
                <Row gutter={24} type='flex' align='middle'>
                    <InputNumber 
                        min         = {0} 
                        max         = {100000} 
                        size        = 'large' 
                        className   = 'custom-input ml-15' 
                        placeholder = 'Duration' 
                        style       = {{width:100}} 
                        value       = {this.state.duration}
                        onChange    = {this.setDuration}
                    />
                    <Select
                        getPopupContainer={(e)=>e.parentNode}
                        allowClear
                        className       = 'custom-select ml-10'
                        placeholder     = 'Days/Hours/Minutes'
                        size            = 'large'
                        style           = {{width:200}}
                        defaultValue    = {this.state.duration_type}
                        onChange        = {this.setDurationType}
                    >
                        <Select.Option value='days'>Days</Select.Option>
                        <Select.Option value='hours'>Hours</Select.Option>
                        <Select.Option value='minutes'>Minutes</Select.Option>

                    </Select>
                    <span style={{color:'#B4BBC4',margin:'0 28px'}}>from the date of assignment.</span>
                    {/* <Select
                        getPopupContainer={(e)=>e.parentNode}
                        allowClear
                        className       = 'custom-select'
                        placeholder     = 'Select Action'
                        size            = 'large'
                        style           = {{width:270}}
                        defaultValue    = {this.state.action}
                        onChange        = {this.setAction}
                    >
                        <Select.Option value='created'>Created</Select.Option>
                        <Select.Option value='assigned'>Assigned</Select.Option>
                        <Select.Option value='seen'>Seen</Select.Option>
                        <Select.Option value='closed'>Closed</Select.Option>
                    </Select> */}
                </Row>
            </>
        )
    }
}
