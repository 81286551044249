const TYPES = {
  FETCH_PROJECT: 'FETCH_PROJECT',
  FETCH_PROJECTS: 'FETCH_PROJECTS',
  FETCH_PROJECT_SUCCESS: 'FETCH_PROJECT_SUCCESS',
  FETCH_PROJECT_FAILED: 'FETCH_PROJECT_FAILED',
  FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
  FETCH_PROJECTS_FAILED: 'FETCH_PROJECTS_FAILED',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  UPDATE_PROJECT_FAILED: 'UPDATE_PROJECT_FAILED',
  UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
  FETCH_PROJECT_TEMPLATES: 'FETCH_PROJECT_TEMPLATES',
  FETCH_PROJECT_TEMPLATES_FAILED: 'FETCH_PROJECT_TEMPLATES_FAILED',
  FETCH_PROJECT_TEMPLATES_SUCCESS: 'FETCH_PROJECT_TEMPLATES_SUCCESS',
  CREATE_NEW_PROJECT: 'CREATE_NEW_PROJECT',
  CREATE_NEW_PROJECT_SUCCESS: 'CREATE_NEW_PROJECT_SUCCESS',
  CREATE_NEW_PROJECT_FAILED: 'CREATE_NEW_PROJECT_FAILED',
  CREATE_NEW_PROJECT_APP: 'CREATE_NEW_PROJECT_APP',
  CREATE_NEW_PROJECT_APP_SUCCESS: 'CREATE_NEW_PROJECT_APP_SUCCESS',
  CREATE_NEW_PROJECT_APP_FAILED: 'CREATE_NEW_PROJECT_APP_FAILED',
  CREATE_PROJECT_TASK: 'CREATE_PROJECT_TASK',
  CREATE_PROJECT_TASK_FAILED: 'CREATE_PROJECT_TASK_FAILED',
  CREATE_PROJECT_TASK_SUCCESS: 'CREATE_PROJECT_TASK_SUCCESS',
  FETCH_NAVIGATION_PROJECTS: 'FETCH_NAVIGATION_PROJECTS',
  FETCH_NAVIGATION_PROJECTS_SUCCESS: 'FETCH_NAVIGATION_PROJECTS_SUCCESS',
  FETCH_NAVIGATION_PROJECTS_PUSH_SUCCESS: 'FETCH_NAVIGATION_PROJECTS_PUSH_SUCCESS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
  REMOVE_PROJECT: 'REMOVE_PROJECT',
  FETCH_ALL_PROJECTS: 'FETCH_ALL_PROJECTS',
  UPDATE_PROJECT_TEMP: 'UPDATE_PROJECT_TEMP',
  FETCH_MARKETPLACE_PROJECTS: 'FETCH_MARKETPLACE_PROJECTS',
  FETCH_MARKETPLACE_PROJECTS_SUCCESS: 'FETCH_MARKETPLACE_PROJECTS_SUCCESS',
};

export default TYPES;
