import React, { Component } from 'react'
import {Row, Col, Divider, Select, Input, Icon, Switch, InputNumber, DatePicker, Typography} from 'antd'
import moment from 'moment'

const operators = {
    'text'      : ['is filled out','is not filled out','is','is not','contains'],
    'textarea'  : ['is filled out','is not filled out','is','is not','contains'],
    'time'      : ['is filled out','is not filled out','is','is not','contains'],
    'url'       : ['is filled out','is not filled out','is','is not','contains'],
    'telephone' : ['is filled out','is not filled out','is','is not','contains'],
    'tel'       : ['is filled out','is not filled out','is','is not','contains'],
    'email'     : ['is filled out','is not filled out','is','is not','contains'],
    'number'    : ['is filled out','is not filled out','is','is not','is greater than','is less than','is greater than or equal to','is less than or equal to'],
    'file'      : ['is filled out','has no files','has atleast one file'],
    'date'      : ['is filled out','is not filled out','is','is not'],
    'checkbox'  : ['is filled out','is not filled out','is','is not','contains'],
    'radio'     : ['is filled out','is not filled out','is','is not','contains'],
    'dropdown'  : ['is filled out', 'is not filled out', 'is', 'is not', 'contains'],
    'dataset'   : ['is filled out', 'is not filled out', 'is', 'is not', 'contains'],
    'people'   : ['is filled out', 'is not filled out', 'is', 'is not', 'contains'],
}

export default class Conditions extends Component {

    render() {
        let {allFields, activeField, setSwitch, sections} = this.props;        
        allFields = Object.assign({}, allFields)

        if(this.props.forProjects){
            delete allFields[activeField.id]
        }
        
        console.log('Active Field', activeField)
        // let visibleCondition = activeField.conditions;
        // if(!activeField.dependency.draw_data.or || !activeField.dependency.draw_data.or.length > 0){
        //     // if(activeField.dependency.draw_data.or[0].and && activeField.dependency.draw_data.or[0].and.length > 0){
        //     // }
        //     visibleCondition = false
        // }

        return (
            <Row>
                {
                    this.props.forProjects &&
                        <label style={{color:'#8697A8'}}>
                            Visible when 
                            <Switch
                                className           = 'ml-20'
                                checkedChildren     = 'Yes'
                                unCheckedChildren   = 'No'
                                defaultChecked      = {activeField.conditions}
                                onChange            = {e=>setSwitch(e, 'conditions')}
                            />
                        </label>
                }
                {
                    (activeField.conditions || activeField.execution === 'auto') &&
                        <Row className='mt-20 ml-10'>
                        {
                            (activeField.dependency.draw_data.or && activeField.dependency.draw_data.or.length > 0) &&

                                activeField.dependency.draw_data.or.map((or, index)=>{
                                    return (
                                        <Row key={Math.random()}>
                                            <Row style={{padding:'15px 15px 5px',background:'#fff',borderRadius:'5px'}}>                                                
                                                {                                                    
                                                    or.and.map((and, idx)=>{
                                                        return(
                                                            <Row key={Math.random()} gutter={16} type='flex' align='middle' className='mb-10'>
                                                                <Col span={6}>
                                                                    <Select
                                                                        getPopupContainer={(e)=>e.parentNode}
                                                                        className       = 'custom-select fw'
                                                                        size            = 'large'
                                                                        placeholder     = 'Select'
                                                                        defaultValue    = {and.active_control ? and.active_control : []}
                                                                        onChange        = {e=>this.props.handleField(e, and.active_control ? and.active_control : null, index, idx)}
                                                                        autoFocus       = {true}
                                                                        showSearch      = {true}
                                                                        optionFilterProp= "children"
                                                                    >
                                                                        {
                                                                            sections && sections.map(section => {
                                                                                let layout = section && section.section_layout && section.section_layout.sort((a, b) => a.y - b.y);
                                                                                layout = layout.filter(a => a.i !== activeField.id)
                                                                                return (
                                                                                    <Select.OptGroup key={section.id} label={section.section_name}>
                                                                                        {
                                                                                            layout.map(l => {
                                                                                                return (
                                                                                                    <Select.Option key={l.i} data-type={allFields[l.i].dataType} title={allFields[l.i].alias_name || allFields[l.i].name} value={allFields[l.i].id}>{allFields[l.i].alias_name || allFields[l.i].name}</Select.Option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </Select.OptGroup>
                                                                                )
                                                                            })
                                                                        }
                                                                        {/* {
                                                                            Object.keys(allFields).map(f=>{
                                                                                return <Select.Option key={f} data-type={allFields[f].dataType} title={allFields[f].alias_name || allFields[f].name} value={allFields[f].id}>{allFields[f].alias_name || allFields[f].name}</Select.Option>
                                                                            })
                                                                        } */}
                                                                    </Select>
                                                                </Col>
                                                                <Col span={6}>
                                                                    <Select
                                                                        getPopupContainer={(e)=>e.parentNode}
                                                                        className       = 'custom-select fw'
                                                                        size            = 'large'
                                                                        placeholder     = 'Select'
                                                                        defaultValue    = {and.active_condition ? and.active_condition : []}
                                                                        onChange        = {e=>this.props.handleOperator(e, index, idx)}
                                                                    >
                                                                        {
                                                                            and.type && operators[and.type].map(t=>{
                                                                                return <Select.Option key={t} title={t} value={t}>{t}</Select.Option>
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </Col>
                                                                {
                                                                    (and.active_condition && (and.active_condition === 'is' || and.active_condition === 'is not' || and.active_condition === 'contains' || and.active_condition === 'is greater than' || and.active_condition === 'is less than' || and.active_condition === 'is greater than or equal to' || and.active_condition === 'is less than or equal to')) &&
                                                                        <Col span={6}>
                                                                            {
                                                                                and.type && 
                                                                                    <AddField
                                                                                        type        = {and.type}
                                                                                        value       = {and.value}
                                                                                        orIndex     = {index}
                                                                                        andIndex    = {idx}
                                                                                        handleValue = {this.props.handleValue}
                                                                                    />
                                                                                
                                                                            }
                                                                        </Col>
                                                                }
                                                                <Col span={4}>
                                                                    {
                                                                        (idx < or.and.length - 1)
                                                                        ?
                                                                            <span>and</span>
                                                                        :   
                                                                            <Icon type='plus' onClick={e=>this.props.addAndCondition(index)} />
                                                                    }
                                                                </Col>
                                                                <Icon type='delete' style={{position:'absolute',right:'35px'}} className='f-20 ml-20' onClick={e=>this.props.deleteAndCondition(and.active_control ? and.active_control : null ,index, idx)} />
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </Row>
                                            {
                                                (index < activeField.dependency.draw_data.or.length - 1) &&
                                                    <Divider className='divider'>Or</Divider>
                                            }            
                                        </Row>                                            
                                    )
                                })
                        }
                        {
                            Object.keys(allFields).length > 0
                            ?
                                <Divider className='divider' onClick={this.props.addOrCondition}>+Add Conditions</Divider>
                            :
                                <Divider className='divider'>
                                    <Typography.Text type="danger">
                                        Please add more fields to put conditions
                                    </Typography.Text>
                                </Divider>
                        }
                        </Row>
                }
            </Row>
        )
    }
}

const AddField = (props) =>{
    return(
        <div>
            {(() => {
                switch(props.type){
                    case 'number' : 
                        return(
                            <InputNumber
                                size            = 'large'  
                                className       = 'custom-input' 
                                allowClear 
                                placeholder     = 'Enter number' 
                                defaultValue    = {props.value ? props.value : ''} 
                                onChange        = {e=>props.handleValue(e, props.orIndex, props.andIndex, props.type)} 
                            />
                        )
                    case 'text' : 
                        return(
                            <Input
                                size            = 'large'  
                                className       = 'custom-input' 
                                allowClear 
                                placeholder     = 'Enter value' 
                                defaultValue    = {props.value ? props.value : ''} 
                                onBlur          = {e=>props.handleValue(e, props.orIndex, props.andIndex)} 
                            />
                        )
                    case 'date' : 
                        return(
                            <DatePicker
                                size            = 'large'  
                                className       = 'custom-input' 
                                allowClear 
                                placeholder     = 'Select date' 
                                defaultValue    = {props.value ? moment(props.value, 'DD/MM/YYYY') : null} 
                                format          = {'DD/MM/YYYY'}
                                onChange        = {e=>props.handleValue(e, props.orIndex, props.andIndex, props.type)} 
                            />
                        )
                    default : return(
                            <Input
                                size            = 'large'  
                                className       = 'custom-input' 
                                allowClear 
                                placeholder     = 'Enter value' 
                                defaultValue    = {props.value ? props.value : ''} 
                                onBlur          = {e=>props.handleValue(e, props.orIndex, props.andIndex)} 
                            />
                    )
                }
            })()}
        </div>
    )
}