import React from 'react'
import { Input } from 'antd';
import { guidGenerator } from '../../utils/commonFunctions';

class NewLaneForm extends React.Component {

  state = {
    title: undefined
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  changeTitle = (e) => {
    this.setState({
      title: e.target.value
    })
  }

  addLane = () => {
    const title = this.state.title && this.state.title.trim();
    title && this.props.onAdd({
      id: guidGenerator(),
      name: title,
      segments: [{
        name: "+ Add Section",
        tasks: [],
        id: guidGenerator(),
      }]
    });
    !title && this.props.onCancel();
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.addLane();
    }
  }

  render() {
    const { title } = this.state;
    return (
      <Input
        placeholder="Enter column title"
        ref={this.inputRef}
        style={{marginRight: 10}}
        size="small"
        value={title}
        onChange={this.changeTitle}
        onKeyDown={this.onKeyDown}
        onBlur={this.addLane}
      />
    );
  }
}

export default NewLaneForm;
