import React, {Component} from 'react';
import {Select} from "antd";
import {connect} from "react-redux";
const style = {
    margin:'12px 0'
}

class FieldsList extends Component {

    onDeselect = (e) => {
        console.log('ee', e)
        this.props.onSelectFields(e.key,'DELETE')
    }

    render() {
        let {fieldList, selectedFields,onSelectFields,mode, fetchingFields} = this.props;

        let placeHolder = null;

        if(fetchingFields){
            placeHolder = 'Fetching fields...'
        }else{
            placeHolder = selectedFields ? 'Select Fields' : 'Fields are empty'
        }

        fieldList = fieldList.map(field=>{
            return  <Select.Option key={field.key} value={field.key}>{field.label}</Select.Option>
        })

        return (
            <Select getPopupContainer={(e)=>e.parentNode} className='fw custom-select white' placeholder={placeHolder} mode={mode} labelInValue size='large' style={style}
                    value={selectedFields} loading={fetchingFields}
                    onSelect={onSelectFields} onDeselect={this.onDeselect}>
               {fieldList}
            </Select>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(FieldsList);