import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import en_US from 'antd/lib/locale-provider/en_US';
import { LocaleProvider } from 'antd';
import AuthContainer from "./auth/AuthContainer";
import {Provider} from 'react-redux';
import {store} from './config/Store';
// import { PersistGate } from 'redux-persist/integration/react'


const render = (Component) =>{
    return ReactDOM.render(
        <LocaleProvider locale={en_US}>
            <Provider store={store}>
                {/* <PersistGate loading={null} persistor={persistor}> */}
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <Component />
                    </BrowserRouter>
                {/* </PersistGate> */}
            </Provider>
        </LocaleProvider>
        , document.getElementById('root'));
}

// render(AuthContainer);
process.env.NODE_ENV === "development" ? render(hot(AuthContainer)) : render(AuthContainer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
