function getParam(name){
    name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
    let regexS = "[\\?&]"+name+"=([^&#]*)";
    let regex = new RegExp( regexS );
    let results = regex.exec(window.location.href);
    // console.log('ressss',results)
    if(results == null)
        return "";
    else
        return results[1];
}

function getUrlData()
{
    let token = getParam("secret_token");
    return token;
}

export {
    getParam,
    getUrlData
}
