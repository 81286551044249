// import { put } from 'redux-saga/effects';
import ACTIONS from "./UserActionTypes";
import {store} from '../../config/Store';

export function fetchUsersRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function fetchMoreUsersRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function createUserRequest(userInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:userInfo})
}
// export function reInviteUserRequest(userInfo) {
//     store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:userInfo})
// }
export function updateUserRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}
export function deleteUserRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

