import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Select, Avatar} from 'antd'
import {getAvatarColor, getNameInitials} from './commonFunctions'

class PeopleSelect extends Component {

    selectPeople = (val) => {
        this.props.onChange(val)
    }

    render() {
        // console.log('people', this.props.users)
        return (
            <Select
                getPopupContainer={(e) => e.parentNode}
                allowClear
                mode={this.props.mode}
                className='custom-select fw'
                size='large'
                placeholder={this.props.placeholder}
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                defaultValue={this.props.defaultValue}
                onChange={this.selectPeople}
            >
                {
                    this.props.users.map(user => (
                        <Select.Option key={user.id} value={user.id}>
                            {
                                user.attributes.picture ?
                                    <Avatar size={'small'} src={user.attributes.picture}/> :
                                    <Avatar size="small" style={{backgroundColor: getAvatarColor(user.id)}}>
                                        {getNameInitials(user.attributes.name || user.attributes.email)}
                                    </Avatar>
                            }
                            <span className='ml-10'>{user.attributes.name || user.attributes.email}</span>
                        </Select.Option>
                    ))
                }

            </Select>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users && state.users.data && state.users.data.data
    }
}

export default connect(mapStateToProps)(PeopleSelect);
