import React from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {Row, Input, Spin} from 'antd';
import AddNewButton from '../utils/addNewButton';
import CreateNewModal from '../utils/createNewModal';
import PageList from './pageList';
import {
    createPageRequest, updatePageRequest
} from "./duck/PageActions";

class PageBuilderContainer extends React.Component {

    state = {
        showModal   : false,
        pageName    : '',
        description : '',
        pageId: '',
        editModal: false
    }


    toggleModal = (val) => {
        this.setState({showModal:val, editModal: false,pageName:'',description:'', pageId: ''})
    }
       
    setRedirectToPage = () => {
        if(this.props.activePage.activePage == null){
            return <Redirect to='/app/pages' />
        }
    }

    handleCreteSubmit = (pageName = this.state.pageName, description = this.state.description) =>{
        if(pageName !== '' && description !== ''){
            let data = {
                name : pageName,
                description : description
            }
            createPageRequest(data);
            this.setState({showModal:false,pageName:'',description:''})
        }
    }
    handleUpdateSubmit = (id = this.state.pageId, pageName = this.state.pageName, description = this.state.description) =>{
        if(pageName !== '' && description !== ''){
            let data = {
                name : pageName,
                description : description
            }
            updatePageRequest(id, data);
            this.setState({showModal:false,pageName:'',description:''})
        }
    }

    handleInputChange = (e) => {
        this.setState({pageName:e.target.value})
    }

    handleDescriptionChange = (e) => {
        this.setState({description:e.target.value})
    }

    onEdit = (page) => {
        this.setState({pageId: page.id, pageName: page.name, description: page.description, showModal: true, editModal: true})
    };
    render(){
        console.log('abc')
        let pages  = this.props.pages.data && (this.props.pages.data.data && this.props.pages.data.data.length > 0 ) ? this.props.pages.data.data : [];
        let modalProps = {}
        if(this.state.editModal){
            modalProps.title = 'Page Details';
            modalProps.btnText = 'Update';
            modalProps.onSubmit = this.handleUpdateSubmit
        } else {
            modalProps.title = 'Enter Page Name';
            modalProps.btnText = 'Create';
            modalProps.onSubmit = this.handleCreteSubmit
        }
        return(
            <Row>
                {
                    this.setRedirectToPage()
                }
                <Row type='flex' justify='end'>
                    <AddNewButton trigger={e=>this.toggleModal(true)}/>
                </Row>
                <Spin spinning={this.props.pages.loading} size='large'>
                    <Row style={{marginTop:'50px',minHeight:'50vh'}}>
                        <PageList list={pages} onEdit={this.onEdit}/>
                    </Row>
                </Spin>
                <CreateNewModal 
                    title       = {modalProps.title}
                    onSubmit    = {modalProps.onSubmit}
                    btnText     = {modalProps.btnText}
                    modalVisible= {this.state.showModal}
                    toggleModal = {this.toggleModal}
                >
                    <Input 
                        onChange    = {this.handleInputChange} 
                        value       = {this.state.pageName} 
                        size        = "large" 
                        className   = 'custom-input' 
                        placeholder = "Example: Order Management, Reimbursement" 
                        allowClear
                    />
                    <Input.TextArea
                        className   = 'custom-input'
                        style       = {{marginTop:'20px'}}
                        value={this.state.description}
                        placeholder = "Page description goes here"
                        autosize    = {{ minRows: 3, maxRows: 6 }}
                        onChange    = {this.handleDescriptionChange}
                    />
                </CreateNewModal>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        pages       : state.pages,
        activePage  : state.activePage
    }
}

export default connect(mapStateToProps)(PageBuilderContainer);