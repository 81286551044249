import React, { Component } from 'react'
import {Input, Icon, Tooltip} from 'antd'

export default class Textbox extends Component {

    state = {
        textBoxValue : (this.props.activeTask && this.props.activeTask.fields && this.props.activeTask.fields[this.props.field.id]) && this.props.activeTask.fields[this.props.field.id].value ? this.props.activeTask.fields[this.props.field.id].value : ''
    }

    componentDidUpdate(prevProps){
        let {activeTask, field} = this.props
        // console.log(activeTask, field)
        if(activeTask && activeTask.fields && activeTask.fields[field.id] && activeTask.fields[field.id].value){
            if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
                this.setState({textBoxValue: activeTask.fields[field.id].value})
            }
        }
    }

    submitValue = (e, fieldId) =>{
        this.props.addValue(fieldId, this.state.textBoxValue.trim())
    }

    keepValue = (e) => {
        this.setState({textBoxValue:e.target.value})
    }


    render() {
        let {field, showError, showErrorAlert} = this.props;
        return (
            <Input
                allowClear
                size        = 'large'
                className   = {showErrorAlert && showError ? 'error custom-input' : 'custom-input'}
                placeholder = {field.alias_help_text ? field.alias_help_text : field.helpText}
                value       = {this.state.textBoxValue}
                onBlur      = {e=>this.submitValue(e,field.id)}
                onChange    = {this.keepValue}
                suffix={
                    field.dataType === 'url' && this.state.textBoxValue !== '' ?
                        <Tooltip title="Click to open this url">
                            <a href={this.state.textBoxValue}  target='_blank' rel="noopener noreferrer">
                                <Icon className='pointer' type="export" style={{fontSize:14,color: 'rgba(0,0,0,.25)'}} />
                            </a>
                        </Tooltip>
                    : <></>
                  }
            />
        )
    }
}
