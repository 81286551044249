import React from 'react';
import {Menu, Badge} from 'antd';
import {connect} from 'react-redux';
import {setActiveTab} from './duck/PageNavigationActions'
const PageNavigation = (props) => {

    const _setActiveTab = (val) => {
        setActiveTab(val);
    }

    return (
            <Menu className='fh page-sidebar-menu' mode="inline">
                <Menu.Item onClick={e=>_setActiveTab('Panel')}>
                    <Badge status="warning" text="Panel" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Chart')}>
                    <Badge status="processing" text="Chart" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Guage')}>
                    <Badge status="error" text="Guage" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Form')}>
                    <Badge status="success" text="Form" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Report')}>
                    <Badge status="warning" text="Report" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Widget')}>
                    <Badge status="processing" text="Widget" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Table')}>
                    <Badge status="error" text="Table" />
                </Menu.Item>
                <Menu.Item onClick={e=>_setActiveTab('Button')}>
                    <Badge status="success" text="Button" />
                </Menu.Item>
                {/* <Menu.Item>
                    <Link onClick={logout}>Logout</Link>
                </Menu.Item> */}
            </Menu>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser:state.currentUser
    }
}

// const Navbar = withRouter(Navigation)
export default connect(mapStateToProps)(PageNavigation);
