import ACTIONS from './RecordActionTypes';

const initialState = {
    records: {},
    data:null,
    loading:false,
    error:null,
}

const getMeta = (meta, noOfItems, updateTotalCount) => {
    let {count, totalCount} = meta;
    count += noOfItems;
    totalCount += updateTotalCount ? noOfItems : 0;
    let newMeta = {...meta, count, totalCount};
    return newMeta
}

const createData = (list, item) => {
    if(!list.find(data=>data.id===item.id)) {
        return [item, ...list]
    }
    return list;
}

const updateData = (list, newData) => {
    return list.map(data=>data.id === newData.id ? newData : data)
}

const filterData = (list, id, d) => {
    return list.filter(data=>data.id !== id);
}

const arrToObjMap = (arr = []) => {
    const obj = arr.reduce((mObj, item) => {
        var key = item._id || item.id;
        mObj[key] = item;
        return mObj;
    }, {});

    return Object.assign({}, obj);
};

const getArrOrder = (arr = []) => {
    let order = arr.map((a) => a._id || a.id);
    return order;
};

const recordReducer = (state=initialState, action) => {
    let payload = action && action.payload;
    let _records;

    switch(action.type){
        // case ACTIONS.FETCH_REQUEST: return {...state,loading:true,error:null};
        // case ACTIONS.FETCH_SUCCESS: return {...state,data:action.payload,loading:false};
        // case ACTIONS.FETCH_FAIL: return {...state,loading:false,error:action.payload};

        /** fetch records start */

        case ACTIONS.FETCH_REQUEST:
            return Object.assign({}, state, {
                [payload.id]: Object.assign({}, state[payload.id], {
                    error: null,
                    loading: true,
                }),
            });

        case ACTIONS.FETCH_SUCCESS:
            let records = (payload && payload.data) || [];
            let meta = payload && payload.meta;
            let recordList = payload.isReset ? [] : ((state[payload.dataset_id] && state[payload.dataset_id].list) || []);
            recordList = [...(recordList || []), ...getArrOrder(records)];

            return Object.assign({}, state, {
                records: Object.assign({}, state.records, arrToObjMap(payload.data)),
                [payload.dataset_id]: Object.assign({}, state[payload.dataset_id], {
                    list: [...new Set(recordList)],
                    meta: Object.assign({}, state[payload.dataset_id] && state[payload.dataset_id].meta, meta),
                    loading: false,
                }),
            });

        case ACTIONS.FETCH_FAIL:
            return Object.assign({}, state, {
                [payload.dataset_id]: Object.assign({}, state[payload.dataset_id], {
                    error: null,
                    loading: true,
                }),
            });

        /** fetch records end */

        // case ACTIONS.FETCH_MORE_REQUEST: return {...state,loading:true,error:null};
        // case ACTIONS.FETCH_MORE_SUCCESS: return {...state,data:{data:[...state.data.data,...action.payload.data],meta:getMeta(state.data.meta, action.payload.meta.count)},loading:false};
        // case ACTIONS.FETCH_MORE_FAIL: return {...state,loading:false,error:action.payload};

        // case ACTIONS.CREATE_REQUEST: return {...state,loading:true,error:null};
        // case ACTIONS.CREATE_SUCCESS: return {...state,data:{data: createData(state.data.data, action.payload.data),meta:getMeta(state.data.meta, 1,true)},loading:false};
        // case ACTIONS.CREATE_FAIL: return {...state,loading:false,error:action.payload};

        /**  create record start */

        case ACTIONS.CREATE_REQUEST:
            return Object.assign({}, state, {
                [payload.dataset_id]: Object.assign({}, state[payload.dataset_id], {
                    loading: true,
                    error: null,
                }),
            });

        case ACTIONS.CREATE_SUCCESS:
            return Object.assign({}, state, {
                records: Object.assign({}, state.records, {
                    [payload.data.id]: payload.data,
                }),
                [payload.dataset_id]: Object.assign({}, state[payload.dataset_id], {
                    list: [...new Set([...(state[payload.dataset_id] && state[payload.dataset_id].list || []), payload.data.id])],
                    loading: false,
                }),
            });

        case ACTIONS.CREATE_FAIL:
            return Object.assign({}, state, {
                [payload.dataset_id]: Object.assign({}, state[payload.dataset_id], {
                    error: payload,
                    loading: false,
                }),
            });

        /**  create record end */

        // case ACTIONS.UPDATE_REQUEST: return {...state,loading:true,error:null};
        // case ACTIONS.UPDATE_SUCCESS: return {...state,data:{data: updateData(state.data.data, action.payload.data),meta:state.data.meta},loading:false};
        // case ACTIONS.UPDATE_FAIL: return {...state,loading:false,error:action.payload};

        /**  update record start */

        case ACTIONS.UPDATE_REQUEST:
            return Object.assign({}, state, {
                records: Object.assign({}, state.records, {
                    [payload.id]: Object.assign({}, state.records[payload.id], {
                        error: null,
                        loading: true,
                    }),
                })
            });

        case ACTIONS.UPDATE_SUCCESS:
            return Object.assign({}, state, {
                records: Object.assign({}, state.records, {
                    [payload.record_id]: payload.data,
                }),
            });

        case ACTIONS.UPDATE_FAIL:
            return Object.assign({}, state, {
                records: Object.assign({}, state.records, {
                    [payload.record_id]: Object.assign({}, state.records[payload.record_id], {
                        loading: false,
                        error: payload,
                    }),
                })
            });

        /**  update record end */

        // case ACTIONS.DELETE_REQUEST: return {...state,loading:true,error:null};
        // case ACTIONS.DELETE_SUCCESS: return {...state,data:{data: filterData(state.data.data, action.payload.data.id, action.payload),meta:getMeta(state.data.meta,-1,true)},loading:false};
        // case ACTIONS.DELETE_FAIL: return {...state,loading:false,error:action.payload};

        /**  delete record start */

        case ACTIONS.DELETE_REQUEST:
            _records = {};
            payload.map(r_id => _records = Object.assign({}, _records, {
                [r_id]: Object.assign({}, state.records[r_id], {
                    error: null,
                    loading: true,
                })
            }));

            return Object.assign({}, state, {
                records: Object.assign({}, state.records, _records)
            });

        case ACTIONS.DELETE_SUCCESS:
            if (payload && Array.isArray(payload.record_ids)) {
                let _deleteRecords = {};
                payload.record_ids.map(id => Object.assign({}, _deleteRecords, { [id]: undefined }));
                return Object.assign({}, state, {
                    records: Object.assign({}, state.records, payload.record_ids, _deleteRecords),
                });
            } else {
                let _recordId = payload && payload.data && payload.data.id;
                return Object.assign({}, state, {
                    records: Object.assign({}, state.records, {
                        [_recordId]: undefined,
                    }),
                });
            }

        case ACTIONS.DELETE_FAIL:
            _records = {};
            payload.record_ids.map(r_id => _records = Object.assign({}, _records, {
                [r_id]: {
                    error: null,
                    loading: true,
                }
            }));

            return Object.assign({}, state, {
                records: Object.assign({}, state.records, _records)
            });

        /**  delete record end */

        default:
            return state;
    }
}

export default recordReducer;