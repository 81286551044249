const TYPES = {
  FETCH_MARKETPLACE_APP: 'FETCH_MARKETPLACE_APP',
  CREATE_MARKETPLACE_APP: 'CREATE_MARKETPLACE_APP',
  FETCH_MARKETPLACE_APP_SUCCESS: 'FETCH_MARKETPLACE_APP_SUCCESS',
  CREATE_MARKETPLACE_APP_SUCCESS: 'CREATE_MARKETPLACE_APP_SUCCESS',
  UPDATE_MARLETPLACCE_APP: 'UPDATE_MARLETPLACCE_APP',
  UPDATE_MARLETPLACCE_APP_SUCCESS: 'UPDATE_MARLETPLACCE_APP_SUCCESS',
  UPDATE_MARLETPLACCE_APP_FAILED: 'UPDATE_MARLETPLACCE_APP_FAILED',
  FETCH_MARKETPLACE_APPS: 'FETCH_MARKETPLACE_APPS',
  FETCH_MARKETPLACE_APPS_SUCCESS: 'FETCH_MARKETPLACE_APPS_SUCCESS',
  INSTALL_APP: 'INSTALL_APP',
  INSTALL_APP_SUCCESS: 'INSTALL_APP_SUCCESS',
  INSTALL_APP_FAILED: 'INSTALL_APP_FAILED',
};

export default TYPES;
