/*
    Created by Nitish on 26/02/20.
 */
import React from 'react';
import {Icon, Row} from 'antd'

const Actions = (props) => {
    let {togglePreview, downloadFile, contentToDisplay} = props;

    let {id,filename} = contentToDisplay;

    const print = () =>{
        let prtContent = document.getElementById("contentPreview");
        let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        WinPrint.document.write(prtContent.innerHTML);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
    }

    return (
        <Row justify='end' type='flex' align='middle' style={{padding:10,fontSize:20}}>
            <Icon type="download" onClick={()=>downloadFile(id, filename)} className='mr-20'/>
            <Icon type="printer" onClick={print} className='mr-20'/>
            <Icon type="close" onClick={()=>togglePreview(null)}/>
        </Row>
    )
}

export default Actions;
