import React, {Component} from 'react'
import {Row, Result} from 'antd';
import isUserAuthenticated from '../auth/utils/IsUserAuthenticated';
import {Redirect} from 'react-router-dom';
import {getUrlData} from '../auth/utils/getParams'
import jwtDecode from "jwt-decode";
import {connect} from "react-redux";

let delay = false;

export class AdminContainer extends Component {
    constructor(props) {
        super(props);
        if (window.location.pathname === '/admin') {
            let secret_code = getUrlData();
            let localStorageToken = localStorage.getItem('jwt');
            // if there already secret token
            if (localStorageToken) {
                let oldUser = jwtDecode(localStorageToken);
                let newUser = jwtDecode(secret_code);
                console.log('Org ID: ',oldUser.user.org_id , newUser.user.org_id)
                // if different org admin
                if (oldUser.user.org_id !== newUser.user.org_id) {
                    console.log('Org id did not match so clearing state and localstorage');
                    localStorage.clear();
                    this.props.logout();
                    delay = true;
                } else {
                    // if same org admin
                    delay = true;
                }
            } else {
                // if do not have any localstorage
                delay = true;
            }
        }
        this.state = {
            authenticated: false
        }
    }

    componentDidMount() {
        if (delay) {
            if (isUserAuthenticated()) {
                this.setState({authenticated: true})
            }
        }

    }

    render() {
        // console.log('authenticated?',this.state.authenticated)
        if (this.state.authenticated) {
            return <Redirect to='/app/users'/>
        }
        return (
            <Row type='flex' align='middle' justify='center' className='fh'>
                <Result
                    status="403"
                    subTitle={<b className='f-20'>Sorry, you are not authorized to access this page.</b>}
                />
            </Row>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout() {
            dispatch({type: 'LOGOUT_REQUEST'})
        }
    }
}
export default connect(null, mapDispatchToProps)(AdminContainer)
