import React, { Component } from 'react'
import {Row, Icon, Input} from 'antd'
import SMS from './sms'
import Email from './email'

const templateTags = [
    {name: 'TaskName'},
    {name: 'TaskId'},
    {name: 'OrganizationName'},
    {name: 'AssignedUser'},
    {name: 'CreatedBy'},
    {name: 'CreatedAt'},
    {name: 'TaskStart'},
    {name: 'Deadline'},
    {name: 'ModifiedAt'},
    {name: 'ModifiedBy'},
    {name: 'TaskUrl'},
    {name: 'TemplateName'},
    {name: 'ThreadName'},
    {name: "TeamManagersEmail", type: "email"},
    {name: "AdminsEmail", type: "email"},
    {name: "createdByEmail", type: "email"},
    {name: "AssignedUserEmail", type: "email"},
    {name: "ThreadInitiatorEmail", type: "email"},
    {name: "TeamManagersTelephone", type: "telephone"},
    {name: "AdminsTelePhone", type: "telephone"},
    {name: "createdByTelephone", type: "telephone"},
    {name: "AssignedUserTelephone", type: "telephone"},
    {name: "ThreadInitiatorTelephone", type: "telephone"}
]

export default class Configure extends Component {
    
    state = {
        urlError     : false,
        webhookUrl   : this.props.activeStep.triggers[this.props.activeStage].webhook.text ? this.props.activeStep.triggers[this.props.activeStage].webhook.text : '',
        templateTags : []
    }

    componentDidMount(){
        const {allFields} = this.props
        let tags = []
        allFields && Object.keys(allFields).map(fieldId => {
            if(allFields[fieldId].dataType === 'telephone' || allFields[fieldId].dataType === 'email'){
                tags.push({name:allFields[fieldId].name, type: allFields[fieldId].dataType, id: allFields[fieldId].id})
            }
        })
        this.setState({templateTags: [...templateTags,...tags]})
    }

    toggleCustom = (e) =>{
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].status = e.target.value
        this.props.updateSteps(steps) 
    }

    updateSmsRecipient = (val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].to = val ? val : []
        this.props.updateSteps(steps)
    }
    
    updateSmsText = (val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].text = val
        this.props.updateSteps(steps)
    }
    
    updateEmailRecipient = (val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].to = val ? val : []
        this.props.updateSteps(steps)
    }
    
    updateEmailText = (val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].text = val
        this.props.updateSteps(steps)
    }
    
    updateEmailSubject = (val) => {
        let steps = Object.assign({}, this.props.steps)
        steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].subject = val
        this.props.updateSteps(steps)
    }

    updateWebhook = () => {
        if(this.isUrl(this.state.webhookUrl) || this.state.webhookUrl.trim() === ''){
            this.setState({urlError : false})
            let steps = Object.assign({}, this.props.steps)
            steps[this.props.activeStep.id].triggers[this.props.activeStage][this.props.text.toLowerCase()].text = this.state.webhookUrl
            this.props.updateSteps(steps)
        }else{
            this.setState({urlError : true})
        }
    }

    handleWebhook = (e) => {
        this.setState({webhookUrl: e.target.value})
    }

    isUrl = (url) => {
        let pattern =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        return pattern.test(url);
    }

    render() {
        const {text, configure, activeStep, activeStage} = this.props
        const status = activeStep.triggers[activeStage][text.toLowerCase()].status 
        // console.log(activeStage)
        return (
            <Row>
                <Row type='flex' align='middle'>
                    <Icon
                        onClick = {e=>configure(false, null, null)}
                        className='pointer'
                        component={
                            () => <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z" fill="#56667A"/>
                                    <path d="M12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L11.2929 16.7071C11.6834 17.0976 12.3166 17.0976 12.7071 16.7071C13.0976 16.3166 13.0976 15.6834 12.7071 15.2929L10.4142 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H10.4142L12.7071 8.70711Z" fill="#56667A"/>
                                </svg>
                        }
                    />
                    <span className='ml-20' style={{fontSize:16}}>{text}</span>                                
                </Row>
                <Row className='mt-20' style={{padding:15,border: '1px solid #D9DDE1',borderRadius:5}}>
                    {
                        text.toLowerCase() === 'sms' &&
                            <SMS 
                                status       = {status}
                                toggleCustom = {this.toggleCustom}
                                templateTags = {this.state.templateTags}
                                updateSmsRecipient = {this.updateSmsRecipient}
                                updateSmsText= {this.updateSmsText}
                                to           = {this.props.activeStep.triggers[this.props.activeStage].sms.to}
                                text         = {this.props.activeStep.triggers[this.props.activeStage].sms.text}
                            />
                    }
                    {
                        text.toLowerCase() === 'webhook' &&
                            <>
                                <Input
                                    className   = 'custom-input'
                                    size        = 'large'
                                    placeholder = 'Enter webhook url'
                                    value       = {this.state.webhookUrl}
                                    onChange    = {this.handleWebhook}
                                    onBlur      = {this.updateWebhook}
                                />
                                {this.state.urlError && <Row style={{color:'red',fontSize:12}} className='mt-10 pl-20'>Please enter a valid url</Row>}
                            </>
                    }
                    {
                        text.toLowerCase() === 'email' &&
                            <Email
                                status       = {status}
                                toggleCustom = {this.toggleCustom}
                                templateTags = {this.state.templateTags}
                                updateEmailRecipient = {this.updateEmailRecipient}
                                updateEmailText= {this.updateEmailText}
                                updateEmailSubject = {this.updateEmailSubject}
                                to           = {this.props.activeStep.triggers[this.props.activeStage].email.to}
                                text         = {this.props.activeStep.triggers[this.props.activeStage].email.text}
                                subject      = {this.props.activeStep.triggers[this.props.activeStage].email.subject}
                            />
                    }
                </Row>
            </Row>
        )
    }
}
