import React from 'react';
import 'antd/dist/antd.css';
import {Redirect,Route} from 'react-router-dom';
import isUserAuthenticated from './utils/IsUserAuthenticated'

function PrivateRoute({ component: Component, ...rest }) {
    return (
        <Route {...rest} render={props => {
            return (
                isUserAuthenticated() ? (
                    <Component {...props}/>
                ) : (
                    <Redirect to={{
                        pathname: '/admin',
                        state: {from: props.location}
                    }}/>
                )
            )
        }}/>
    );
}

export default PrivateRoute;
