import React from 'react'
import { message } from 'antd';
import { Draggable } from "react-smooth-dnd";
import Header from './Header';
import Segment from '../Segment';
// import SegmentForm from '../Segment/SegmentForm';

import './styles.css';

class Column extends React.Component {

  state = {
    showAddTask: false
  }

  createNewSegment = (segment) => {
    const { columnIndex } = this.props;
    const column = Object.assign({}, this.props.column);
    column.segments.push(segment);
    this.props.updateColumm(column, columnIndex);
  }

  updateColumnName = (name) => {
    const { columnIndex } = this.props;
    const column = Object.assign({}, this.props.column);
    column.name = name;
    this.props.updateColumm(column, columnIndex);
  }

  updateColumnSegment = (segment, segmentIndex) => {
    const { columnIndex } = this.props;
    const column = Object.assign({}, this.props.column);
    column.segments[segmentIndex] = segment;
    this.props.updateColumm(column, columnIndex);
  }

  showAddTask = () => {
    this.setState({
      showAddTask: true
    })
  }

  hideAddTask = () => {
    this.setState({
      showAddTask: false
    })
  }

  createTask = (task, segmentIndex) => {
    const { createTask, columnIndex } = this.props;
    createTask(task, columnIndex, segmentIndex);
    this.hideAddTask();
  }

  handleRemoveTask = (segmentIndex, removedIndex) => {
    const { columnIndex } = this.props;
    return this.props.removeTask(columnIndex, segmentIndex, removedIndex);
  }

  handleAddTask = (segmentIndex, addedIndex, taskId) => {
    const { columnIndex } = this.props;
    return this.props.addTask(columnIndex, segmentIndex, addedIndex, taskId);
  }

  handleDeleteColumn = () => {
    const { column } = this.props;
    const segments = column.segments;
    let allowDelete = false;
    segments.forEach(segment => {
      allowDelete = segment.tasks.length > 0 ? false : true
    });
    !allowDelete && message.error('Column with tasks cannot be deleted.');
    allowDelete && this.props.onDelete(this.props.columnIndex);
  }

  deleteSection = (segmentIndex) => {
    const column = Object.assign({}, this.props.column);
    column.segments = column.segments.slice(0, segmentIndex).concat(column.segments.slice(segmentIndex+1))
    if (column.segments.length === 0) {
      column.segments = [{
        name: "+ Add Section",
        tasks: []
      }];
    }
    this.props.updateColumm(column, this.props.columnIndex);
  }

  deleteTask = (segmentIndex, taskIndex, id) => {
    const { columnIndex } = this.props;
    this.props.deleteTask(columnIndex, segmentIndex, taskIndex, id);
  }

  render() {
    const { showAddTask } = this.state;
    const { column, color, tasks } = this.props;
    return (
      <Draggable key={column.index}>
        <div className="column">
          <Header
            {...column}
            color={color}
            updateName={this.updateColumnName}
            onDelete={this.handleDeleteColumn}
            showAddTask={this.showAddTask}
          />
          {column.segments.map((segment, index) => (
            <Segment
              segmentIndex={index}
              segment={segment}
              lastSegment={index === column.segments.length - 1}
              key={index}
              color={color}
              tasks={tasks}
              column={this.props.column}
              removeTask={this.handleRemoveTask}
              addTask={this.handleAddTask}
              updateSegment={this.updateColumnSegment}
              showAddTask={index === 0 && showAddTask}
              hideAddTask={this.hideAddTask}
              createTask={this.createTask}
              deleteSection={this.deleteSection}
              updateTask={this.props.updateTask}
              deleteTask={this.deleteTask}
              users={this.props.users}
              loggedInuser={this.props.loggedInuser}
              createNewSegment={this.createNewSegment}
            />
          ))}
        </div>
      </Draggable>
    );
  }
}

export default Column;
