import {put, takeLatest,call,retry} from 'redux-saga/effects';
import appUrl from '../../constants/AppUrl';
import axios from 'axios';
import {showNotification} from '../../utils/commonFunctions'
import {defaultConfig} from '../../config/Axios';
import ACTIONS from './UserActionTypes';

function* retrySaga({apiRequest,data, successType, failType, error}) {
    if(error.message === 'Network Error') {
        // if internet was disconnected
        try {
            const {noOfAttempts,interval} = defaultConfig.retry;
            // Retry api in every 1 second, till 20 attempts
            let users = yield retry(noOfAttempts, interval, apiRequest, data);
            if (users.data.status) {
                yield put({type: failType, payload: users.data})
            }else{
                yield put({type: successType, payload: users.data})
            }
        } catch (error) {
            yield put({type: failType, payload: defaultConfig.exception})
        }
    }else{
        console.log(error)
        yield put({type: failType, payload: defaultConfig.exception})
    }
}

const fetchAPI = (params) => axios({
    method: 'GET',
    url:appUrl.PEOPLE_URL,
    params
})

const createAPI = (data) => axios({
    method:'POST',
    url: appUrl.PEOPLE_URL +"/invite-users",
    data//{data: {attributes: data}}
})

const updateAPI = ({id,data}) => axios({
    method:'PUT',
    url: appUrl.PEOPLE_URL + `/${id}`,
    data: {data: {attributes: data}}
})

const deleteAPI = (id) => axios({
    method: 'DELETE',
    url: appUrl.PEOPLE_URL+'/'+id,
})

function* fetchUsers (action){
    try {
        let users = yield call(fetchAPI,action.payload);
        if(users.data.status) {
            // response may be like invaid token.
            yield put({type: ACTIONS.FETCH_FAIL, payload: users.data});
        }else{
            yield put({type: ACTIONS.FETCH_SUCCESS, payload: users.data});
        }
    } catch (error) {
        yield call(retrySaga,{
            apiRequest:fetchAPI,
            data:action.payload,
            successType:ACTIONS.FETCH_SUCCESS,
            failType:ACTIONS.FETCH_FAIL,
            error
        });
    }
}

function* fetchMoreUsers (action) {
    try {
        let users = yield call(fetchAPI, action.payload);
        if(users.data.status) {
            yield put({type: ACTIONS.FETCH_MORE_FAIL, payload: users.data});
        }else{
            yield put({type: ACTIONS.FETCH_MORE_SUCCESS, payload: users.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: fetchAPI,
            data: action.payload,
            successType: ACTIONS.FETCH_MORE_SUCCESS,
            failType: ACTIONS.FETCH_MORE_FAIL,
            error
        })
    }
}

function* deleteUser (action) {
    try {
        const user = yield call(deleteAPI, action.payload);
        if(user.data.status) {
            showNotification('error', user.data.meta.message || 'failed to delete user');
            yield put({type: ACTIONS.DELETE_FAIL, payload: user.data});
        }else{
            showNotification('success','User deleted successfully')
            yield put({type: ACTIONS.DELETE_SUCCESS, payload: user.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: deleteAPI,
            data: action.payload,
            successType: ACTIONS.DELETE_SUCCESS,
            failType: ACTIONS.DELETE_FAIL,
            error
        })
    }
}

function* updateUser (action) {
    try {
        const user = yield call(updateAPI, action.payload);
        if(user.data.status) {
            showNotification('error',user.data.meta.message || 'failed to update user');
            yield put({type: ACTIONS.UPDATE_FAIL, payload: user.data});
        }else{
            showNotification('success','User  updated successfully')
            yield put({type: ACTIONS.UPDATE_SUCCESS, payload: user.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: updateAPI, data: action.payload,
            successType: ACTIONS.UPDATE_SUCCESS,
            failType: ACTIONS.UPDATE_FAIL,
            error
        })
    }
}

function* createUser (action) {
    try {
        const user = yield call(createAPI, action.payload);
        // wait until api is finished
        // Then check if there is some problem in user's data, like users exists,
        if(!user.data.meta.success) {
            showNotification('error',user.data.meta.message || 'failed to create user');
            yield put({type: ACTIONS.CREATE_FAIL, payload: user.data});
        } else{
            showNotification('success','User created successfully');
            yield put({type: ACTIONS.CREATE_SUCCESS, payload: user.data});
        }
    } catch (error) {
        // if there is any error, check if the error is due to disconnected net.
        yield call(retrySaga, {
            apiRequest: createAPI, data: action.payload,
            successType: ACTIONS.CREATE_SUCCESS,
            failType: ACTIONS.CREATE_FAIL,
            error
        })
    }
}

//  **** START POINT - ACTION WATCHER  ***
export default function* usersSaga(){
    yield takeLatest(ACTIONS.FETCH_REQUEST, fetchUsers);
    // yield takeLatest(ACTIONS.FETCH_ONE_REQUEST, fetchOneUser);
    yield takeLatest(ACTIONS.FETCH_MORE_REQUEST, fetchMoreUsers);
    yield takeLatest(ACTIONS.CREATE_REQUEST, createUser);
    // yield takeLatest(ACTIONS.CREATE_REQUEST, reInviteUser);
    yield takeLatest(ACTIONS.DELETE_REQUEST, deleteUser);
    yield takeLatest(ACTIONS.UPDATE_REQUEST, updateUser);
}