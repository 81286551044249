import React, { Component } from 'react'
// import {Link} from 'react-router-dom'
import {Row, Button, Tooltip} from 'antd'

const title = {fontSize: '20px', lineHeight: '23px', color: '#44566C',fontWeight:'bold'}
// const desc  = {fontSize: '12px', lineHeight: '22px', color: '#8798A8'}
const headerStyle = {
    position: 'fixed',
    top: '64px',
    background: '#F8FAFB',
    zIndex: '100',
    left: '0',
    padding: '10px 40px',
    right: '0',
    borderBottom: '0.7px solid #D9DDE1',
}

export default class Header extends Component {


    render() {
        let {loader, name, description} = this.props;

        return (
            <Row type='flex' justify='space-between' align='middle' style={headerStyle}>
                <Row type='flex'>
                    <Row>
                        <span style={title}>
                            {name}
                        </span>
                        <br/>
                        {description && <Tooltip placement="rightBottom" title={description.length > 50 ? description : null}>
                            <div style={{display: "flex"}}>
                                <span>
                                    {description.slice(0, 50)}
                                    {description.length > 50 ? '...' : null}
                                 </span>
                            </div>
                        </Tooltip>}
                    </Row>
                </Row>
                <Row>
                    <Button type='default' className='mr-10' onClick={()=>this.props.onCancel()}>Cancel</Button>
                    <Button type='primary' loading={loader} onClick={e=>this.props.onPublish()}>Publish</Button>
                </Row>
            </Row>
        )
    }
}
