const ACTIONS = {
    FETCH_REQUEST: 'FETCH_USERS_REQUEST',
    FETCH_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_FAIL: 'FETCH_USERS_FAIL',
    FETCH_ONE_REQUEST: 'FETCH_ONE_USER_REQUEST',
    FETCH_ONE_SUCCESS: 'FETCH_ONE_USER_SUCCESS',
    FETCH_ONE_FAIL: 'FETCH_ONE_USER_FAIL',
    FETCH_MORE_REQUEST: 'FETCH_MORE_USERS_REQUEST',
    FETCH_MORE_SUCCESS: 'FETCH_MORE_USERS_SUCCESS',
    FETCH_MORE_FAIL: 'FETCH_MORE_USERS_FAIL',
    CREATE_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_FAIL: 'CREATE_USER_FAIL',
    UPDATE_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_FAIL: 'UPDATE_USER_FAIL',
    DELETE_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_FAIL: 'DELETE_USER_FAIL',
}

 export default ACTIONS;