import React, { Component } from 'react'
import {Upload, Button, Icon} from 'antd'
import {showNotification} from '../../../utils/commonFunctions'
import axios from 'axios';
import appUrl from '../../../constants/AppUrl';
import Attachments from '../../components/Attachments';
import {fetchAttachments} from '../../../attachments/duck/AttachmentsActions'
import {connect} from 'react-redux'
// import { CustomErrorComponent } from 'custom-error';

class File extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files               : [],
            uploadingFiles      : [],
            // showPreviewOfFileId : null
        }
    }

    componentDidMount(){
        const {activeTask, field, fetchAttachments} = this.props;

        if(activeTask){
            let fields = activeTask.fields;
            if(fields && fields[field.id]){
                console.log('called',fields[field.id].value)
                fetchAttachments({ids: fields[field.id].value})
                this.setState({files : fields[field.id].value ? fields[field.id].value : []})
            }
        }
        if (this.props.focusOnMount && this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    componentDidUpdate(prevProps, prevState){
        let {activeTask, field} = this.props
        let fields = activeTask.fields

        if(activeTask && fields && fields[field.id]){
            let prevFields = prevProps.activeTask.fields
            if(prevFields && prevFields[field.id] && prevFields[field.id].value){
                if(fields[field.id].value !== prevFields[field.id].value) {
                    this.setState({files: fields[field.id] && fields[field.id].value ? fields[field.id].value : []})
                }
            }
        }

        // if(activeTask && activeTask.fields && activeTask.fields[field.id] && activeTask.fields[field.id].value){
        //     if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
        //         this.setState({textBoxValue: activeTask.fields[field.id].value})
        //     }
        // }
    }

    handleFile = (fileId) =>{
        if(!this.props.readOnly){
            let ids = [...this.state.files,fileId]
            this.setState({files: ids})
            this.props.addValue(this.props.field.id, ids)
        }
    }

    uploadFile = ({
        action,
        data,
        file,
        filename,
        headers,
        onError,
        onProgress,
        onSuccess,
        withCredentials,
    }) => {
        const { activeTask, field } = this.props;

        action = action || appUrl.ATTACHMENTS;
        let ext = file.name.split('.')
        ext = '.' + ext[ext.length - 1]
        if(field.options && field.options.length > 0 && !field.options.includes(ext)){
            showNotification('error', 'Please upload file with extension '+field.options.join(', ')+' only');
            return false
        }
        if (file.size > 50000000) {
            showNotification('error', 'File size larger than 50Mb are not allowed!');
            return onError('File size larger than 50Mb are not allowed!');
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('onModel', 'Task');
        formData.append('model_id', activeTask.id);
        formData.append('type', 'field');
        const uploadingFiles = [...this.state.uploadingFiles, {fileUrl: URL.createObjectURL(file), file, progress: 0}];
        this.setState({
            uploadingFiles
        });
        const uploadedFileIndex = uploadingFiles.length - 1;
        axios
            .post(action, formData, {
                onUploadProgress: ({total, loaded}) => this.onUploadProgress(total, loaded, uploadedFileIndex),
            })
            .then((response) => this.uploadFileSuccess(response, uploadedFileIndex))
            .catch((error) => {
                console.log('error',error)
                this.uploadFileFailure(error, uploadedFileIndex)
            });
    }

    onUploadProgress = (total, loaded, uploadedFileIndex) => {
        const uploadingFiles = [...this.state.uploadingFiles];
        const progress = Math.round(loaded / total * 100).toFixed(2);
        if (uploadingFiles[uploadedFileIndex]) {
            uploadingFiles[uploadedFileIndex].progress = progress;
        }
        this.setState({
            uploadingFiles
        });
    }

    uploadFileSuccess = (response, uploadedFileIndex) => {
        const attachment = response.data.data[0];
        const uploadingFiles = [...this.state.uploadingFiles];
        uploadingFiles[uploadedFileIndex] = Object.assign({}, uploadingFiles[uploadedFileIndex], attachment);
        this.setState({
            uploadingFiles
        })
        this.handleFile(attachment._id)
    }

    uploadFileFailure = (error, uploadedFileIndex) => {
        const { uploadingFiles } = this.state;
        this.setState({
            uploadingFiles: uploadingFiles.slice(0, uploadedFileIndex).concat(uploadingFiles.slice(uploadedFileIndex+1))
        });
        showNotification('error', 'Error uploading attachments');
    }

    deleteAttachmentJustUploaded = (attachment) => {
        this.setState({
            uploadingFiles: this.state.uploadingFiles.filter(file => file._id !== attachment._id),
            files: this.state.files.filter(id => id !== attachment._id )
        });
    }

    deleteAttachment = (attachment) => {
        let ids = this.state.files.filter(id => id !== attachment._id)
        this.setState({files: ids})
        this.props.addValue(this.props.field.id, ids.length === 0 ? null : ids)
        this.props.deleteAttachment(attachment)
    }

    render() {
        let {field, readOnly, attachments, showError, showErrorAlert} = this.props
        let {files} = this.state
        let attach = []
        files && files.length > 0 && files.forEach(id => {
            if(id && attachments && attachments[id]){
                attach.push(attachments[id])
            }
        })
        // attachments && attachments.forEach(file => {
        //     let _index = this.state.files.findIndex(f => f === file._id)
        //     if(_index >= 0){
        //         attach.push(file)
        //     }
        // });

        return (
            <>
                <Upload
                    accept          = {field.options && field.options.join()}
                    disabled        = {readOnly}
                    multiple        = {field.multi_entry ? true : false}
                    showUploadList  = {false}
                    customRequest   = {this.uploadFile}
                    // listType        = "picture"
                    // className       = 'upload-list-inline'
                >
                    <Button
                        // size = 'large'
                        style     = {{fontSize:14}}
                        className = {showErrorAlert && showError ? 'error custom-btn fw mt-5' : 'custom-btn fw mt-5'}
                        disabled  = {readOnly}
                        block
                    >
                        <Icon type="upload" /> {field.alias_help_text ? field.alias_help_text : field.helpText}
                    </Button>
                </Upload>
                <Attachments
                    attachments                  = {attach}
                    uploadingFiles               = {this.state.uploadingFiles}
                    deleteAttachment             = {this.deleteAttachment}
                    deleteAttachmentJustUploaded = {this.deleteAttachmentJustUploaded}
                    forTaskField                 = {true}
                    readOnly                     = {readOnly}
                />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        attachments: state.attachments
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAttachments: (params) => dispatch(fetchAttachments(params))
    }
}


export default connect(mapStateToProps)(File)
