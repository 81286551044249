import React from 'react';
import { Row, Col, Icon, Progress, Tooltip, Divider } from 'antd';
import axios from 'axios';
import appUrl from '../../../constants/AppUrl';
import { readableFileSize, showNotification } from '../../../utils/commonFunctions';
import fileDownload from 'js-file-download';
import FilePreviewModal from '../../../filePreview/filePreviewModal'
import FilePreviewWrapper from '../../../filePreview/filePreviewWrapper'
import {
  Rar,
  Xml,
// Mpg,
  Docx,
  Doc,
  Pdf,
  Ppt,
  Txt,
  M4v,
  Zip,
  Psd,
  Wav,
  Html,
  Java,
  Gif,
  Mp3,
  XLSX,
  Flv,
  Mp4,
  Xls,
  Blank
} from './svg';

import './styles.css';

const imageFileTypes = ['image/gif', 'image/jpeg', 'image/png'];

class Attachments extends React.Component {

  constructor(props) {
    super(props);
    this.fileComp = React.createRef();
    const { uploadingFiles } = this.props;
    this.state = {
      showPreviewOfFileId : null,
      downloading: {},
      filesToExclude: uploadingFiles.reduce((result, file) => {
        if (file._id) {
          result[file._id] = true;
        }
        return result;
      }, {})
    };
  }

  togglePreview = (fileId) => {
    this.setState({showPreviewOfFileId: fileId})
  }

  componentDidMount(){
    this.fileComp.current && this.fileComp.current.addEventListener("keydown", (e) => {
      // press esc to close the preview
      if (e.keyCode === 27) {
          this.togglePreview(null);
      }
    })
  }

  componentWillUnmount(){
    this.fileComp.current && this.fileComp.current.removeEventListener("keydown", this.togglePreview());
  }

  componentDidUpdate(prevProps) {
    if (this.props.uploadingFiles !== prevProps.uploadingFiles) {
      this.setState({
        filesToExclude: this.props.uploadingFiles.reduce((result, file) => {
          if (file._id) {
            result[file._id] = true;
          }
          return result;
        }, {})
      })
    }
  }

  getFileTypeIcon = (mimetype) => {
    switch(mimetype) {
      case 'application/x-rar-compressed':
        return Rar;
      case 'application/xml':
        return Xml;
      case 'audio/mpeg':
        return Mp3;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return Docx;
      case 'application/msword':
        return Doc;
      case 'application/pdf':
        return Pdf;
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return Ppt;
      case 'text/plain':
        return Txt;
      case 'video/x-m4v':
        return M4v;
      case 'application/zip':
        return Zip;
      case 'image/vnd.adobe.photoshop':
        return Psd;
      case 'audio/x-wav':
        return Wav;
      case 'text/html':
        return Html;
      case 'text/x-java-source':
        return Java;
      case 'image/gif':
        return Gif;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return XLSX;
      case 'video/x-flv':
        return Flv;
      case 'video/mp4':
      case 'application/mp4':
        return Mp4;
      case 'application/vnd.ms-excel':
        return Xls;
      default:
        return Blank;
    }
  }

  deleteAttachment = (e, attachment) => {
    e.stopPropagation()
    this.props.deleteAttachment(attachment);
  }

  deleteAttachmentJustUploaded = (e, attachment) => {
    e.stopPropagation()
    this.props.deleteAttachment(attachment);
    this.props.deleteAttachmentJustUploaded(attachment)
  }

  download = (e, attachment) => {
    e.stopPropagation()
    const action = `${appUrl.ATTACHMENTS_DOWNLOAD}/${attachment._id}`;
    this.setState({
      downloading: Object.assign({}, this.state.downloading, {[attachment._id]: {progress: 0}})
    });
    axios({
      method: 'get',
      url: action,
      responseType: 'arraybuffer',
      onDownloadProgress: ({total, loaded}) => {
        const progress = Math.round(loaded / total * 100);
        this.setState({
          downloading: Object.assign({}, this.state.downloading, {[attachment._id]: {progress: progress}})
        })
      }
    })
    .then(response => {
      fileDownload(response.data, attachment.filename);
      const downloading = Object.assign({}, this.state.downloading);
      delete downloading[attachment._id];
      this.setState({
        downloading
      });
    })
    .catch(err => {
      showNotification("error", "Error in downloading attachment");
    })
  }

  render() {
    const { downloading={}, filesToExclude } = this.state;
    let { attachments=[], uploadingFiles, forTaskField, isProcess, readOnly } = this.props;
    if(!forTaskField) attachments = attachments.filter(file => file.type !== 'field')
    // if (attachments.length === 0 && uploadingFiles.length === 0) return null;
    return (
      <div tabIndex="0" ref={this.fileComp} style={{outline:0}}>
        {
            this.state.showPreviewOfFileId && attachments.length > 0 &&
                <FilePreviewModal toggleModal={this.togglePreview}>
                    <FilePreviewWrapper
                        data                = {attachments}
                        showPreviewOfFileId = {this.state.showPreviewOfFileId}
                        togglePreview       = {this.togglePreview}
                    />
                </FilePreviewModal>
        }
        <Row className={forTaskField ? 'mt-10 taskFile' : ''}>
          {/* {
            !forTaskField && attachments.length > 0 &&
              <>
                {!isProcess && <Divider style={{background:'#EFF2F5', margin: "12px 0 20px"}} />}
                <Col span={1}>
                  <Icon
                    style={{cursor: "pointer"}}
                    component={() => <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M17.1326 4.09046C17.0169 2.99316 16.482 2.00761 15.6266 1.31544L15.1344 0.917056C14.4036 0.325654 13.4832 0 12.5424 0C11.2879 0 10.1174 0.55728 9.3311 1.52904L1.76298 10.8815C1.06964 11.7381 0.751411 12.8136 0.866983 13.9097C0.982555 15.0057 1.5181 15.9911 2.37491 16.6846L2.86713 17.0829C3.59781 17.6743 4.5182 18 5.45867 18C5.60389 18 5.75078 17.9922 5.89527 17.9769C6.98468 17.8621 7.96559 17.3327 8.65449 16.4897C8.6568 16.487 8.65815 16.4854 13.7603 10.1812C14.669 9.05807 14.4946 7.40503 13.3717 6.49626L13.1137 6.28763C12.6497 5.91209 12.0651 5.70529 11.4679 5.70529C10.6714 5.70529 9.92819 6.05918 9.42889 6.67618L4.33269 12.9737C3.99971 13.3853 4.06357 13.9911 4.47516 14.3242C4.64548 14.462 4.8598 14.5379 5.07861 14.5379C5.37009 14.5379 5.64235 14.4082 5.82563 14.1818L10.9217 7.88424C11.055 7.71955 11.2536 7.62509 11.4664 7.62509C11.6282 7.62509 11.78 7.67883 11.9056 7.78047L12.1637 7.98935C12.3086 8.10666 12.3993 8.2737 12.4189 8.45983C12.4384 8.64596 12.3847 8.82828 12.2673 8.97316L7.17707 15.2636C6.75676 15.7826 6.13109 16.0802 5.46046 16.0803C4.95769 16.0803 4.46571 15.9063 4.07514 15.5901L3.58292 15.1918C3.12488 14.8211 2.83855 14.2942 2.77681 13.7083C2.71502 13.1224 2.8851 12.5474 3.25577 12.0894L10.8239 2.73705C11.2443 2.2177 11.8701 1.9198 12.541 1.9198C13.0439 1.9198 13.5359 2.09384 13.9264 2.4099L14.4185 2.80818C15.3641 3.57338 15.5108 4.96511 14.7457 5.9106C14.5844 6.11003 14.5103 6.36026 14.5372 6.61535C14.5642 6.8704 14.6888 7.09976 14.8881 7.26102C15.0585 7.39886 15.2728 7.47482 15.4916 7.47482C15.7831 7.47482 16.0553 7.34503 16.2385 7.11866C16.9308 6.26319 17.2484 5.18772 17.1326 4.09046Z" fill="#44566C"/>
                    </svg>}
                  />
                </Col>
              </>
          } */}
          <Col span={forTaskField ? 24 : 23}>
            <Row className='wrapper'>
              {uploadingFiles.map((attachment, index) => {
                if (imageFileTypes.indexOf(attachment.file.type) !== -1) return false;
                if (parseInt(attachment.progress) === 100) {
                  return (
                    <div className="attachment-container-non-image" onClick={e=>this.togglePreview(attachment._id)}>
                      <div style={{display: "flex"}}>
                        <Icon
                          component={this.getFileTypeIcon(attachment.file.type)}
                        />
                        <div style={{margin: "16px 0px"}} className='truncate'>
                          <Tooltip title={attachment.file.name}>
                            <div>{attachment.file.name.substring(0, 10)}{attachment.file.name.length > 10 && '...'}</div>
                          </Tooltip>
                          <div>{readableFileSize(attachment.file.size)}</div>
                        </div>
                        <div style={{margin: "16px 0px", display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center"}}>
                          <Icon style={{margin: 0}} type="download" onClick={(e)=>this.download(e, attachment)} />
                          {downloading[attachment._id] && <div className="attachment-downloading non-image">
                            <Progress type="circle" percent={downloading[attachment._id].progress} width={25} showInfo={false}/>
                          </div>}
                          {
                            !readOnly && <Icon style={{margin: "0px 16px"}} type="delete" onClick={(e)=>this.deleteAttachmentJustUploaded(e, attachment)} />
                          }
                        </div>
                      </div>
                    </div>
                  );

                } else {
                  return (
                    <div className="attachment-container-non-image">
                      <div style={{display: "flex"}}>
                        <Icon
                          component={this.getFileTypeIcon(attachment.file.type)}
                        />
                        <div style={{margin: "16px 0px"}} className='truncate'>
                          <Tooltip title={attachment.file.name}>
                            <div>{attachment.file.name.substring(0, 15)}{attachment.file.name.length > 15 && '...'}</div>
                          </Tooltip>
                          <div>{readableFileSize(attachment.file.size)}</div>
                        </div>
                      </div>
                      <Progress percent={attachment.progress} showInfo={false}/>
                    </div>
                  );
                }
              })}
              {attachments.map((attachment, index) => {
                if (imageFileTypes.indexOf(attachment.mimetype) !== -1) return false;
                return !filesToExclude[attachment._id] && (
                  <div className="attachment-container-non-image" key={attachment._id} onClick={e=>this.togglePreview(attachment._id)}>
                    <div style={{display: "flex"}}>
                      <Icon
                        component={this.getFileTypeIcon(attachment.mimetype)}
                      />
                      <div style={{margin: "16px 0px"}} className='truncate'>
                        <Tooltip title={attachment.filename}>
                          <div>{attachment.filename.substring(0, 10)}{attachment.filename.length > 10 && '...'}</div>
                        </Tooltip>
                        <div>{readableFileSize(attachment.size)}</div>
                      </div>
                      <div style={{margin: "16px 0px", display: "flex", flexGrow: 1, justifyContent: "flex-end", alignItems: "center"}}>
                        <Icon style={{margin: 0}} type="download" onClick={(e)=>this.download(e, attachment)} />
                        {downloading[attachment._id] && <div className="attachment-downloading non-image">
                          <Progress type="circle" percent={downloading[attachment._id].progress} width={25} showInfo={false}/>
                        </div>}
                        {
                          !readOnly && <Icon style={{margin: "0px 16px"}} type="delete" onClick={(e)=>this.deleteAttachmentJustUploaded(e, attachment)} />
                        }
                      </div>
                    </div>
                  </div>
                );
              })}
              {uploadingFiles.map((attachment, index) => {
                if (imageFileTypes.indexOf(attachment.file.type) === -1) return false;
                if (parseInt(attachment.progress) === 100) {
                  return (
                    <div className="attachment-container" key={attachment._id} onClick={e=>this.togglePreview(attachment._id)}>
                      <img alt={attachment.fileUrl} src={attachment.fileUrl} />
                      {downloading[attachment._id] && <div
                        className="attachment-downloading"
                      >
                        <Progress type="circle" percent={downloading[attachment._id].progress} width={40} showInfo={false}/>
                      </div>}
                      {!downloading[attachment._id] && <div className="attachment-container-overlay">
                          <Icon type="download" onClick={(e)=>this.download(e, attachment)} />
                          {
                            !readOnly && <Icon type="delete" onClick={(e)=>this.deleteAttachmentJustUploaded(e, attachment)} />
                          }
                      </div>}
                      <div className='truncate'>
                        <Tooltip title={attachment.file.name}>
                          <span>{attachment.file.name.substring(0, 20)}{attachment.file.name.length > 20 && '...'}</span>
                        </Tooltip>
                        {!forTaskField && <span style={{float: "right"}}>{readableFileSize(attachment.file.size)}</span>}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="attachment-container" key={index}>
                      <img src={attachment.fileUrl} alt=''/>
                      <div className="attachment-uploading">
                        <Progress percent={attachment.progress} showInfo={false} style={{margin: 10}}/>
                      </div>
                    </div>
                  );
                }
              })}
              {attachments.map((attachment, index) => {
                if (imageFileTypes.indexOf(attachment.mimetype) === -1) return false;
                return !filesToExclude[attachment._id] && (
                  <div className="attachment-container" key={attachment._id} onClick={e=>this.togglePreview(attachment._id)}>
                    <img src={attachment.thumb_location} alt=''/>
                    {downloading[attachment._id] && <div
                      className="attachment-downloading"
                    >
                      <Progress type="circle" percent={downloading[attachment._id].progress} width={40} showInfo={false}/>
                    </div>}
                    {!downloading[attachment._id] && <div className="attachment-container-overlay">
                        <Icon type="download" onClick={(e)=>this.download(e, attachment)} />
                        {
                          !readOnly && <Icon type="delete" onClick={(e)=>this.deleteAttachment(e, attachment)} />
                        }
                    </div>}
                    <div className='truncate'>
                      <Tooltip title={attachment.filename}>
                        <span>{attachment.filename.substring(0, 20)}{attachment.filename.length > 20 && '...'}</span>
                      </Tooltip>
                      {!forTaskField && <span style={{float: "right"}}>{readableFileSize(attachment.size)}</span>}
                    </div>
                  </div>
              )})}
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Attachments;
