import React from 'react';
import {Redirect} from 'react-router-dom';
import {Modal, Row} from 'antd'
import {fetchDataset, updateDataset, deleteDataset} from './duck/DatasetActions';
import {guidGenerator} from '../utils/commonFunctions';
import FormBuilderHeader from "./components/formBuilderHeader";
import FormBuilderContainer from '../processBuilder/formBuilder/formBuilderContainer'
import {showNotification} from "../utils/commonFunctions";
import {connect} from 'react-redux';


const {confirm} = Modal;

let count = 0;

class DatasetContainer extends React.Component {

    state = {
        loading: false,
        dataset: undefined,
        redirect: false,
        redirectToList: false,
        list: true,
        dummyTasks: [],
        activeBo: null,
        sections: [
            {
                id: guidGenerator(),
                section_name: 'Untitled Section',
                section_description: 'Section Information',
                section_layout: [],
                is_section: true,
                conditions: false,
                dependency: {
                    draw_data: {},
                    logic: {}
                }
            }
        ],
        allFields: {}
    }

    sort = (val) => {
        this.setState({sortBy: val})
    }

    searchBO = (e) => {
        this.setState({searchText: e.target.value})
    }

    updateSections = (data) => {
        this.setState({sections: data});
    }

    updateFields = (fields) => {
        this.setState({allFields: fields})
    }

    updateSteps = (steps) => {
        this.setState({steps})
    }

    onCancel = () => {
        if ((Object.keys(this.state.allFields).length > 0) && this.props.currentBO && Object.keys(this.props.currentBO.attributes.fields).length > 0) {
            this.setState({redirectToList: true})
        } else {
            this.setState({redirect: true})
        }
    };
    onPublish = () => {
        this.publishBo()
    }

    publishBo = () => {
        let activeBo = this.props.currentBO;
        let data = {
            name: activeBo ? activeBo.attributes.name : '',
            description: activeBo ? activeBo.attributes.description : '',
            fields: this.state.allFields,
            sections: this.state.sections,
        }
        if (Object.keys(data.fields).length > 0) {
            this.props.updateDataset(activeBo.id, data);
            this.setState({redirectToList: true})
            console.log('BO maker data ----->', data)
            showNotification('success', 'Published successfully');
        } else {
            showNotification('error', 'Please add at least one field.')
        }
    }

    componentDidMount() {
        document.querySelector('.app-content-block').classList.add('overlayWithHeader')
        const {match} = this.props;
        const {params: {id}} = match;
        count = 1;

        if (!(this.props.currentBO && this.props.currentBO.id === id)) {
            this.props.fetchDataset(id);
        } else {
            this.setState({
                loading: false,
                activeBO: this.props.currentBO,
            });
        }
    }

    componentWillUnmount() {
        document.querySelector('.app-content-block').classList.remove('overlayWithHeader')
        count = 1;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.currentBO && count === 1) {
            if (Object.keys(this.props.currentBO.attributes.sections).length > 0 && Object.keys(this.props.currentBO.attributes.fields).length > 0) {
                this.setState({
                    sections: this.props.currentBO.attributes.sections,
                    allFields: this.props.currentBO.attributes.fields
                })
                count = 2;
            }
        }
    }

    updateDataset = (dataset) => {
        this.props.updateDataset(dataset);
    }

    deleteDataset = (id) => {
        confirm({
            title: 'Do you want to delete this dataset?',
            content: '',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                this.props.deleteDataset(id);
                this.setState({redirect: true})
            },
            onCancel: () => {
                console.log('Cancel');
            }
        });
    }

    render() {
        let {activeBo, redirect, loading, sections, allFields, redirectToList} = this.state;
        const {currentBO} = this.props;
        if (redirectToList) {
            // console.log('step:1')
            let url = `/app/dataset/${currentBO.id}`;

            return <Redirect to={{
                pathname: url,
                state: {redirect: false}
            }}/>
        } else if (redirect) {
            let url = `/app/dataset`;
            return <Redirect to={url} push/>
        } else {
            if (currentBO) {
                return (
                    <Row className='fh' style={{height: '700px'}}>
                        <FormBuilderHeader
                            name={currentBO.attributes.name}
                            description={currentBO.attributes.description}
                            id={currentBO.id}
                            onPublish={this.onPublish}
                            onCancel={this.onCancel}
                            loader={loading}
                        />
                        <FormBuilderContainer
                            activeBo={currentBO}
                            sections={sections}
                            allFields={allFields}
                            updateSections={this.updateSections}
                            updateFields={this.updateFields}
                        />
                    </Row>
                )
            }
        }
        return (
            <div style={{height: "100%", overflow: "hidden"}}>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentBO: state.dataset.active
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchDataset: (id) => dispatch(fetchDataset(id)),
        updateDataset: (id, data) => dispatch(updateDataset({id: id, data})),
        deleteDataset: (id) => dispatch(deleteDataset(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DatasetContainer);
