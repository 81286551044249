import React from 'react'
import { Input } from 'antd';
import { guidGenerator } from '../../../utils/commonFunctions';

class SegmentForm extends React.Component {

    state = {
        name: this.props.name
    }

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.inputRef.current.focus();
    }

    changeName = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    addLane = () => {
        console.log(this.props);
        const name = this.state.name.trim();
        name && this.props.onAdd({
            id: guidGenerator(),
            name,
            tasks: []
        });
        !name && this.props.onCancel();
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.addLane();
        }
    }

    render() {
        const { name } = this.state;
        return (
            <Input
                placeholder="Enter section name"
                ref={this.inputRef}
                style={{marginRight: 10, width: 200}}
                size="small"
                value={(name !== '+ Add Section' && name) || ''}
                onChange={this.changeName}
                onKeyDown={this.onKeyDown}
                onBlur={this.addLane}
                maxLength="50"
            />
        );
    }
}

export default SegmentForm;
