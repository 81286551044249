import React from 'react';
import { Tabs,Button } from "antd";
// let {title} = Typography;
const { TabPane } = Tabs;
// const style={
//     wordBreak: 'break-all'
// }

let activeTab = 'SUM';
// const tabStyle = {
//     position: 'absolute',
//     zIndex: '9',
//     top: '80%',
//     width: '96%',
//     backgroundColor: '#e8e8e8',
//     height: 'auto',
//     maxHeight: '100%',
// }

const AggregateOptionsBox = (props) => {

    const onChangeTab = (e) => {
        activeTab = e
    }

    const onClick = (e) => {
        console.log('eeee', e,  e.target, activeTab)
        if (e.target.id) {
            let obj = {
                operation: activeTab,
                key: e.target.id,
                label: e.target.innerText
            }
            props.onSelectAggregateOption(obj)
        }
    }

    let selectedAggregateFieldsForSum = new Set();
    let selectedAggregateFieldsForCount = new Set();

    console.log('eeee', props)

    if(props.selectedAggregateFields){
        props.selectedAggregateFields.map(field=>{
            switch(true){
                case field.operation === 'SUM':
                    selectedAggregateFieldsForSum.add(field.key);
                    break;
                case field.operation === 'COUNT':
                    selectedAggregateFieldsForCount.add(field.key);
                    break;
                default: break;
            }
        })
    }

    let sumContent = props.numberTypeFields.filter(field=>!selectedAggregateFieldsForSum.has(field.key)).map(field=><p key={field.key} style={{margin: '.5rem 0', padding: '.5rem'}}>
        <Button type="link" id={field.key} onClick={onClick}>{field.label}</Button>
    </p>)

    let content = props.numberTypeFields.filter(field=>!selectedAggregateFieldsForCount.has(field.key)).map(field=><p key={field.key} style={{margin: '.5rem 0', padding: '.5rem'}}>
        <Button type="link" id={field.key} onClick={onClick}>{field.label}</Button>
    </p>)

    return (
        <Tabs onChange={onChangeTab} type="card" style={{backgroundColor:'#fff'}}>
            <TabPane tab="Sum" key="SUM">
                {sumContent}
            </TabPane>
            <TabPane tab="Count" key="COUNT">
                {content}
            </TabPane>
        </Tabs>
    )

    // return (
    //     <Row gutter={24} style={{...props.rowStyle, marginTop:'1rem', backgroundColor:'#fff'}}>
    //         <Col span={8}>
    //             <div onClick={onClick} id="SUM">
    //                 <p><strong>Sum</strong></p>
    //                 {sumContent}
    //             </div>
    //         </Col>
    //       <Col span={8}>
    //           <div onClick={onClick} id="COUNT">
    //               <p><strong>Count</strong></p>
    //               {content}
    //           </div>
    //       </Col>
    //     </Row>
    // );
};

export default AggregateOptionsBox;
