const ACTIONS = {
    
    FETCH_REQUEST       : 'FETCH_PROCESS_REQUEST',
    FETCH_SUCCESS       : 'FETCH_PROCESS_SUCCESS',
    FETCH_FAIL          : 'FETCH_PROCESS_FAIL',
    
    FETCH_ONE_REQUEST   : 'FETCH_ONE_PROCESS_REQUEST',
    FETCH_ONE_SUCCESS   : 'FETCH_ONE_PROCESS_SUCCESS',
    FETCH_ONE_FAIL      : 'FETCH_ONE_PROCESS_FAIL',
    
    FETCH_MORE_REQUEST  : 'FETCH_MORE_PROCESS_REQUEST',
    FETCH_MORE_SUCCESS  : 'FETCH_MORE_PROCESS_SUCCESS',
    FETCH_MORE_FAIL     : 'FETCH_MORE_PROCESS_FAIL',
    
    CREATE_REQUEST      : 'CREATE_PROCESS_REQUEST',
    CREATE_SUCCESS      : 'CREATE_PROCESS_SUCCESS',
    CREATE_FAIL         : 'CREATE_PROCESS_FAIL',
    
    UPDATE_REQUEST      : 'UPDATE_PROCESS_REQUEST',
    UPDATE_SUCCESS      : 'UPDATE_PROCESS_SUCCESS',
    UPDATE_FAIL         : 'UPDATE_PROCESS_FAIL',

    DELETE_REQUEST      : 'DELETE_PROCESS_REQUEST',
    DELETE_SUCCESS      : 'DELETE_PROCESS_SUCCESS',
    DELETE_FAIL         : 'DELETE_PROCESS_FAIL',
}

 export default ACTIONS;