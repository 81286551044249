import React, { Component } from 'react'
import {Row, Col, Input, Checkbox} from 'antd'
import {showNotification} from '../../../utils/commonFunctions'

const help = {
    'assigned'    : 'This action allows the current assignee to assign the step to a different user.',
    'rejected'    : 'This action sends the item back to the requestor and ends the process. ',
    'submitted'   : 'This action advances the process to the next step.',
    'stopped'     : 'This action stops the process.',
}

const actionName = {
    'assigned'    : 'Assignment',
    'rejected'    : 'Rejection',
    'submitted'   : 'Submission',
    'stopped'     : 'Stop',
}
export default class Actions extends Component {

    state = {
        assigned : {
            alias   : !this.props.activeStep.start && this.props.activeStep.actions.assigned.alias,
            action  : !this.props.activeStep.start && this.props.activeStep.actions.assigned.action,
            show    : !this.props.activeStep.start && this.props.activeStep.actions.assigned.show,
            auto    : !this.props.activeStep.start && this.props.activeStep.actions.assigned.auto,
        },
        rejected:{
            alias   : !this.props.activeStep.start && this.props.activeStep.actions.rejected.alias,
            action  : !this.props.activeStep.start && this.props.activeStep.actions.rejected.action,
            show    : !this.props.activeStep.start && this.props.activeStep.actions.rejected.show,
            auto    : !this.props.activeStep.start && this.props.activeStep.actions.rejected.auto,
        },
        submitted:{
            alias   : this.props.activeStep.actions.submitted.alias,
            action  : this.props.activeStep.actions.submitted.action,
            show    : this.props.activeStep.actions.submitted.show,
            auto    : this.props.activeStep.actions.submitted.auto,
        },
        stopped:{
            alias   : this.props.activeStep.actions.stopped.alias,
            action  : this.props.activeStep.actions.stopped.action,
            show    : this.props.activeStep.actions.stopped.show,
            auto    : this.props.activeStep.actions.stopped.auto,
        }
    }

    change = (action) => {
        let tmp     = Object.assign({},this.state[action])
        tmp.show    = !this.state[action].show
        this.setState({[action]:tmp})
        let steps   = Object.assign({},this.props.steps)
        steps[this.props.activeStep.id].actions[action].show = !this.state[action].show
        if(!tmp.show){
            steps[this.props.activeStep.id].triggers[action].sms.status     = 'off'
            steps[this.props.activeStep.id].triggers[action].webhook.status = 'off'
            steps[this.props.activeStep.id].triggers[action].email.status   = 'off'
        }
        console.log(action,this.state[action].show)
        console.log(steps[this.props.activeStep.id].triggers[action])
        this.props.updateSteps(steps)
    }

    changeAlias = (e, action) =>{
        let tmp      = Object.assign({},this.state[action])
        tmp.alias    = e.target.value
        this.setState({[action]: tmp})
    }

    changeAction = (e, action) =>{
        let tmp         = Object.assign({},this.state[action])
        tmp.action      = e.target.value
        this.setState({[action]: tmp})
    }

    markAutoAction = (e, action) => {
        let tmp         = Object.assign({},this.state[action])
        tmp.auto = e.target.checked;
        this.setState({[action]: tmp})
    }

    blur = (type, action) => {
        let steps   = Object.assign({},this.props.steps)
        if((typeof this.state[action][type] === "string" && this.state[action][type].trim() !== '') || typeof this.state[action][type] === "boolean"){
            steps[this.props.activeStep.id].actions[action][type] = this.state[action][type]
            this.props.updateSteps(steps)
        }else{
            let tmp     = Object.assign({},this.state[action])
            tmp[type]   = steps[this.props.activeStep.id].actions[action][type]
            this.setState({[action]: tmp})
            showNotification('error',actionName[action] + ' ' +(type === 'action' ? 'action' : 'status') +' name cannot be blank')
        }
    }

    render() {
        let {activeStep} = this.props
        return (
            <>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500'}} className='mt-20 mb-20'>
                    What actions can be taken on this task?
                </Row>
                <Row style={{color:'#44566C'}} className='mb-20'>
                    Determine what actions the assignee can take at this step. Action and Status names are set to default.
                </Row>
                <Row className='mb-20 pt-20 center' style={{color:'#56667A',textShadow:'0 0'}}>
                    <Col span={4}>Stage</Col>
                    <Col span={7}>Action name</Col>
                    <Col span={7}>Status name</Col>
                    <Col span={6}></Col>
                </Row>
                {
                    Object.keys(activeStep.actions).map((action, index)=>{
                        return(
                            <Row key={index}>
                                <Row className='mb-20 center pt-20' type='flex' align='middle' gutter={16}>
                                    <Col span={4} className='text-color'>{actionName[action]}</Col>
                                    <Col span={7}>
                                        <Input size='large' className='custom-input center' value={this.state[action].action} onBlur={e=>this.blur('action', action)} onChange={e=>this.changeAction(e, action)}/>
                                    </Col>
                                    <Col span={7}>
                                        <Input size='large' className='custom-input center' value={this.state[action].alias} onBlur={e=>this.blur('alias', action)} onChange={e=>this.changeAlias(e, action)}/>
                                    </Col>
                                    <Col span={6}>
                                        <ActionSwitch
                                            value   = {this.state[action].show}
                                            change  = {this.change}
                                            action  = {action}
                                        />
                                    </Col>
                                </Row>
                                <Row type="flex" justify="flex-end" className='mb-20 mt-10'>
                                    <Col className='text-color'>
                                        <Checkbox onChange={(e) => this.markAutoAction(e, action)} checked={this.state[action].auto} onBlur={e => this.blur('auto', action)}/>
                                        <span style={{marginLeft: 10}}>Mark {this.state[action].alias} automatically on assignment and {this.state[action].action} request</span>
                                    </Col>
                                </Row>
                                <Row type='flex' justify='center' style={{color:'#A1AAB5',margin:'auto',width:'75%'}}>
                                    {
                                        help[action]
                                    }
                                </Row>
                            </Row>
                        )
                    })
                }
            </>
        )
    }
}

const ActionSwitch = (props) => {
    return(
        <Row type='flex' className='custom-switch' onClick={e=>props.change(props.action)}>
            <div className={props.value ? 'active' : ''}  style={{minWidth:'60px',textAlign:'center'}}>Show</div>
            <div className={!props.value ? 'active' : ''} style={{minWidth:'60px',textAlign:'center'}}>Hide</div>
        </Row>
    )
}
