import React from 'react';
import {Row, Dropdown, Menu, Input, Select, Icon, Col, Button} from 'antd';
import AddNewButton from "../../utils/addNewButton";
import axios from 'axios'
import appUrl from '../../constants/AppUrl';
import fileDownload from 'js-file-download'
import {showNotification} from '../../utils/commonFunctions'
import moment from 'moment'

const {Option} = Select;

class RecordHeader extends React.Component {
    constructor(props) {
        super(props);
        this.datasetNameRef = React.createRef();
        this.datasetDescriptionRef = React.createRef();
        this.state = {
            enableDatasetNameEdit: false,
            enableDatasetDescriptionEdit: false,
            datasetName: props.data.attributes.name,
            datasetDescription: props.data.attributes.description,
            downloading: false
        }
    }

    enableDatasetNameEdit = () => {
        this.setState({
            enableDatasetNameEdit: true
        }, () => {
            this.datasetNameRef.current.focus();
        })
    }

    enableDatasetDescriptionEdit = () => {
        this.setState({
            enableDatasetDescriptionEdit: true
        }, () => {
            this.datasetDescriptionRef.current.focus();
        });
    }

    updateDatasetName = (e) => {
        this.setState({
            datasetName: e.target.value
        })
    }
    updateDataset = () => {
        const data = {
            name: this.state.datasetName,
            description: this.state.datasetDescription
        }
        this.props.updateDataset(data);
        this.setState({
            enableDatasetNameEdit: false,
            enableDatasetDescriptionEdit: false
        })
    }

    updateDatasetDescription = (e) => {
        this.setState({
            datasetDescription: e.target.value
        })
    }

    exportEntries = () => {
        this.setState({downloading: true})
        axios({
            method: "GET",
            url     : `${appUrl.DATASET_URL}/export/${this.props.currentDataset.id}`,
            contentType: 'application/doc; charset=utf-8',
            responseType: 'arraybuffer',
        }).then(res => {
            console.log("res.data", res);
            fileDownload(res.data, `${this.props.currentDataset.attributes.name}-${moment().format("DD-MM-YYYY-HH:mm")}.xlsx`);
            showNotification("success", "File downloaded successfully!");
            this.setState({downloading: false})
        })
        .catch(err => {
            showNotification("error", "Error in downloading file! Please try again");
            console.log("onDownloadOrder ", err);
            this.setState({downloading: false})
        });
    }

    render() {
        let editable = false, props = this.props;
        let {enableDatasetNameEdit, enableDatasetDescriptionEdit, datasetName, datasetDescription} = this.state;

        return (
            <Row>
                <Row type='flex' justify='space-between' align={'middle'}>
                    <Col>
                        <Row>
                            {
                                enableDatasetNameEdit && <Input
                                    maxLength={35}
                                    style={{width: "100%"}}
                                    onChange={this.updateDatasetName}
                                    value={datasetName}
                                    ref={this.datasetNameRef}
                                    onKeyDown={e => e.key === 'Enter' && this.updateDataset()}
                                    onBlur={this.updateDataset}
                                />
                            }
                            {
                                !enableDatasetNameEdit && <span
                                    style={{
                                        fontSize: '20px',
                                        lineHeight: '18px',
                                        color: '#44566C',
                                        fontWeight: 'bold',
                                        marginRight: '8px'
                                    }}
                                    onClick={this.enableDatasetNameEdit}
                                >
                                    {datasetName}
                                </span>
                            }
                        </Row>
                        {
                            enableDatasetDescriptionEdit && <Input
                                value={datasetDescription}
                                onChange={this.updateDatasetDescription}
                                style={{width: "80%"}}
                                ref={this.datasetDescriptionRef}
                                onKeyDown={e => e.key === 'Enter' && this.updateDataset()}
                                onBlur={this.updateDataset}
                            />
                        }
                        {
                            !enableDatasetDescriptionEdit && props.data.attributes.description && <span
                                style={{fontSize: '12px', lineHeight: '18px', color: '#8798A8'}}
                                onClick={this.enableDatasetDescriptionEdit}
                            >
                                {datasetDescription}
                            </span>
                        }
                        {
                            !enableDatasetDescriptionEdit && !props.data.attributes.description && <span
                                style={{fontSize: '12px', lineHeight: '18px', color: '#8798A8'}}
                                onClick={this.enableDatasetDescriptionEdit}
                            >
                                Add description
                            </span>
                        }
                    </Col>
                    {/* <Dropdown
                        trigger={["click"]}
                        overlay={
                            <Menu onClick={(e) => props.handleMenu(props.data.id, e)}>
                                <Menu.Item key="edit">
                                    <a>Edit form</a>
                                </Menu.Item>
                                <Menu.Item key="delete">
                                    <a style={{color: "#FF3D57"}}>Delete</a>
                                </Menu.Item>
                            </Menu>
                        }>
                        <Icon type="more" style={{fontSize: 20, cursor: "pointer", align: 'middle', marginLeft: '15px'}}/>
                    </Dropdown> */}
                    <span>
                        <Button type="danger" loading={this.state.downloading} className='mr-10' onClick={this.exportEntries}>
                            {this.state.downloading ? 'Downloading entries' : 'Export Entries'}
                        </Button>
                        <Button type="primary" onClick={(e) => props.handleMenu(props.data.id, {key:'edit'})}>
                            <Icon type='edit' />
                            Edit Form
                        </Button>
                    </span>
                </Row>
                <Row type='flex' justify='space-between' style={{marginTop: 20}}>
                    <span>
                        Show
                        <Select getPopupContainer={(e) => e.parentNode} defaultValue="10" style={{width: 70, marginLeft: 7}} onChange={props.paginationSet}>
                            <Option value="10">10</Option>
                            <Option value="20">20</Option>
                            <Option value="50">50</Option>
                            <Option value="100">100</Option>
                        </Select>
                    </span>
                    <span>
                        {
                            props.showDelete ?
                                <span>
                                    {/*<AddNewButton disabled={editable} trigger={props.edit} icon={'edit'}/>*/}
                                    <AddNewButton disabled={editable} trigger={props.delete} icon={'delete'}/>
                                </span>
                                : <AddNewButton disabled={editable} trigger={() => props.create(true)}/>
                        }
                    </span>
                </Row>
            </Row>
        );

    }
}

export default RecordHeader;