import React from 'react';
import App from "../App";
import AuthRouters from "./AuthRouters";
import PrivateRoute from "./PrivateRoute";
import RedirectPath from "./RedirectPath";
// import PageNotFound from "../exceptions/PageNotFound";
// import {Switch,Route,Redirect} from 'react-router-dom';
import { hot } from 'react-hot-loader/root';

// import {connect} from 'react-redux';
// import jwtDecode from 'jwt-decode';
// import {initializeSocket} from "./Socket";

const AuthContainer = (props) => {
    return (
        <>
            <AuthRouters />
            <RedirectPath  path='/' exact />
            <PrivateRoute path='/app' component={App} />
        </>
    )
}

export default process.env.NODE_ENV === 'development' ? hot(AuthContainer) : AuthContainer

// export default AuthContainer;
