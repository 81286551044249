import React from 'react';
import {Table, Input, Button, Icon, Dropdown, Menu, Row, Avatar, Tag} from 'antd';
// import {fetchMoreUsersRequest} from "../duck/UserActions";
let data = [];

class PeopleList extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            searchText: '',
            loading: false
        };
    }
    componentDidUpdate = (prev, state) => {
        if(prev.list.length !== this.props.list.length){
            this.handleTableChange(false)
        }
        // if(this.state.loading && prev.list.length === this.props.list.length ){
        //     this.handleTableChange(false)
        // }
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            dataIndex && record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                this.searchInput.select()
            }
        },
        render: (text, list) => {
            return <Row onClick={() => this.props.onClick('viewPeople', list)}>
                <label>{text}</label>
            </Row>
        }
    });

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };
    onClick = (active, verified, list) => {
        if (active) {
            if (verified) {
                this.props.onClick('Deactivate', list.id)
            } else {
                this.props.onClick('Re-invite', list.email)
            }
        } else {
            if (verified) {
                this.props.onClick('Activate', list.id)
            } else {
                this.props.onClick('Re-invite', list.email)
            }

        }
    };

    handleTableChange = (load) => {
        this.setState({loading: load})
    };

    render() {
        const {list, onClick, editable, loadmore, count} = this.props

        let STATUS = null, actionButtonText = null, tagText = null;
        if (list.length > 0) {
            data = list.map(user => {
                let roles = user.role_id && user.role_id === 'admin' ? 'Administrator' : user.role_id && user.role_id === 'super_admin' ? 'Super Administrator' : 'Member';

                if (user.active) {
                    if (user.verified) {
                        actionButtonText = 'Deactivate';
                        tagText = null;
                        STATUS = {color: 'green', text: 'Active'}
                    } else {
                        actionButtonText = 'Re-invite';
                        tagText = 'Invited';
                        STATUS = {color: 'orange', text: 'Invitation Pending'}
                    }
                } else {
                    if (user.verified) {
                        tagText = 'De-Activated';
                        actionButtonText = 'Activate';
                        STATUS = {color: 'red', text: 'Inactive'}
                    } else {
                        tagText = 'Invited';
                        actionButtonText = 'Re-invite';
                        STATUS = {color: 'red', text: 'Inactive'}
                    }

                }
                return ({
                    ...user,
                    key: user.id,
                    actionButtonText: actionButtonText,
                    action: STATUS,
                    role: roles,
                    tagText
                })
            })
        } else {
            data = []
        }

        const columns = [
            {
                key: "user_avatar",
                title: '',
                width: '2%',
                render: (text, list) => {
                    return (
                        list.picture ?
                            <Avatar size="35" src={list.picture}/>
                            :
                                list.name === '-' ?
                                    <Avatar size={'35'} icon={'user'}/>
                                    :
                                    <Avatar size="35" style={{
                                        color: 'white',
                                        backgroundColor: list.action.color
                                    }}>{list.name && list.name[0]}</Avatar>
                    )
                }
            },
            {
                key: "user_name",
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: '28%',
                ...this.getColumnSearchProps('name'),
                render: (text, list) => {
                    return (
                        <Row type='flex'  align='middle' onClick={() => onClick('viewPeople', list)}>
                            <span>
                                <span style={{color: '#44566C'}}>
                                    {list.name}
                                    {list.tagText && <span className='ml-20'><Tag color={list.action.color}>{list.tagText}</Tag></span>}
                                </span>
                                <br/>
                                <span style={{color: '#8697A8'}}>{list.designation}</span>
                            </span>
                        </Row>
                    )
                }
            },
            {
                key: "user_email",
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                width: '25%',
                ...this.getColumnSearchProps('email'),
            },
            {
                key: "user_role",
                title: 'Role',
                dataIndex: 'role',
                key: 'role_id',
                width: '15%',
                ...this.getColumnSearchProps('role'),
            },
            {
                key: "user_teams",
                title: 'Team',
                dataIndex: 'teams',
                key: 'teams',
                width: '20%',
                ...this.getColumnSearchProps('teams'),
            },
            // {
            //     title: 'Phone',
            //     dataIndex: 'phone',
            //     key:'phone',
            //     width: '15%',
            //     ...this.getColumnSearchProps('phone'),
            // },
            {
                key: "dropDown",
                title: '',
                width: '5%',
                render: (text, list) => {
                    let dropDown = editable ? <Dropdown overlay={<Menu>
                        <Menu.Item key={list.id} onClick={() => this.onClick(list.active, list.verified, list)}>
                            {list.actionButtonText}
                        </Menu.Item>
                        {/*<Menu.Item key={list.id+1} onClick={()=>this.props.onDelete(list.id)}>Delete</Menu.Item>*/}
                    </Menu>}>
                        <Icon type="ellipsis" style={{fontSize: '40px'}}/>
                    </Dropdown> : null;
                    return <span style={{color: '#8697A8'}}>
                        {dropDown}
                     </span>
                }
            },
        ];
        return <Table
                    size='medium'
                    style={{marginTop: '30px'}}
                    className='custom-table'
                    rowKey={record => record.id}
                    columns={columns}
                    onChange={(e)=>{
                        e.current*10 !== data.length && loadmore(e);
                        e.current*10 !== data.length && count - 1 !== data.length && this.handleTableChange(true)
                    }}
                    dataSource={data}
                    loading={this.state.loading}
                    pagination={data.length > 10 ? {total: count, defaultCurrent:1, pageSizeOptions:["a","b"]} : false}
                />
    }
}


export default PeopleList