import ACTIONS from './PageNavigationActionTypes';

const initialState = {activeTab:null,loading:false,error:null}

const activePageTabReducer = (state=initialState, action) => {
    switch(action.type){
        case ACTIONS.SET_ACTIVE_TAB: return {...state,activeTab:action.category,loading:false};
        case ACTIONS.RESET_ACTIVE_TAB: return {...state,activeTab:action.category,loading:false};

        default:
            return state;
    }
}

export default activePageTabReducer;