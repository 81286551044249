import  {createStore, applyMiddleware, compose} from "redux";
import rootReducer from "./RootReducer";
// import freezeState from 'deep-freeze';
import createSagaMiddleware from 'redux-saga';
import {rootSaga} from './RootSaga';
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
// import logger from 'redux-logger';
import handleException from '../exceptions/handleException';
// import middleware from './CustomMiddleware';

// const persistConfig = {
//     key: 'root',
//     storage,
// }


let state = rootReducer;
let sagaMiddleware = createSagaMiddleware({onError:handleException});

// if(process.env.NODE_ENV !== 'production'){
//     state = freezeState(rootReducer);
//     localStorage.removeItem('persist:root')
// }

// const persistedReducer = persistReducer(persistConfig, state)
const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose;
const store = createStore(state, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);

// let persistor = persistStore(store)
export { store }
