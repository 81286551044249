import React from 'react';
import {Table, Input, Button, Icon, Spin, Empty, Row} from 'antd';
import moment from 'moment';
// import {object} from "prop-types";
import {
    Blank
} from './Attachments/svg';
import { connect } from 'react-redux';

class EntriesList extends React.Component {
    state = {
        searchText: '',
        selectedRowKeys: [],
        showDelete: false
    };

    onSelectChange = selectedRowKeys => {
        console.log('selectedRowKeys changed: ', selectedRowKeys);
        this.setState({ selectedRowKeys });
        this.props.selectCheckBox(selectedRowKeys)
    };

    getColumnSearchProps = (dataIndex, field) => {
        if(['file', 'date'].includes(field.dataType)) return {}
        return (
            {
                filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                    <div style={{padding: 8}}>
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            placeholder={field.name ? `Search ${field.name}` : "Search"}
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
                            style={{width: 188, marginBottom: 8, display: 'block'}}
                        />
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm)}
                            icon="search"
                            size="small"
                            style={{width: 90, marginRight: 8}}
                        >
                            Search
                        </Button>
                        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                            Reset
                        </Button>
                    </div>
                ),
                filterIcon: filtered => (
                    <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
                ),
                onFilter: (value, record) =>
                        record['fields'][dataIndex] && record['fields'][dataIndex].value && record['fields'][dataIndex].value
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase()),
                onFilterDropdownVisibleChange: visible => {
                    if (visible) {
                        this.searchInput.select()
                    }
                },
            }
        )
    }

    handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({searchText: selectedKeys[0]});
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };


    render() {
        let {data, mRecords, mDataset, paginationSize, users, records} = this.props;
        // if (!mRecords) {
        //     return <Row type={'flex'} align={'middle'} justify={'center'} style={{minHeight:'70vh'}}><Spin size={"large"}/></Row>
        // }
        // console.log('record', record)

        if (mRecords && mRecords.length > 0) {
            let columns = [];
            let array = [];
            let con = 0, width = null;
            let field = data && data.attributes && data.attributes.fields
            mRecords.map(item => {
                let fields = [];
                if (mDataset && mDataset.attributes && mDataset.attributes && Array.isArray(mDataset.attributes.sections)) {
                    // console.log("mDataset.attributes", mDataset.attributes);

                    field && mDataset.attributes.sections.map(section => {
                        // console.log("section",section);
                        // let section_layout = section.section_layout;
                        let section_layout = section.section_layout.sort((a, b) => a.y - b.y);
                        let section_name = section.section_name;
                        let section_fields = [];
                        section_layout && section_layout.map(sl => {
                            let val = sl.i;
                            if(val && field[val]){
                                let dataField = {
                                    name: field[val].name,
                                    id: val,
                                    dataType: field[val].dataType,
                                    alias_help_text: field[val].alias_help_text,
                                    helpText: field[val].helpText,
                                    alias_name: field[val].alias_name,
                                    options: field[val].options,
                                    mandatory: field[val].mandatory,
                                }

                                section_fields.push({
                                    dataIndex: dataField.name,
                                    title: dataField.alias_name || dataField.name,
                                    key: dataField.id,
                                    // dataIndex: item,
                                    width: 200,
                                    render: (text, list) => {
                                        let _field = val && list.fields && Array.isArray(list.fields) && list.fields.length > 0 && list.fields.find(f => f && f.id === val);
                                        let value = _field && _field.value;

                                        // if (dataField.dataType === "date") {
                                        //     console.log('value', value )
                                        //     value = value ? moment(value, 'DD/MM/YYYY') : null;
                                        // }

                                        if(Array.isArray(value)) {
                                            value = value.toString();
                                        }

                                        if (dataField.dataType === "file" && value) {
                                            return <Icon className='file' type='file' />                                                
                                        } else if(dataField.dataType === 'people' && value) {
                                            const user = users.find(u => u.id === value)
                                            return user ? user.attributes.name : value                     
                                        } else if(dataField.dataType === 'dataset' && value) {
                                            const searchableField = field[val] && field[val].searchableField
                                            const recordValue = searchableField && records && records[value] && records[value].attributes.fields 
                                                                && records[value].attributes.fields[searchableField]
                                                                && records[value].attributes.fields[searchableField].value.row_id
                                            return recordValue ? recordValue : value
                                        } else {
                                            return value                                            
                                        }

                                    },
                                    ...this.getColumnSearchProps(fields.length, dataField),
                                });

                                if(item.attributes.fields && item.attributes.fields.hasOwnProperty(val)) {
                                    fields.push({...dataField, value: item.attributes.fields[val].value.row_id});
                                } else {
                                    fields.push({...dataField, value: null});
                                }
                            }


                        });

                        if (mDataset.attributes.sections.length > columns.length) {
                            columns.push({
                                title: <span style={{fontWeight:600}}>{section_name || "Untitled section"}</span>,
                                key: String(section.id),
                                children: section_fields,
                                fixed:true,
                                ellipsis: true,
                                // width:200,
                                // textWrap: 'word-break'
                            });
                        }
                    });
                }

                // if(item.attributes.fields && Object.keys(item.attributes.fields).length > 4){
                //     fixedName = 'left';
                // }

                array.push({
                    id: item.id,
                    key:item.id,
                    name: item.attributes.name,
                    description:item.attributes.description,
                    fields: fields,
                })
            });

            columns = columns.filter(c => c && c.children && c.children.length > 0);

            // let columns = [
            //     // {
            //     //     title: 'Name',
            //     //     key:'name',
            //     //     width: '200px',
            //     //     fixed: fixedName,
            //     //     render: (text, list) => {
            //     //         return <label>{list.name}</label>
            //     //     },
            //     //     ...this.getColumnSearchProps('name'),
            //     // },
            //     // {
            //     //     title: 'Description',
            //     //     key:'description',
            //     //     width: '200px',
            //     //     render: (text, list) => {
            //     //         return <label>{list.description}</label>
            //     //     },
            //     //     ...this.getColumnSearchProps('description'),
            //     // },
            // ];
            // console.log(array)
            // let con = 0, width = null;
            // array.map(item => {
            //     if(item.fields && con === 0){
            //         Object.keys(item.fields).map((val, index) => {
            //             let field = item.fields && item.fields[val];
            //             let dataType = field && field.dataType;
            //             width = item.fields && item.fields.length;
            //             columns.push({
            //                 title: field.name,
            //                 key: field.name,
            //                //dataIndex: item,
            //                 width: '200px',
            //                 render: (text, list) => {
            //                     let value = list.fields && list.fields[val] && list.fields[val].value;
            //                     if (dataType === "date") {
            //                         value = value ? moment(value).format('DD/MM/YYYY') : null;
            //                     }

            //                     if(Array.isArray(value)) {
            //                         value = value.toString();
            //                     }

            //                     if (dataType === "file" && value) {
            //                         return (
            //                             <label>
            //                                 <Icon className='file' type='file' />
            //                             </label>
            //                         )
            //                     } else {
            //                         return <label>{value}</label>
            //                     }

            //                 },
            //                 ...this.getColumnSearchProps(index)
            //             })
            //         });
            //         con = 1;
            //     }
            // })


            const { selectedRowKeys } = this.state;
            const rowSelection = {
                selectedRowKeys,
                columnWidth: 50,
                onChange: this.onSelectChange,
                hideDefaultSelections: true,
            };

            return (
                <div className='fw fixed' style={{overflow:'auto'}}>
                    <Table 
                        // scroll={{ x: (width * 100)+ 50, y: '100%' }}
                        rowSelection={rowSelection} 
                        bordered 
                        style={{marginTop: '30px'}} 
                        columns={columns} 
                        dataSource={array}
                        pagination={{pageSize: paginationSize}} 
                        tableLayou='fixed'
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: event => {this.props.select(record)}, // click row
                                // onDoubleClick: event => {}, // double click row
                                // onContextMenu: event => {}, // right button click row
                                // onMouseEnter: event => {}, // mouse enter row
                                // onMouseLeave: event => {}, // mouse leave row
                            };
                        }}
                    />
                </div>
            )
        } else {
            return <Empty description='No record found'/>
        }


    }
}

const mapStateToProps = (state, ownProps) => {
    let { dataset_id } = ownProps;
    let datasets = state.dataset && state.dataset.dataset && state.dataset.dataset.data;
    let mDataset = datasets && datasets.find(d => d && d.id === dataset_id);

    let datasetRecord = state.record[dataset_id];
    let list = datasetRecord && datasetRecord.list;
    let mRecords = list && list.map(id => state.record.records[id]);
    mRecords = mRecords && mRecords.filter(r => r);

    // console.log("mRecords", dataset_id, mRecords);

    return {
        datasets: datasets,
        mRecords: mRecords,
        mDataset: mDataset,
        users: state && state.users && state.users.data && state.users.data.data,
        records: state && state.record && state.record.records
    };
}

const mapDispatchToProps = (dispatch) => {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(EntriesList)