import React from 'react';

import {
    Row,
    Button,
    // Input,
    // Col,
    Modal
} from 'antd';
import { showNotification } from "../../utils/commonFunctions";
import FormFields from '../form/formFields'
import { connect } from "react-redux";

// const {TextArea} = Input;
// let inputData = {};
// let count = 0;

class addRecord extends React.Component {
    // setup state
    state = {
        activeDataset: null,
        loading: false,
        inputData: {},
        count: 0,
        mandatoryField: [],
        showErrorAlert: false,
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(!this.props.record.loading && this.state.loading){
    //      console.log('11111')
    //         this.setState({loading: false})
    //         this.props.onCloseModel(false)
    //     }
    // }

    componentDidUpdate(prevProps) {
        let { data, fetchAttachments } = this.props;
        if ((!prevProps.data && data && data.id) || (prevProps.data && data && data.id && prevProps.data.id !== data.id)) {
            this.setState({ activeDataset: data });
            // fetchAttachments && fetchAttachments({ model_id: data.id });
        }
    }

    createRecord = () => {
        let { inputData, count } = this.state;
        inputData.name = "demo";
        // this.props.create(inputData);

        if (this.state.mandatoryField.length > 0) {
            this.toggleErrorAlert(true);
            showNotification("error", "Please fill all mandatory field");
        } else {
            this.toggleErrorAlert(false);
            this.props.create(inputData);
            count++;
            this.setState({
                inputData: {},
                count: count,
            });
        }
    }

    close = () => {
        let { count } = this.state;
        // inputData = {
        //     // name: null,
        //     // description: null,
        //     members: []
        // };
        count = count - 1;
        this.setState({
            inputData: { members: [] },
            count: count,
            showErrorAlert: false,
        });

        this.props.onCloseModel()
    };

    // setFieldValue = (info) => {
    //     inputData.fields = { ...inputData.fields, ...info.fields };
    // };

    setFieldValue = (info) => {
        let { inputData } = this.state;
        inputData = JSON.parse(JSON.stringify(inputData));
        inputData.fields = Object.assign({}, inputData.fields, info.fields);

        this.setState({
            inputData: inputData,
            showErrorAlert: false,
        });
    };

    toggleErrorAlert = (flag) => {
        this.setState({showErrorAlert: flag})
    }

    updateMandatoryFields = (fields) => {
        this.setState({mandatoryField : fields})
    }

    render() {
        let { inputData, count, mandatoryField, showErrorAlert } = this.state;
        // let {loading} = this.state
        let { attachments } = this.props;
        let mAttachment = [];
        // if (data && data.id && attachments && Array.isArray(attachments[data.id])) {
        //     mAttachment = attachments[data.id].map(id => attachments[id]);
        // }

        return  <Modal
                    visible={this.props.visible}
                    footer={null}
                    key={count}
                    width={'60%'}
                    bodyStyle={{ height: '600px' }}
                    closable={false}
        >
            <Row type='flex' justify='space-between' key={count}>
                <span className={'text-font-fp'}>Add Record</span>
                <Button onClick={() => this.close()} shape="circle" icon="close" style={{ color: '#44566C', backgroundColor: '#F8FAFB', fontSize: '16px', border: 0 }} />
            </Row>
            {/*<Col className='mt-20 ml-10'>*/}
            {/*    <label>Record name <sup style={{fontSize:12, color: 'red', top: 0}}> *</sup></label>*/}
            {/*    <Input*/}
            {/*        onChange={(e)=>inputData.name = e.target.value}*/}
            {/*        className='custom-input mt-5'*/}
            {/*        size = 'large'*/}

            {/*    />*/}
            {/*</Col>*/}
            {/*<Col className='mt-20 ml-10'>*/}
            {/*    <span style={{color: '#44566C'}}>Descriptions</span>*/}
            {/*    <TextArea*/}
            {/*        className='custom-input'*/}
            {/*        onChange={(e)=>inputData.description = e.target.value}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            <Row className='mt-20' style={{overflow:'auto', height:'469px'}}>
                {/* <FormFields
                    sections={this.props.dataSet.attributes.sections}
                    fields={this.props.objectFields}
                    activeDataset={this.props.dataSet}
                    updateRecordFields={this.setFieldValue}
                /> */}
                <FormFields
                    sections={this.props.dataSet.attributes.sections}
                    fields={this.props.objectFields}
                    activeDataset={this.props.dataSet}
                    inputData={inputData}
                    updateRecordFields={this.setFieldValue}
                    attachments={mAttachment}
                    deleteAttachment = {this.props.deleteAttachment}
                    mandatoryField={mandatoryField}
                    showErrorAlert={showErrorAlert}
                    updateMandatoryFields={this.updateMandatoryFields}
                />
            </Row>
            <Row style={{ position: 'absolute', bottom: 0, right: 0, height: 50, width: '100%', background: '#F8FAFB' }}>
                <Row style={{ position: 'absolute', bottom: 10, right: 10 }}>
                    <Button onClick={() => this.close()} className='mr-10'>Cancel</Button>
                    <Button loading={this.state.loading} onClick={this.createRecord} type="primary">Add</Button>
                </Row>
            </Row>
        </Modal>
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        teams: state.teams,
        record: state.record,
        dataset: state.dataset
    }
}
export default connect(mapStateToProps)(addRecord)
