import React from 'react';
import {connect} from 'react-redux';
import {Row, Modal, Spin, Empty, Button, message} from 'antd';
import {
    createUserRequest,
    deleteUserRequest,
    fetchMoreUsersRequest,
    fetchUsersRequest,
    updateUserRequest
} from "./duck/UserActions";
import appUrl from '../constants/AppUrl';
import axios from 'axios';
import Header from './components/header'
import AddPeople from './components/addPeople'
import ViewPeople from './components/viewPeople'
import AddTeam from "./components/addTeam";
import ViewTeam from "./components/viewTeam";
import Body from './components/body'
import {
    createTeamRequest,
    deleteTeamRequest,
    updateTeamRequest,
    fetchMoreTeamsRequest,
    // fetchTeamsRequest
} from "../teams/duck/TeamsActions";

const {confirm} = Modal;

class UsersContainer extends React.PureComponent {
    state = {
        name: {},
        addPeopleModelView: false,
        viewPeopleModelView: false,
        addTeamModelView: false,
        viewTeamModelView: false,
        currentItem: null,
        selectedView: 'all',
        selectedTeam: [],
        selectedCheckbox: [],
        loadingMoreUsers: false,
    }

    componentDidUpdate(prevProps) {
        let {users} = this.props;

        if (
            users && users.data && Array.isArray(users.data.data) &&
            prevProps.users && prevProps.users.data && Array.isArray(prevProps.users.data.data) &&
            users.data.data.length > prevProps.users.data.data.length ||
            (
                users && users.data && Array.isArray(users.data.data) &&
                !(prevProps.users && prevProps.users.data)
            )
        ) {
          this.setState({
            loadingMoreUsers: false,
          }, () => {
              this.loadMoreUser();
          });
      }
    }

    // ui controller
    handleView = (selectedView, data) => {
        console.log('Selected: ', selectedView, data)
        let teams = this.props.teams.data && this.props.teams.data.data, currentTeam
        switch (selectedView) {
            case 'addPeople' :
                this.setState({addPeopleModelView: !this.state.addPeopleModelView})
                break;
            case 'viewPeople' :
                this.setState({viewPeopleModelView: !this.state.viewPeopleModelView})
                if (data) {
                    this.setState({currentItem: data})
                }
                break;
            case 'deletePeople':
                if (this.state.selectedCheckbox.length < 1) {
                    alert('please select any members')
                } else {
                    console.log(this.state.selectedCheckbox)
                }
                break;
            case 'Deactivate' :
                axios.put(appUrl.PEOPLE_URL + '/' + data, {data: {attributes: {active: false}}})
                    .then(res => {
                        message.success('User successfully deactivated')
                    }).catch(err => message.error('Somthing wrong happen', err))
                break;
            case 'Activate' :
                axios.put(appUrl.PEOPLE_URL + '/' + data, {data: {attributes: {active: true}}})
                    .then(res => {
                        message.success('User successfully activated')
                    }).catch(err => message.error('Somthing wrong happen', err))
                break;
            case 'Re-invite' :
                axios.post(appUrl.PEOPLE_URL + "/re-invite-users", {emails: [data]})
                    .then(res => {
                        message.success('Re invitation link successfully sent')
                    }).catch(err => message.error('Somthing wrong', err))
                break;
            case 'addTeam':
                this.setState({selectedView: 'all'});
                this.setState({addTeamModelView: !this.state.addTeamModelView})
                break;
            case 'viewTeam' :
                this.setState({viewTeamModelView: !this.state.viewTeamModelView})
                break;
            case 'deleteTeam' :
                confirm({
                    title: 'Do you want to delete this team?',
                    content: '',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                        this.deleteTeam(data)
                        currentTeam = teams;
                        this.setState({selectedView: 'all', selectedTeam: currentTeam});
                    },
                    onCancel() {
                        console.log('Cancel');
                    }
                });

                break;
            case 'all':
                currentTeam = teams;
                this.setState({selectedView: 'all', selectedTeam: currentTeam});
                break;
            case 'checkbox':
                this.setState({selectedCheckbox: data})
                break;
            case 'selectedTeam':
                currentTeam = teams.filter(e => e.id === data);
                this.setState({selectedView: data, selectedTeam: currentTeam});
                break;
            default: break;
        }
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (this.state.selectedView !== 'all' && nextProps.teams.data && this.state.selectedTeam && this.state.selectedTeam.length > 0 && nextProps.teams.data.data.length > 0) {
            nextProps.teams.data.data.map(team => {
                if (team.attributes.members.length !== this.state.selectedTeam[0].attributes.members.length) {
                    let data = this.state.selectedTeam[0]
                    data.attributes.members = team.attributes.members;
                    this.setState({selectedTeam: [data]})
                }
            })
        }
    }

    // api calling
    createUser = (data) => {
        createUserRequest(data)
        this.setState({addPeopleModelView: false})
    };

    deleteUser = (id) => {
        confirm({
            title: 'Do you want to delete this user?',
            content: '',
            okText: 'Yes',
            onOk() {
                deleteUserRequest(id);
            },
            onCancel() {
                console.log('Cancel');
            }
        });
    };
    updateUser = (id, data) => {
        updateUserRequest(id, data)
    };
    createTeam = (item) => {
        createTeamRequest(item)
        this.setState({addTeamModelView: false})
    }
    deleteTeam = (id) => {
        deleteTeamRequest(id);
    }
    updateTeam = (id, data) => {
        let teams = this.props.teams.data && this.props.teams.data.data, currentTeam
        updateTeamRequest(id, data)
        // currentTeam = teams.filter(e=>e.id === id);
        // console.log(currentTeam, data)
        // this.setState({selectedView: id, selectedTeam: currentTeam, viewTeamModelView: !this.state.viewTeamModelView });
        this.setState({viewTeamModelView: !this.state.viewTeamModelView, selectedView: 'all'})
        this.reloadList()
    }
    loadMoreUser = (page) => {
        let {data, meta} = this.props.users.data;
        let totalUsers = data.length;

        if (!this.state.loadingMoreUsers && totalUsers < meta.totalCount) {
            let params = {
                limit: page && page.current ? page.current*10 - totalUsers : 30,
                offset: totalUsers
            };

            this.setState({
                loadingMoreUsers: true,
            }, () => fetchMoreUsersRequest(params));
        }
    }
    loadMoreTeam = () => {
        let {data, meta} = this.props.teams.data;
        let totalTeams = data.length;
        if (meta.totalCount > totalTeams) {
            let params = {
                limit: 10,
                offset: totalTeams
            };
            // this.setState({loadingMore: true});
            fetchMoreTeamsRequest(params)
        }
    }

    reloadList = () => {
        fetchUsersRequest();
    }

    render() {
        const {users, teams} = this.props;

        let {addPeopleModelView, viewPeopleModelView, addTeamModelView, currentItem, selectedView, selectedTeam, viewTeamModelView} = this.state;
        let data = [];
        if (!users.data) {
            // there is no data, show loader till then
            if (users.loading) {
                return <Row type='flex' justify='center' align='middle' style={{minHeight: '80%'}}><Spin size='large'/></Row>
            }
            // if fetch request has not been raised yet, then return null
            return null;
        }

        // there is no data and there is some bug, show error and a button to raise request again.
        // *** The request will not retry until there is no internet connection. Thus, user have to manually raise request.
        if (users.error && !users.data) {
            return <div>
                <p>{users.error}</p>
                <Button type="primary" onClick={this.reloadList}>Retry</Button>
            </div>;
        }

        // if there is no data
        if (users.data.data.length === 0) {
            if (addPeopleModelView) {
                return <AddPeople visible={addPeopleModelView} createPeople={this.createUser} team={teams.data}
                                  onCloseModel={this.handleView}/>
            }
            return <Empty
                image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
                imageStyle={{
                    height: 60,
                }}
                description={
                    <span>
                        Users not found
                    </span>
                }>
                <Button type="primary" onClick={() => this.handleView('addPeople')}>Create Now</Button>
            </Empty>
        }

        if (selectedView === 'all') {
            data = users.data.data.map(user => {

                let attributes = user.attributes;
                return {
                    id: user.id,
                    name: attributes.name || '-',
                    email: attributes.email || null,
                    designation: attributes.designation || null,
                    role_id: attributes.role_id || null,
                    phone: attributes.phone || null,
                    active: attributes.active,
                    teams: attributes.teams && attributes.teams.map(obj => obj.name).toString().replace(",", ", "),
                    verified: attributes.verified,
                    picture: attributes.picture
                }
            });
        } else {
            data = selectedTeam[0].attributes.members.length > 0 ? selectedTeam[0].attributes.members.map(user => {
                return {
                    id: user._id,
                    name: user.name || '-',
                    email: user.email || null,
                    designation: user.designation || null,
                    phone: user.phone || null,
                    role_id: user.role_id || null,
                    active: user.active,
                    teams: user.teams || null,
                    verified: user.verified,
                    picture: user.picture
                }
            }) : []
        }

        return (
            <Row>
                <Header selectedTeam={selectedTeam} loadMoreTeam={this.loadMoreTeam} selected={selectedView} handleView={this.handleView}
                        team={teams.data}/>
                <Body count={users.data && users.data.meta.totalCount} loadmore={this.loadMoreUser} selectedTeam={selectedTeam} list={data} onClick={this.handleView}
                      onDelete={this.deleteUser} selected={selectedView}/>
                <AddPeople visible={addPeopleModelView} createPeople={this.createUser} team={teams.data}
                           onCloseModel={this.handleView}/>
                <ViewPeople visible={viewPeopleModelView} onDelete={this.deleteUser} onUpdate={this.updateUser}
                            item={currentItem} onCloseModel={this.handleView} selected={selectedView}/>
                <AddTeam visible={addTeamModelView} members={users.data && users.data.data} createTeam={this.createTeam}
                         onCloseModel={this.handleView}/>
                <ViewTeam visible={viewTeamModelView} currentTeam={selectedTeam} members={users.data && users.data.data}
                          updateTeam={this.updateTeam} onCloseModel={this.handleView}/>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.users,
        teams: state.teams
    }
}

export default connect(mapStateToProps)(UsersContainer);

