import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import { Row } from 'antd';

class DashboardContainer extends Component {

    render() {
        console.log('isUserAuthenticated')
        return (
            <Row className='app-content-area' style={{background:'#f2f2f2'}}>

            </Row>
        );
    }
}

DashboardContainer.propTypes = {};

export default DashboardContainer;
