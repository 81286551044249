import React, { Component } from 'react'
import { Row, Icon, Divider, Input, Popconfirm, Tooltip } from 'antd'
import { useDrop } from "react-dnd";
import { showNotification } from '../../utils/commonFunctions'
import GridLayoutFormBuilder from './gridLayoutFormBuilder';

const title = { fontSize: '20px', lineHeight: '23px', color: '#44566C' }
const desc = { fontSize: '12px', lineHeight: '22px', color: '#8798A8' }

export default class Section extends Component {

    state = {
        activeSectionId: null
    }


    confirm = (id) => {
        this.deleteSection(id)
    }

    deleteSection = (id) => {
        let data = [...this.props.sections]
        if (data.length === 1) {
            showNotification('warning', 'There should be minimum one section in the form')
            return false
        }
        let fields = Object.assign({}, this.props.allFields)
        if (!this.props.isProject) {
            for (let key in fields) {
                if (fields.hasOwnProperty(key)) {
                    if (fields[key].section_id === id) {
                        this.deleteField(key, id)
                    }
                }
            }
        }

        data = data.filter((sec) => sec.id !== id);
        this.props.updateSections(data)
    }

    handleSectionName = (e, id) => {
        this.props.sectionName(e.target.value, id)
    }

    handleSectionDescription = (e, id) => {
        this.props.sectionDescription(e.target.value, id)
    }

    onLayoutChange = (layout) => {
        this.props.onLayoutChange(this.props.section.id, layout)
    }

    addDynamicField = (field) => {
        let fields = Object.assign({}, this.props.allFields)
        let layout = JSON.parse(JSON.stringify(this.props.section.section_layout))
        let total = 0;
        layout.map(l => {
            total += l.y
        })
        if (!fields[field.id]) {
            layout.push({
                i: field.id,
                x: 0,
                y: total,
                w: 24,
                minW: 24,
                maxW: 24,
                h: field.dataType === 'textarea' ? 6 : 4,
                minH: field.dataType === 'textarea' ? 6 : 4,
                maxH: field.dataType === 'textarea' ? 6 : 4,
                // w:    field.dataType === 'number' ? 3 : 12,
                // h:    field.dataType === 'textarea' ? 6 : 4,
                // minH: field.dataType === 'textarea' ? 6 : 4,
                // maxH: field.dataType === 'textarea' ? 6 : 4,
                // minW: field.dataType === 'number' ? 3 : 6,
                // maxW: field.dataType === 'number' ? 24 : 24
            })
            fields[field.id] = field;
            this.props.updateFields(fields);
            this.onLayoutChange(layout);
        } else {
            showNotification('error', 'This field is already added, please create/use another field');
        }
    }

    deleteField = (id, sectionId) => {
        let fields = this.props.allFields //hack as it was urgent, need to improve the code
        // let fields = Object.assign({}, this.props.allFields)
        let layout = JSON.parse(JSON.stringify(this.props.section.section_layout))
        
        let found = fields[id]
        
        for (let key in fields) {
            if (fields.hasOwnProperty(key)) {
                fields[key].affects = fields[key].affects.filter(aff => aff !== id)
            }
            if(fields[key].affects.includes(sectionId)){
                fields[key].affects = fields[key].affects.filter(a => a !== sectionId) 
            }
        }
        // to remove field from wf execution if field is deleted
        if (!this.props.isProject) {
            this.deleteFromWorkflowExecution(id, sectionId)
        }

        this.ifDeletedRemoveFromDependency(found, fields)

        layout = layout.filter(f => f.i !== id);
        delete fields[id]

        this.props.updateFields(fields);
        this.onLayoutChange(layout);
    }

    ifDeletedRemoveFromDependency = (field, allFields) => {
        if (field) {
            if (field.affects.length > 0) {
                field.affects.map(id => {
                    let found = allFields[id]
                    if(!found){
                        let sections = [...this.props.sections]
                        let _idx = sections.findIndex(s => s.id === id)
                        if(_idx >= 0) found = sections[_idx]
                    }
                    if (found) {
                        if (found.dependency.draw_data.or && found.dependency.draw_data.or.length > 0) {
                            found.dependency.draw_data.or.map((or, index) => {
                                if (or.and && or.and.length > 0) {
                                    or.and.map((and, idx) => {
                                        if (and.active_control === field.id) {
                                            alert('Dependency found!')
                                            or.and.splice(idx, 1)
                                            found.dependency.logic.or[index].and.splice(idx, 1)
                                            if (or.and.length === 0) {
                                                found.dependency.draw_data.or.splice(index, 1)
                                                found.dependency.logic.or.splice(index, 1)
                                            }
                                            if (found.dependency.draw_data.or.length === 0) {
                                                found.dependency.draw_data = {}
                                                found.dependency.logic = {}
                                            }
                                        }
                                    })
                                }
                            })
                        }
                    }
                })
            }
        }
    }

    deleteFromWorkflowExecution = (fieldId, sectionId) => {
        let steps = Object.assign({}, this.props.steps)
        Object.keys(steps).map(s => {
            let step = steps[s]
            console.log('-----------------', step)
            if (step.fields[fieldId]) {
                delete step.fields[fieldId]
            }
            if (sectionId && step.sections[sectionId]) {
                delete step.sections[sectionId]
            }
            if (step.dependency.draw_data.or && step.dependency.draw_data.or.length > 0) {
                step.dependency.draw_data.or.map((or, index) => {
                    if (or.and && or.and.length > 0) {
                        or.and.map((and, idx) => {
                            if (and.active_control === fieldId) {
                                alert('Dependency found in execution!')
                                or.and.splice(idx, 1)
                                step.dependency.logic.or[index].and.splice(idx, 1)
                                if (or.and.length === 0) {
                                    step.dependency.draw_data.or.splice(index, 1)
                                    step.dependency.logic.or.splice(index, 1)
                                }
                                if (step.dependency.draw_data.or.length === 0) {
                                    step.dependency.draw_data = {}
                                    step.dependency.logic = {}
                                }
                            }
                        })
                    }
                })
            }
        })
    }

    // getFieldData = (e, type, section) => {
    //     e.preventDefault()
    //     let el = document.getElementsByClassName('app-content-block');
    //     let top = type === 'textarea' ? 43 : 83
    //     let pos = {
    //         top: (e.target.getBoundingClientRect().top + el[0].scrollTop) - top,
    //         left: e.clientX - (section.dataType === 'number' ? 450 : 600)
    //     }
    //     this.setState({ activeSectionId: section.id })
    //     this.props.togglePropertiesModal(pos, true, section)
    // }

    handleSectionCondition = (e, sectionId) => {
        e.preventDefault()
        let el  = document.getElementsByClassName('app-content-block');
        let top = 83
        let pos = {
            top  : ((e.target.getBoundingClientRect().top+el[0].scrollTop) - top) - 20,
            left : e.clientX - 600
        }
        this.props.toggleSectionConditionModal(pos, true, sectionId)
    }

    render() {
        let { section, addNewSection, copySection, allFields } = this.props
        let sectionFields = []
        for (let key in allFields) {
            if (allFields.hasOwnProperty(key)) {
                if (allFields[key].section_id === section.id) {
                    sectionFields.push(allFields[key])
                }
            }
        }
        console.log('fields', allFields)
        return (
            <React.Fragment>
                <Row type='flex' justify='space-between'>
                    <span>
                        <Input onBlur={e => this.handleSectionName(e, section.id)} defaultValue={section.section_name} style={title} className='editableInput' />
                        <br />
                        <Input onBlur={e => this.handleSectionDescription(e, section.id)} defaultValue={section.section_description} style={desc} className='editableInput' />
                    </span>
                    <span >
                        {/* <Tooltip title='Section settings'>
                            <Icon type="setting" className='f-16 ml-20'/>
                        </Tooltip>
                        <Tooltip title='Create a copy of this section'>
                            <Icon type="copy" onClick={e=>copySection(section.id)} className='f-16 ml-20'/>
                        </Tooltip> */}

                        <Tooltip title='Edit this section' onClick={e => this.handleSectionCondition(e, section)}>
                            <Icon type="edit" className='f-16 ml-20' />
                        </Tooltip>

                        <Popconfirm
                            title="Are you sure delete this section?"
                            onConfirm={e => this.confirm(section.id)}
                            // onCancel    = {cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Tooltip title='Delete this section'>
                                <Icon type="delete" className='f-16 ml-20' />
                            </Tooltip>
                        </Popconfirm>
                    </span>
                    {/* <span>
                        <Tooltip title='Section settings'>
                            <Icon type="setting" className='f-16 ml-20'/>
                        </Tooltip>
                        <Tooltip title='Create a copy of this section'>
                            <Icon type="copy" onClick={e=>copySection(section.id)} className='f-16 ml-20'/>
                        </Tooltip>
                    </span> */}
                </Row>
                {
                    sectionFields.length > 0 &&
                        <GridLayoutFormBuilder
                            layout={this.props.section.section_layout}
                            allFields={sectionFields}
                            onLayoutChange={this.onLayoutChange}
                            togglePropertiesModal={this.props.togglePropertiesModal}
                            deleteField={this.deleteField}
                        />

                }
                <Dropzone name={section.section_name} addDynamicField={this.addDynamicField} id={section.id} />
                <Divider className='divider' onClick={e => addNewSection(section.id)}>Add Section</Divider>
            </React.Fragment>
        )
    }
}

const Dropzone = (props) => {
    let _item = {
        section_id: props.id,
        alias_name: '',
        alias_help_text: '',
        computed_field: false,
        formula: null,
        mutable_entry: false,
        mandatory: false,
        read_only: false,
        conditions: false,
        dependency: {
            draw_data: {},
            logic: {}
        },
        affects: [],
        validation: null
    }
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: "field",

        drop: (item) => (
            props.addDynamicField({ ...item, ..._item })
        ),

        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        })
    });

    const isActive = canDrop && isOver;
    let backgroundColor = "#F8FAFB";
    if (isActive) {
        backgroundColor = "gold";
    } else if (canDrop) {
        backgroundColor = "aqua";
    }

    return (
        <div ref={drop} style={{ textAlign: 'center', padding: '10px', color: '#8798A8', background: backgroundColor, marginTop: '20px', border: '0.5px dashed #8697A8', borderRadius: '5px' }}>
            {isActive ? "Release to drop" : "+Drop any field here"}
        </div>
    );
};