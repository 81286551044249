import React from 'react';
import {Col, Radio, Row} from "antd";

const EvaluationSection = (props) => {
    return (
        <Row style={props.rowStyle}>
            <Col span={6}>Evaluate For</Col>
            <Col span={18} style={{textAlign:'right'}}>
                <Radio.Group onChange={e=>props.onChangeRadioBtn(e, 'evaluate')} value={props.value}>
                    <Radio value="All Records">All Records</Radio>
                    <Radio value="Selected Records">Selected Records</Radio>
                </Radio.Group>
            </Col>
        </Row>
    );
};

export default EvaluationSection;
