import TYPES from './MarketplaceAppActionTypes';

const initialState = {
  apps: {},
  createdApp: undefined,
  updatedApp: undefined,
}

const AppReducer = (state=initialState, action) => {
  switch(action.type) {
    case TYPES.CREATE_MARKETPLACE_APP:
      return Object.assign({}, state, {
        createdApp: undefined
      });
    case TYPES.CREATE_MARKETPLACE_APP_SUCCESS:
      return Object.assign({}, state, {
        createdApp: action.id
      });
    case TYPES.FETCH_MARKETPLACE_APP_SUCCESS:
      return Object.assign({}, state, {
        apps: Object.assign({}, state.apps, action.app),
      })
    case TYPES.UPDATE_MARLETPLACCE_APP:
      return Object.assign({}, state, {
        updatedApp: undefined,
      });
    case TYPES.UPDATE_MARLETPLACCE_APP_SUCCESS:
      return Object.assign({}, state, {
        updatedApp: action.id
      })
    case TYPES.UPDATE_MARLETPLACCE_APP_FAILED:
      return Object.assign({}, state, {
        updatedAppFail: action.id
      })
    case TYPES.FETCH_MARKETPLACE_APPS_SUCCESS:
      return Object.assign({}, state, {
        apps: Object.assign({}, state.apps, action.apps),
      })
    case TYPES.INSTALL_APP:
      return Object.assign({}, state, {
        installAppFailed: undefined,
        installAppSuccess: undefined,
      });
    case TYPES.INSTALL_APP_FAILED:
      return Object.assign({}, state, {
        installAppFailed: action.id
      });
    case TYPES.INSTALL_APP_SUCCESS:
      return Object.assign({}, state, {
        installAppSuccess: action.id
      });
    default:
      return state;
  }
}

export default AppReducer;
