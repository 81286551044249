import TYPES from './AttachmentsActionTypes';

const initialState = {
};

const AttachmentsReducer = (state=initialState, action) => {
  switch (action.type) {
    case TYPES.FETCH_ATTACHMENTS_SUCCESS:
      return Object.assign({}, state, action.attachments)
    case TYPES.CREATE_ATTACHMENT_SUCCESS:
      return Object.assign({}, state, {
        [action.attachment.model_id]: Array.from(new Set([...(state[action.attachment.model_id] || []), action.attachment._id])),
        [action.attachment._id]: action.attachment
      });
    case TYPES.DELETE_ATTACHMENT_SUCCESS:
      const newState = Object.assign({}, state);
      if (newState[action.attachment._id]) {
        const model_id = newState[action.attachment._id].model_id;
        newState[model_id] = newState[model_id].filter(id => id !== action.attachment._id);
        delete newState[action.attachment._id];
      }
      return newState;
    default:
      return state;
  }
}

export default AttachmentsReducer;
