import React, { Component } from 'react'
import {Select, Icon, Avatar, Row} from 'antd'
import CreateNewModal from '../../../utils/createNewModal'
import {getAvatarColor, getNameInitials} from '../../../utils/commonFunctions'

const s1 = {
    fontSize:'14px',
    color:'#8697A8', 
    fontWeight:'normal'
}
export default class WhoCanInitiateModal extends Component {

    state = {
        initiators : [],
        error : false
    }

    componentDidMount(){
        let initiators = []
        if(this.props.activeStep)
        {
            if(this.props.activeStep.assignment.people && this.props.activeStep.assignment.people === 'all'){
                initiators.push('all')
            }else{
                this.props.activeStep.assignment.people && this.props.activeStep.assignment.people.map(p=>{
                    initiators.push(p)
                })
                this.props.activeStep.assignment.teams && this.props.activeStep.assignment.teams.map(t=>{
                    initiators.push('team_'+t)
                })
            }
        }
        this.setState({initiators})
    }

    selectInitiators = (val) => {
        this.setState({initiators:val})
    }

    handleSubmit = () => {
        console.log(this.state.initiators)
        if(this.state.initiators.length === 0){
            this.setState({error: true})
        }else{
            this.setState({error: false})
        }
        this.props.handleSubmit(this.state.initiators, this.props.activeStep.id)
    }

    render() {
        let {users, showModal, toggleModal, teams} = this.props
        // console.log('Init', this.state.initiators)
        return (
            <CreateNewModal
                    title       = {
                        <div>
                            <span>{'Who can start this process?'}</span>
                            <br/>
                            <span style={s1}>{'Admins can view and edit all processes, items and reports'}</span>
                        </div>
                    } 
                    onSubmit    = {this.handleSubmit}
                    modalVisible= {showModal}
                    toggleModal = {toggleModal}
                    btnText     = 'Done'
                    closable    = {false}
                >
                    <Select
                        getPopupContainer={(e)=>e.parentNode}
                        size        = 'large'
                        className   = 'custom-select fw'
                        allowClear
                        mode        = 'multiple'
                        placeholder = 'Enter peope or team'
                        value       = {this.state.initiators}
                        onChange    = {this.selectInitiators}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.key && option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        
                        >
                        <Select.Option value='all'>
                            <Icon type='team' className='mr-10' />
                            Everyone
                        </Select.Option>
                        <Select.OptGroup label="Teams">
                            {
                                teams && teams.map(t=>{
                                    return <Select.Option key={t.id} value={'team_'+t.id}>
                                            <Avatar size="small" style={{backgroundColor: getAvatarColor(t.id)}}>
                                                {getNameInitials(t.name ? t.name : '')}
                                            </Avatar>
                                                <span className='ml-10'>{t.name}</span>
                                            </Select.Option>
                                })
                            }
                        </Select.OptGroup>
                        <Select.OptGroup label="People">
                            {
                                users && users.map(u=>{
                                    return <Select.Option key={u.id} value={u.id}>
                                        {
                                            u.attributes.picture ?
                                            <Avatar size={'small'} src={u.attributes.picture}/> :
                                                <Avatar size="small" style={{backgroundColor: getAvatarColor(u.id)}}>
                                                    {getNameInitials(u.attributes.name ? u.attributes.name : u.attributes.email)}
                                                </Avatar>
                                        }
                                                <span className='ml-10'>{u.attributes.name || u.attributes.email}</span>
                                            </Select.Option>
                                })
                            }
                        </Select.OptGroup>
                    </Select>
                    {this.state.error && <Row className='ml-10 mt-5' style={{color:'red',position:'absolute', fontSize:12}}>Please select the initiators of this process</Row>}
                </CreateNewModal>
        )
    }
}
