const ACTIONS = {
    
    FETCH_REQUEST       : 'FETCH_FIELDS_REQUEST',
    FETCH_SUCCESS       : 'FETCH_FIELDS_SUCCESS',
    FETCH_FAIL          : 'FETCH_FIELDS_FAIL',
    
    FETCH_ONE_REQUEST   : 'FETCH_ONE_FIELD_REQUEST',
    FETCH_ONE_SUCCESS   : 'FETCH_ONE_FIELD_SUCCESS',
    FETCH_ONE_FAIL      : 'FETCH_ONE_FIELD_FAIL',
    
    FETCH_MORE_REQUEST  : 'FETCH_MORE_FIELDS_REQUEST',
    FETCH_MORE_SUCCESS  : 'FETCH_MORE_FIELDS_SUCCESS',
    FETCH_MORE_FAIL     : 'FETCH_MORE_FIELDS_FAIL',
    
    CREATE_REQUEST      : 'CREATE_FIELD_REQUEST',
    CREATE_SUCCESS      : 'CREATE_FIELD_SUCCESS',
    CREATE_FAIL         : 'CREATE_FIELD_FAIL',
    
    UPDATE_REQUEST      : 'UPDATE_FIELD_REQUEST',
    UPDATE_SUCCESS      : 'UPDATE_FIELD_SUCCESS',
    UPDATE_FAIL         : 'UPDATE_FIELD_FAIL',

    DELETE_REQUEST      : 'DELETE_FIELD_REQUEST',
    DELETE_SUCCESS      : 'DELETE_FIELD_SUCCESS',
    DELETE_FAIL         : 'DELETE_FIELD_FAIL',
}

 export default ACTIONS;