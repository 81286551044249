import moment from 'moment'
import { notification } from 'antd'

const colors = {};
export const colorArr = [
  "#3ac48a",
  "#84dab6",
  "#fdcb7e",
  "#ffa16c",
  "#697789",
  "#9eabb9",
  "#c2ccd7",
  "#329aff",
  "#4ed6e7",
  "#CCE5FF",
  "#ff6378",
  "#FF9EAB",
  "#FFDBC8",
  "#FF3D57"
];

export function getAvatarColor(id, transparency) {
  if (!id) {
    return colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  if (!colors.hasOwnProperty(id)) {
    colors[id] = colorArr[Math.floor(Math.random() * colorArr.length)];
  }
  return transparency ? `${colors[id]}${transparency}` : colors[id];
}
export const getNameInitials = (name) => {
  if (!name) return;
  let initials = name[0] || '';
  var index = name.indexOf(" ");

  if (index < name.length && index > 1) {

    initials += (name[index + 1]);
  }
  return initials;
}

export const readableFileSize = (bytes, si) => {
  var thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
}

export const guidGenerator = () => {
  let timestamp = parseInt(moment.utc().valueOf());
  return timestamp + Math.floor(Math.random() * 1000 + 1);
}

export const showNotification = (type, msg, time = 4) => {
  notification[type]({
    message: msg,
    duration: time,
    placement: type === 'error' ? 'bottomLeft' : 'topRight'

  });
};

export const createObjectCopy = (obj) => {
  // return JSON.parse(JSON.stringify(obj))
  return Object.assign({}, obj)
}
export const tagColor = [
  '#697789',
  '#9eabb9',
  '#c2ccd7',
  '#329aff',
  '#4ed6e7',
  '#ff6378',
  '#3ac48a',
  '#fdcb7e',
  '#ffa16c',
]
