import React from 'react'
import { Row, Icon, Alert, Menu } from 'antd'
import { useDrag } from 'react-dnd'

export default function FieldTypeList(props) {
    let { fields, searchText } = props;
    fields = fields.filter(f => f.attributes.name.toLowerCase().includes(searchText.toLowerCase()))
    return (
        <Row className='fieldListColumn'>
            <Field key={'608bf711701d743468ad09b0'} name={'People'} type={'people'} icon={chooseIcon('people')} id={'608bf711701d743468ad09b0'} helpText={'This is a people list'} options={null} />
            {
                fields.length > 0
                    ?
                    fields.map(f => {
                        return <Field key={f.id} name={f.attributes.name} type={f.attributes.type} icon={chooseIcon(f.attributes.type)} id={f.id} helpText={f.attributes.help_text} options={f.attributes.options} />
                    })
                    :
                    <Alert message="No field found..." type="info" />
            }
        </Row>
    )
}

export function DatasetList(props) {
    let { datasets } = props;
    return (
        <Menu style={{ width: 250, borderRadius:5, border:0 }} mode="vertical">
            <Menu.SubMenu key="sub1" title="Select a dataset" >
                {
                    datasets.map(f => {
                        return (
                            <Menu.Item key={f.id} >
                                <Field key={f.id} name={f.attributes.name} type={'dataset'} id={f.id} helpText={'This is a dataset field'} options={[]} />
                            </Menu.Item>
                        )
                    })
                }
            </Menu.SubMenu>
        </Menu>
    )
}

const chooseIcon = (type) => {
    switch (type) {
        case 'text': return 'font-colors';
        case 'email': return 'mail';
        case 'number': return 'number';
        case 'telephone': return 'phone';
        case 'dropdown': return 'down-circle';
        case 'textarea': return 'align-left';
        case 'date': return 'calendar';
        case 'url': return 'global';
        case 'file': return 'cloud-upload';
        case 'star' : return 'star';
        case 'people' : return 'team';
        default: break;
    }

}

const Field = ({ name, type, icon, id, helpText, options }) => {
    const [{ isDragging }, drag] = useDrag({
        item: type === 'dataset' ? { name, type: 'field', id, dataType: type, helpText, options, columnsSelected:[], searchableField:null } : { name, type: 'field', id, dataType: type, helpText, options },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (item && dropResult) {
                // alert(`You dropped ${item.name} into Section:  ${dropResult.name}`)
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0.4 : 1

    return (
        <div ref={drag} style={{ opacity }} className='items'>
            <Icon type={icon} />
            <span>{name}</span>
        </div>
    )
}

// const Dataset = ({ name, type, icon, id, helpText, options }) => {
//     console.log('yes',options)
//     const [{ isDragging }, drag] = useDrag({
//         item: { name, type: 'field', id, dataType: "dropdown", helpText: `This is dataset field list`, options },
//         end: (item, monitor) => {
//             const dropResult = monitor.getDropResult()
//             if (item && dropResult) {
//                 // alert(`You dropped ${item.name} into Section:  ${dropResult.name}`)
//             }
//         },
//         collect: monitor => ({
//             isDragging: monitor.isDragging()
//         }),
//     })

//     const opacity = isDragging ? 0.4 : 1

//     return (
//         <div ref={drag} style={{ opacity }} className='items'>
//             <Icon type="database" theme="outlined" />
//             <span>{name}</span>
//         </div>
//     )
// }
