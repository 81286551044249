import React, { Component } from 'react'
import {Select} from 'antd'
import { connect } from 'react-redux'
import { fetchRecordRequest } from '../../../record/duck/RecordActions';

class People extends Component {

    state = {
        selectedPeople: (this.props.activeTask && this.props.activeTask.fields && this.props.activeTask.fields[this.props.field.id])
            ? this.props.activeTask.fields[this.props.field.id].value
            : null
    }

    // componentDidMount() {
    //     let { field } = this.props;
    //     if (field && field.id) {
    //         fetchRecordRequest({id: field.id});
    //     }
    // }

    componentDidUpdate(prevProps){
        let {activeTask, field} = this.props
        if(activeTask && activeTask.fields && activeTask.fields[field.id]){
            if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
                this.setState({selectedPeople: activeTask.fields[field.id].value})
            }
        }
    }

    handleDropdown = (options, fieldId) =>{
        this.setState({selectedPeople: options})
        this.props.addValue(fieldId, options)
    }


    render() {
        let {field, showError, showErrorAlert, people, currentUser} = this.props;
        let { selectedPeople } = this.state;
        // people = people.filter(p => p.id !== currentUser.id)

        return (
            <>
                <Select
                    getPopupContainer={(e)=>e.parentNode}
                    allowClear
                    mode        = {field.multi_entry ? 'multiple' : 'single'}
                    className   = {showErrorAlert && showError ? 'error custom-select fw' : 'custom-select fw'}
                    size        = 'large'
                    placeholder = {field.alias_help_text ? field.alias_help_text : field.helpText}
                    value       = {this.state.selectedPeople || []}
                    onChange    = {e=>this.handleDropdown(e, field.id)}
                >
                {
                        people && people.length > 0 && people.map(p=>{
                            return <Select.Option key={p.id} value={p.id}>
                                {p.attributes.name || p.attributes.email}
                            </Select.Option>
                        })
                    }
                </Select>
            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        people: state && state.users && state.users.data && state.users.data.data,
        currentUser: state && state.currentUser && state.currentUser.data && state.currentUser.data.data
    }
}

export default connect(mapStateToProps)(People);