import React, { Component } from 'react'
import { Row, Menu, Dropdown, Checkbox, Icon } from 'antd';
import {
    deletePageRequest,
    setActivePage
} from "./duck/PageActions";

export default class PageList extends Component {

    getMenu = (page) => {
        return(
            <Menu>
                <Menu.Item  onClick={e=>this.editPage(page)} key={page._id+'_edit'}>
                    <Icon type="edit" />
                    <span>Edit</span>
                </Menu.Item>
                <Menu.Item onClick={e=>this.deletePage(page._id)} key={page._id+'_delete'}>
                    <Icon type="delete" />
                    <span>Delete</span>
                </Menu.Item>
            </Menu>
        )
    }

    deletePage = (pageId) => {
        console.log('delete called', pageId)
        if(pageId){
            deletePageRequest(pageId)
        }
    }
    
    editPage = (page) => {
        this.props.onEdit(page)
    }

    selectPage = (pageData) => {
        setActivePage(pageData)
    }

    render() {
        return (
            <Row>
                {
                    this.props.list && this.props.list.length > 0 && this.props.list.map(page => {
                        return <Row key={page.id}  style={{cursor:'pointer',background:'white',padding:'10px 30px',borderRadius:'8px',marginBottom:'10px'}} type='flex' align='middle' justify='space-between'>
                            <Row type='flex' align='middle' onClick={e=>this.selectPage(page)}>
                                <Checkbox></Checkbox>
                                <Row style={{marginLeft:'15px',display:'inline-block'}}>
                                    <span style={{color:'#44566C'}}>{page.name}</span><br/>
                                    <span style={{color:'#8697A8'}}>{page.description}</span>
                                </Row>
                            </Row>
                            <span style={{color:'#8697A8',marginLeft:'40px'}}>
                                <Dropdown overlay={this.getMenu(page)} trigger={['hover']}>
                                    <Icon type="ellipsis" style={{fontSize:'40px'}} />
                                </Dropdown>
                            </span>
                        </Row>
                    })

                }
            </Row>
        )
    }
}
