import React from 'react';
import {Route} from 'react-router-dom';

import AdminContainer from '../admin/adminContainer';

const AuthRouters = (props) => {
    return (
        <React.Fragment>
            <Route path='/admin' exact component={AdminContainer} />
        </React.Fragment>
    );
};

export default AuthRouters;
