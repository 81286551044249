// import { put } from 'redux-saga/effects';
import ACTIONS from "./RecordActionTypes";
import {store} from '../../config/Store';

export function fetchRecordRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function fetchMoreRecordRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function createRecordRequest(userInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:userInfo})
}
export function updateRecordRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}
export function deleteRecordRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

