import React, { Component } from 'react'
import {connect} from 'react-redux';
import {Select, Avatar} from 'antd'
import {getAvatarColor, getNameInitials} from './commonFunctions'

class TeamSelect extends Component {

    selectTeam = (val) => {
        this.props.onChange(val)
    }

    render() {
        // console.log('teams', this.props.teams)
        return (
            <Select
                getPopupContainer={(e)=>e.parentNode}
                allowClear
                mode             = {this.props.mode}
                className        = 'custom-select fw'
                size             = 'large'
                placeholder      = {this.props.placeholder}
                optionFilterProp = "children"
                filterOption     = {(input, option) => option.props.children[1].props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                defaultValue     = {this.props.defaultValue}
                onChange         = {this.selectTeam}
            >
                {
                    this.props.teams.map(team => (
                         <Select.Option key={team.id} value={team.id}>
                            <Avatar size="small" style={{backgroundColor: getAvatarColor(team.id)}}>
                                {getNameInitials(team.name ? team.name : '')}
                            </Avatar>
                            <span className='ml-10'>{team.name}</span>
                        </Select.Option>
                    ))
                }

            </Select>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        teams : state.teams && state.teams.data && state.teams.data.data
    }
}

export default connect(mapStateToProps)(TeamSelect);
