import React from 'react';
import jwtDecode from "jwt-decode";
import PeopleList from './peopleList'


const Body = (props) => {
    let editable = props.selected === 'all';
    let token = localStorage.getItem('jwt')
    let list = props.list;
    if (token && editable) {
        let user = jwtDecode(token);
        list = list.filter(res => res.id !== user.user.id)
    }
    return (<React.Fragment>
        <PeopleList 
            loadmore={props.loadmore} 
            editable={editable} 
            onDelete={props.onDelete} 
            list={list} 
            onClick={props.onClick}
            count={props.count}
        />
    </React.Fragment>)
}

export default Body