import React from 'react';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {Row, Input, Spin, Tag, Menu, Icon, Col, Select, Empty, Card, Tooltip, Popconfirm} from 'antd';
// import AddNewButton from '../utils/addNewButton';
// import CreateNewModal from '../utils/createNewModal';
import {deleteProcessRequest} from '../process/duck/ProcessActions'
import moment from 'moment'

class ProcessListContainer extends React.Component {

    state = {
        // showModal   : false,
        // processName : '',
        // description : '',
        activeProcessId  : null,
        searchText  : '',
        sortBy      : [],
    }

    getMenu = (id) => {
        return(
            <Menu>
                <Menu.Item  onClick={e=>this.editProcess(id)} key={id+'_edit'}>
                    <Icon type="edit" />
                    <span>Edit</span>
                </Menu.Item>
                <Menu.Item onClick={e=>this.deleteProcess(id)} key={id+'_delete'}>
                    <Icon type="delete" />
                    <span>Delete</span>
                </Menu.Item>
            </Menu>
        )
    }

    bubbling = (e) => {
        e.stopPropagation()
    }

    deleteProcess = (e, processId) => {
        e.stopPropagation()
        if(processId){
            deleteProcessRequest(processId)
        }
    }
    
    editProcess = (processId) => {
        this.selectProcess(processId)
        console.log('edit called', processId)
    }

    selectProcess = (processId) => {
        this.setState({activeProcessId: processId})
        // return <Redirect to="'/app/process/creator/'+processData.id" push/>
    }

    sort = (val) => {
        this.setState({sortBy:val})
    }

    searchProcess = (e) => {
        this.setState({searchText:e.target.value})
    }
       
    // setRedirectToPage = () => {
    //     if(this.props.activePage.activePage == null){
    //         return <Redirect to='/app/pages' />
    //     }
    // }

    // handleSubmit = (processName = this.state.processName, description = this.state.description) =>{
    //     if(processName !== '' && description !== ''){
    //         let data = {
    //             name        : processName,
    //             description : description
    //         }
    //         createProcessRequest(data);
    //         this.setState({showModal:false, processName:'', description:''})
    //     }
    // }

    // handleInputChange = (e) => {
    //     this.setState({processName:e.target.value})
    // }

    // handleDescriptionChange = (e) => {
    //     this.setState({description:e.target.value})
    // }

    render(){
        if(this.state.activeProcessId){
            let url = `/app/process/creator/${this.state.activeProcessId}`
            return <Redirect to={url} push/>
        }
        let process  = this.props.process.data && (this.props.process.data.data && this.props.process.data.data.length > 0 ) ? this.props.process.data.data : [];
        if(this.state.searchText.trim() !== ''){
            process   = process && process.filter(f=>f.name.toLowerCase().includes(this.state.searchText.toLowerCase()))
        }
        if(this.state.sortBy === 'alphabetical'){
            let tg = [...process]
            process  = tg.sort((a, b) => a.name.localeCompare(b.name))
        }else if(this.state.sortBy === 'creation'){
            let tg = [...process]
            process  = tg.reverse()
        }
        
        return(
            <Row>
                {/* <Row type='flex' justify='end'>
                    <AddNewButton trigger={e=>this.toggleModal(true)}/>
                </Row> */}
                <Spin spinning={this.props.process.loading} size='large' style={{minHeight:'60vh'}}>
                    <Row gutter={16} type='flex' justify='end' style={{marginBottom:'40px'}}>
                        <Col span={4}>
                            <Select
                                getPopupContainer={(e)=>e.parentNode}
                                style       = {{boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)'}}
                                className   = 'fw custom-select white'
                                size        = 'large'
                                placeholder = 'Sort by'
                                defaultValue= {this.state.sortBy}
                                onChange    = {this.sort}
                                allowClear

                            >
                                <Select.Option value='alphabetical'>
                                    <Icon type="sort-ascending" className='mr-10' style={{fontSize:'18px'}}/>
                                    Alphabetical
                                </Select.Option>   
                                <Select.Option value='creation'>
                                    <Icon type="calendar" className='mr-10'/>
                                    Creation Date
                                </Select.Option>
                            </Select>
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="Search processes"
                                prefix={<Icon type="search" style={{fontSize:'20px', color: 'rgba(0,0,0,.25)' }} />}
                                size='large'
                                className='input-box searchbox'
                                onKeyUp = {this.searchProcess}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {
                            process && process.length > 0
                            ?
                                process.map(process => {
                                    return  <Card
                                                bordered    = {false}
                                                hoverable
                                                className   = {'custom-card'}
                                                key         = {process._id}
                                                onClick     = {e=>this.selectProcess(process._id)}
                                            >
                                                <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
                                                    <Row type='flex' justify={process.draft ? 'space-between' : 'end'} align='middle'>
                                                        {process.draft && <Tag color='#FF6378'>Draft</Tag>}
                                                        <Popconfirm
                                                            title="Are you sure delete this process?"
                                                            onCancel    ={this.bubbling}
                                                            onConfirm   = {e=>this.deleteProcess(e, process._id)}
                                                            okText      = "Yes"
                                                            cancelText  = "No"
                                                        >
                                                            <Tooltip title='Delete'>
                                                                <Icon className='delete' type='delete' onClick={this.bubbling} />
                                                            </Tooltip>
                                                        </Popconfirm>                            
                                                    </Row>
                                                    <Row type="flex" style={{flexGrow: 1}}>
                                                        <span style={{
                                                            fontSize: '24px',
                                                            lineHeight: '32px',
                                                            fontWeight: 'bold',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>{process.name}</span>
                                                    </Row>
                                                    <Row>
                                                        <span style={{fontSize: '12px'}}>Last updated {moment(process.modified_at).fromNow()}</span>
                                                    </Row>
                                                </div>
                                            </Card>
                                })
                            :
                                <Row style={{minHeight:'50vh'}} type='flex' align='middle' justify='center'>
                                    <Empty description={false} />
                                </Row>
                        }
                    </Row>
                </Spin>
                {/* <CreateNewModal 
                    title       = 'Enter Process Name' 
                    onSubmit    = {this.handleSubmit}
                    modalVisible= {this.state.showModal}
                    toggleModal = {this.toggleModal}
                    btnText     = 'Create'
                >
                    <Input 
                        onChange    = {this.handleInputChange} 
                        value       = {this.state.processName} 
                        size        = "large" 
                        className   = 'custom-input' 
                        placeholder = "Example: Registration, User Onboarding" 
                        allowClear
                    />
                    <Input.TextArea
                        className   = 'custom-input'
                        style       = {{marginTop:'20px'}}
                        placeholder = "Process description goes here"
                        autosize    = {{ minRows: 3, maxRows: 6 }}
                        onChange    = {this.handleDescriptionChange}
                    />
                </CreateNewModal> */}
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        process : state.process
    }
}

export default connect(mapStateToProps)(ProcessListContainer);