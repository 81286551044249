import React, { Component } from 'react'
import CreateNewModal from '../utils/createNewModal'
import {showNotification, tagColor} from '../utils/commonFunctions'
import {createTagRequest} from '../tags/duck/TagsActions'
import {Row, Col, Input, Popover, Typography} from 'antd'

export default class TagCreatorContainer extends Component {

    state = {
        showModal : false,
        tagName   : '',
        color     : '#f8fafb'
    }

    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    handleSubmit = (tagName = this.state.tagName, color = this.state.color.toLowerCase()) =>{
        let nameRegExp = /^[a-z0-9 &]+$/i;
        if(tagName.trim() === ''){
            showNotification('error', 'Tag name cannot be blank')
            return false
        }
        if(!nameRegExp.test(tagName.trim())){
            showNotification('error', 'Special characters not allowed')
            return false
        }
        if(color === '#f8fafb'){
            showNotification('error', 'Please select tag color')
            return false
        }
        if(tagName !== '' && color !== '#f8fafb'){
            createTagRequest({tag:tagName, color})
            this.setState({tagName:'', color : '#f8fafb'})
            showNotification('success','Tag created successfully')
            this.toggleModal(false)
        }
    }

    handleTagName = (e) =>{
        this.setState({tagName:e.target.value})
    }

    pickColor = (e) => {
        this.setState({color:e.target.getAttribute('value')})
    }

    content = (
        <div style={{width:'150px'}}>
            {
                tagColor.map(color=>{
                    return <div key={color} className='pointer' value={color} onClick={this.pickColor} style={{backgroundColor:color,width:'20px',height:'20px',marginRight:'10px',marginBottom:'10px',display:'inline-block'}}></div>
                })

            }
        </div>
    );

    render() {
        return (
            <>
                <a href="#" onClick={e=>this.toggleModal(true)}>Tag</a>
                <CreateNewModal 
                    title       = 'Create Tag' 
                    onSubmit    = {this.handleSubmit}
                    modalVisible= {this.state.showModal}
                    toggleModal = {this.toggleModal}
                    btnText     = 'Create'
                >
                    <Row gutter={16}>
                        <Col span={18}>
                            <label>Tag Name</label>
                            <Typography.Text type="danger" className='ml-5'>*</Typography.Text>
                            <Input size='large' onChange={this.handleTagName} value={this.state.tagName} maxLength={25} placeholder='Ex: Priority, Sprint' allowClear className='custom-input mt-10' />
                        </Col>
                        <Col span={6}>
                            <label>Tag Color</label>
                            <Typography.Text type="danger" className='ml-5'>*</Typography.Text>
                            <br/>
                            <Popover placement='bottom' content={this.content} trigger="click">
                                <div className='mt-10' style={{height:'40px',backgroundColor:'#f8fafb',padding:'6px',borderRadius:'4px'}}>
                                    <div className='fh' style={{background:this.state.color}}></div>
                                </div>
                            </Popover>
                        </Col>
                    </Row>
                </CreateNewModal>
            </>
        )
    }
}
