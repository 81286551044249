import React, { Component } from 'react'
import CreateNewModal from '../utils/createNewModal'
import FieldTypes from './fieldTypes'
import {showNotification} from '../utils/commonFunctions'
import {createFieldRequest} from '../fields/duck/FieldActions'

export default class FieldCreatorContainer extends Component {

    state = {
        showModal : false,
        fieldName   : '',
        helpText    : '',
        selectedType: [],
        options     : [],
        acceptedFileFormat : []
    }

    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    handleSubmit = (fieldName = this.state.fieldName, helpText = this.state.helpText, selectedType = this.state.selectedType) =>{
        // let nameRegExp = /^[a-z0-9 &]+$/i;
        if(fieldName.trim() === ''){
            showNotification('error', 'Field name cannot be blank')
            return false
        }
        // if(!nameRegExp.test(fieldName.trim())){
        //     showNotification('error', 'Special characters not allowed')
        //     return false
        // }
        
        if(selectedType.length  === 0){
            showNotification('error', 'Please select field data type')
            return false
        }
        if(fieldName !== '' && selectedType.length > 0){
            let data = {
                name        : fieldName,
                help_text   : helpText,
                type        : selectedType.toString().toLowerCase(),
                options     : selectedType.toString().toLowerCase() === 'file' ? this.state.acceptedFileFormat : this.state.options
                // options     : this.state.options
            }
            createFieldRequest(data);
            this.setState({fieldName:'', helpText:'', selectedType : [], options: [], acceptedFileFormat: []})
            showNotification('success','Field created successfully')
            this.toggleModal(false)
        }
    }

    handleName = (val) =>{
        this.setState({fieldName:val})
    }
    
    handleHelpText = (val) =>{
        this.setState({helpText:val})
    }

    selectType = (type) => {
        this.setState({selectedType:type})
    }

    handleOptions = (val) => {
        this.setState({options:val})
    }

    handleFileFormat = (val) => {
        this.setState({acceptedFileFormat: val})
    }

    render() {
        return (
            <>
                <a href="#" onClick={e=>this.toggleModal(true)}>Field</a>
                <CreateNewModal 
                    title       = 'Create Custom Field' 
                    onSubmit    = {this.handleSubmit}
                    modalVisible= {this.state.showModal}
                    toggleModal = {this.toggleModal}
                    btnText     = 'Create'
                >
                    <FieldTypes 
                        handleName      = {this.handleName}
                        handleHelpText  = {this.handleHelpText}
                        handleOptions   = {this.handleOptions}
                        selectType      = {this.selectType}
                        fieldName       = {this.state.fieldName}
                        helpText        = {this.state.helpText}
                        selectedType    = {this.state.selectedType}
                        options         = {this.state.options}
                        acceptedFileFormat  = {this.state.acceptedFileFormat}
                        handleFileFormat    = {this.handleFileFormat}
                    />
                </CreateNewModal>
            </>
        )
    }
}
