import appUrl from '../constants/AppUrl';
import openSocket from 'socket.io-client';
const socket = openSocket(appUrl.SOCKET_URL);

const initializeSocket = function(){
    socket.on('connect', function () {
        socket
            .emit('authenticate', {token: localStorage.getItem('jwt')}) //send the jwt
            .on('authenticated', function () {
                console.log("Socket authenticated");
            });
    });
};

export {socket, initializeSocket};