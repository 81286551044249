import ACTIONS from './PageActionTypes';

const initialState = {data:null,loading:false,error:null}

const getMeta = (meta, noOfItems, updateTotalCount) => {
    let {count, totalCount} = meta;
    count += noOfItems;
    totalCount += updateTotalCount ? noOfItems : 0;
    let newMeta = {...meta, count, totalCount};
    return newMeta
}

const createData = (list, item) => {
    if(!list.find(data=>data.id===item.id)) {
        return [item, ...list]
    }
    return list;
}

const updateData = (list, newData) => {
    return list.map(data=>data.id === newData.id ? newData : data)
}

const filterData = (list, id) => {
    return list.filter(data=>data.id !== id);
}


const pagesReducer = (state=initialState, action) => {
    switch(action.type){

        case ACTIONS.FETCH_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.FETCH_SUCCESS: return {...state,data:action.payload,loading:false};
        case ACTIONS.FETCH_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.FETCH_MORE_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.FETCH_MORE_SUCCESS: return {...state,data:{data:[...state.data.data,...action.payload.data],meta:getMeta(state.data.meta, action.payload.meta.count)},loading:false};
        case ACTIONS.FETCH_MORE_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.CREATE_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.CREATE_SUCCESS: return {...state,data:{data: createData( state.data.data, action.payload.data),meta:getMeta(state.data.meta, 1,true)},loading:false};
        case ACTIONS.CREATE_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.UPDATE_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.UPDATE_SUCCESS: return {...state,data:{data: updateData(state.data.data, action.payload.data),meta:state.data.meta},loading:false};
        case ACTIONS.UPDATE_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.DELETE_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.DELETE_SUCCESS: return {...state,data:{data: filterData(state.data.data, action.payload.data.id),meta:getMeta(state.data.meta,-1,true)},loading:false};
        case ACTIONS.DELETE_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.SET_ACTIVE_PAGE: return {...state, activePage:action.pageData, loading:false};

        default:
            return state;
    }
}

export default pagesReducer;