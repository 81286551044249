import React, { Component } from 'react'
import { Row } from 'antd'
import FormSections from './formSections';
import jsonLogic from "json-logic-js";

export default class FormFields extends Component {

    state = {
        displayField    : [],
        displaySection    : [],
    }

    componentDidMount(){
        this.showDependentFields();
    }

    componentDidUpdate(prevProps) {
        const { inputData } = this.props;

        // let flag = prevProps.inputData && Object.keys(prevProps.inputData).length === 0 ? true : false;
        if(inputData) {
            if(inputData.fields && inputData.fields !== prevProps.inputData.fields){
                // flag = false
                this.showDependentFields()
            }
        }
    }

    showDependentFields = () => {
        let { fields, sections, activeDataset, inputData } = this.props
        let activeTaskFields = inputData && inputData.fields || activeDataset && activeDataset.fields;
        // let stepFields = null
        let _section = [], _field = [], _mandatoryField = [], data = {}, _val;
        
        let keysArray = Object.keys(fields);

        keysArray.forEach(fieldId => {
            // _val = activeTaskFields && activeTaskFields[fieldId] ? activeTaskFields[fieldId].value.row_id : null
            if (activeTaskFields && activeTaskFields[fieldId]) {
                if(['6080803e1334ca5bc8603974','608082c51334ca5bc860e76c','6080805c1334ca5bc8604f34','6080836d1334ca5bc860e76f','608082781334ca5bc860e76b'].includes(fieldId)){
                    console.log('fields---->',fieldId ,activeTaskFields[fieldId])
                }
                if (activeTaskFields[fieldId].value && Array.isArray(activeTaskFields[fieldId].value)) {
                    _val = activeTaskFields[fieldId].value
                }
                else if (typeof activeTaskFields[fieldId].value === "object") {
                    _val = activeTaskFields[fieldId].value && activeTaskFields[fieldId].value.row_id || null;
                } else {
                    _val = activeTaskFields[fieldId].value || null;
                }
            } else {
                _val = null;
            }
            data = Object.assign({...data}, {[fieldId]: _val});
        })

        sections && sections.forEach(section => {
            if (section && section.conditions && section.dependency && section.dependency.logic && Object.keys(section.dependency.logic).length > 0) {
                let res = jsonLogic.apply(section.dependency.logic, data);
                // console.log('section - 1619076226714', section.id, res, data, 'reim-field-id: 608082db1334ca5bc860e76d')
                if(res) {
                    _section.push(section.id);
                }
            }else {
                _section.push(section.id);
            }
        });

        keysArray.forEach(fieldId => {
            if(fields[fieldId].mandatory && _section.includes(fields[fieldId].section_id)) {
                if(activeTaskFields && activeTaskFields[fieldId]) {
                    if (typeof activeTaskFields[fieldId].value === "object") {
                        if(!activeTaskFields[fieldId].value.row_id) {
                            _mandatoryField.push(fieldId);
                        }
                    } else if(!activeTaskFields[fieldId].value ) {
                        _mandatoryField.push(fieldId);
                    }
                }else{
                    _mandatoryField.push(fieldId);
                }
            }

            if(fields[fieldId].conditions && fields[fieldId].dependency && fields[fieldId].dependency.logic && Object.keys(fields[fieldId].dependency.logic).length > 0){
                // console.log("data", data);
                let res = jsonLogic.apply(fields[fieldId].dependency.logic, data);
                if(res) {
                    _field.push(fieldId);
                } else if(_mandatoryField.includes(fieldId)) {
                    _mandatoryField = _mandatoryField.filter(f=>f !== fieldId)
                }
            }else{
                _field.push(fieldId);
            }
        });

        // keysArray.forEach(fieldId => {
        //     if(fields[fieldId].mandatory && _section.includes(fields[fieldId].section_id)) {
        //         if((stepFields && stepFields[fieldId].show && stepFields && stepFields[fieldId].editable) || !isProcess){
        //             if(activeTask && activeTaskFields && activeTaskFields[fieldId]){
        //                 if(!activeTaskFields[fieldId].value.row_id){
        //                     _mandatoryField.push(fieldId)
        //                 }
        //             }else{
        //                 _mandatoryField.push(fieldId)
        //             }
        //         }
        //     }
        //     if(fields[fieldId].conditions && fields[fieldId].dependency && fields[fieldId].dependency.logic && Object.keys(fields[fieldId].dependency.logic).length > 0){
        //         let res = jsonLogic.apply(fields[fieldId].dependency.logic, data);
        //         if(res){
        //             _field.push(fieldId);
        //         }
        //         else if(_mandatoryField.includes(fieldId)){
        //             _mandatoryField = _mandatoryField.filter(f=>f !== fieldId)
        //         }
        //     }else{
        //         _field.push(fieldId);
        //     }
        // })


        // console.log("_mandatoryField", _mandatoryField);
        // console.log("displayField", displayField);

        this.props.updateMandatoryFields && this.props.updateMandatoryFields(_mandatoryField)
        this.setState({
            displayField: _field,
            displaySection: _section,
        });
    }

    render() {
        let { fields, sections } = this.props;
        let { displayField, displaySection } = this.state;
        // console.log("fields", fields);
        // console.log("sections", sections);

        return (
            <Row>
                {
                    sections && sections.map(section => {
                        if (displaySection.includes(section.id)) {

                        let sectionLayout = section.section_layout.sort((a, b) => a.y - b.y);
                        // let sectionFields = fields.filter(f=>f.section_id === section.id)
                        let sectionFields = []
                        // Object.keys(fields).map(f => {
                        //     if (fields[f].section_id === section.id) {
                        //         sectionFields.push(fields[f])
                        //     }
                        // })

                        sectionLayout.forEach(s => {
                            if(displayField.includes(s.i)){
                                sectionFields.push(fields[s.i])
                            }
                        });

                        return (
                            <FormSections
                                key={section.id}
                                section={section}
                                sectionFields={sectionFields}
                                activeTask={this.props.activeDataset}
                                updateTask={this.props.updateRecordFields}
                                attachments={this.props.attachments}
                                deleteAttachment={this.props.deleteAttachment}
                                mandatoryField  = {this.props.mandatoryField}
                                showErrorAlert  = {this.props.showErrorAlert}
                                sectionIds={sections.map(s => s.id.toString())}
                                fetchAttachments={this.props.fetchAttachments}
                            />
                        )
                        }
                    })
                }
            </Row>
        )
    }
}