import React from 'react'
import {Avatar, Menu, Tag, Tooltip} from 'antd'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux';
import {getAvatarColor, getNameInitials} from '../utils/commonFunctions'
import jwtDecode from "jwt-decode";

let profile = {
    name: "Admin",
    id :  null
}
export class  MyProfileDropdown  extends React.Component {
     logout = () => {
        localStorage.clear();
        this.props.logout();
    }

     menu = (
        <Menu>
			<Menu.Item>
				<Link to={'/login'}  rel="noopener noreferrer" onClick={this.logout}>
					Logout
				</Link>
			</Menu.Item>
        </Menu>
    );
    render(){
        let {currentUser, organisations} = this.props;
        if(currentUser){
            profile.name = currentUser.attributes.name
            profile.id = currentUser.id
        }
        let current_org = null
        let token       = localStorage.getItem('jwt')
        if(token) {
            let user        = jwtDecode(token);
            current_org     = organisations && organisations.find(org=> org.OrganisationId === user.user.org_id)
        }
        return(
            <span style={{marginLeft:'32px'}}>
                {
                    currentUser && currentUser.attributes && currentUser.attributes.picture
                    ?
                        <Avatar size={40} src={currentUser.attributes.picture} className='mr-10'/>
                    :
                        <Avatar size={40} className='mr-10' style={{background: getAvatarColor(profile.id),fontSize:'14px'}}>
                            {getNameInitials(profile.name)}
                        </Avatar>
                }
                <span>
                    <span style={{marginRight:'15px',color:'#44566C'}}>{profile.name}</span>
                </span>
                {current_org && <Tooltip placement='bottom' title={'Organisation: '+current_org.org_name}><Tag color="geekblue">{current_org.org_name}</Tag></Tooltip>}

                {/* <Dropdown overlay={this.menu} className='pointer' trigger={['click']}> */}
                {/* </Dropdown> */}
            </span>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        currentUser : state.currentUser && state.currentUser.data && state.currentUser.data.data,
        organisations : state.organisations && state.organisations.data && state.organisations.data.data && state.organisations.data.data.Organisations
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout(){
            dispatch({type:'LOGOUT_REQUEST'})
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileDropdown);