const TYPES = {
  FETCH_DATASET: 'FETCH_DATASET',
  FETCH_DATASETS: 'FETCH_DATASETS',
  FETCH_DATASET_SUCCESS: 'FETCH_DATASET_SUCCESS',
  FETCH_DATASET_FAILED: 'FETCH_DATASET_FAILED',
  FETCH_DATASETS_SUCCESS: 'FETCH_DATASETS_SUCCESS',
  FETCH_DATASETS_FAILED: 'FETCH_DATASETS_FAILED',
  UPDATE_DATASET: 'UPDATE_DATASET',
  UPDATE_DATASET_FAILED: 'UPDATE_DATASET_FAILED',
  UPDATE_DATASET_SUCCESS: 'UPDATE_DATASET_SUCCESS',
  CREATE_NEW_DATASET: 'CREATE_NEW_DATASET',
  CREATE_NEW_DATASET_SUCCESS: 'CREATE_NEW_DATASET_SUCCESS',
  CREATE_NEW_DATASET_FAILED: 'CREATE_NEW_DATASET_FAILED',
  DELETE_DATASET: 'DELETE_DATASET',
  DELETE_DATASET_SUCCESS: 'DELETE_DATASET_SUCCESS',
  FETCH_ALL_DATASETS: 'FETCH_ALL_DATASETS',
};

export default TYPES;
