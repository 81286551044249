import ACTIONS from "./TagsActionTypes";
import {store} from '../../config/Store';

export function fetchTagsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function createTagRequest(tagInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:tagInfo})
}
export function deleteTagRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

export function fetchMoreTagsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function updateTagRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}

