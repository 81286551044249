import ACTIONS from './ProfileActionTypes';

const initialState = {data:null,loading:false,error:null}

const profileReducer = (state=initialState, action) => {
    switch(action.type){
        case ACTIONS.FETCH_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.FETCH_SUCCESS: return {...state,data:action.payload,loading:false};
        case ACTIONS.FETCH_FAIL: return {...state,loading:false,error:action.payload};

        case ACTIONS.UPDATE_REQUEST: return {...state,loading:true,error:null};
        case ACTIONS.UPDATE_SUCCESS: return {...state,data:action.payload.data,loading:false};
        case ACTIONS.UPDATE_FAIL: return {...state,loading:false,error:action.payload};

        default:
            return state;
    }
}

export default profileReducer;