import React from 'react';
import {connect} from 'react-redux';
import {Row, Modal, Spin} from 'antd';
import Header from './components/recordHeader'
import AddRecord from './components/addRecord'
import ViewRecord from './components/viewRecord'
import List from './components/entriesList'
import {Redirect} from 'react-router'
import {
    createRecordRequest,
    fetchRecordRequest,
    updateRecordRequest,
    deleteRecordRequest
} from "../record/duck/RecordActions";
import {deleteDataset, updateDataset} from "./duck/DatasetActions";
import {showNotification} from "../utils/commonFunctions";
import { fetchAttachments, deleteAttachment } from '../attachments/duck/AttachmentsActions';

// instate variable
const {confirm} = Modal;
let deletedIds = []

class RecordContainer extends React.PureComponent {
    // setup state
    state = {
        addModel: false,
        redirect: false,
        viewModel: false,
        selectedData: null,
        redirectToForm: false,
        showDelete: false,
        data: null,
        id: null,
        pageSize: 10,
        loader: false,
        loading: true
    }

    componentDidMount() {
        let { mDataset, dataset_id } = this.props;
        this.setState({id: dataset_id})
        mDataset && mDataset.attributes &&  mDataset.attributes.fields
        && Object.keys(mDataset.attributes.fields).map(id => {
            if (mDataset.attributes.fields[id] && mDataset.attributes.fields[id].dataType === "dataset") {
                fetchRecordRequest({id: id});
            }
        })
        fetchRecordRequest({id: dataset_id});
    }

    componentDidUpdate(prevProps, prevState) {
        const { match: { params: { id } }, mDataset } = this.props
        if(mDataset !== (prevProps && prevProps.mDataset)){
            const fields = mDataset && mDataset.attributes &&  mDataset.attributes.fields 
            Object.keys(fields).map(id => {
                if (fields[id] && fields[id].dataType === "dataset") {
                    fetchRecordRequest({id: id});
                }
            })
        }
    }

    createRecord = (info) => {
        let {dataset} = this.props.dataset;
        let currentDataset = dataset.data.find(o => o.id === this.state.id)
        if(currentDataset) {
            let newInfo = {
                ...info,
                dataset_id: currentDataset.id,
                fields: info.fields,
                tags: [],
                meta: {},
                sections: currentDataset.attributes.sections
            };
            console.log('Created record: ', newInfo)
            createRecordRequest(newInfo)
        }
        this.setState({addModel: false})
    }

    deleteRecord = () => {
        console.log('DeletedId', deletedIds)
        confirm({
            title: 'Do you want to delete this record?',
            content: '',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
                deleteRecordRequest(deletedIds)
                this.setState({showDelete: false})
            },
            onCancel: () => {
                console.log('Cancel');
            }
        });
    }

    updateRecord = (id, data) => {

        console.log('updateRecord', id, data)
        updateRecordRequest(id, data)
        this.triggerViewModel()
    }

    updateDataset = (data) => {
        let {dataset} = this.props.dataset;
        let currentDataset = dataset.data.find(o=>o.id === this.state.id)
        if(currentDataset){
            this.props.updateDataset({id: this.state.id, data: {...data}})
        }
    }

    loadMoreRecord = (page, size) => {
        // let {data,meta} = this.props.users.data;
        // let totalUsers = data.length;
        // if ( totalUsers < meta.totalCount ) {
        //     let params = {
        //         limit: page*size, offset: totalUsers
        //     };
        //     this.setState({loadingMore:true});
        //     // fetchMoreUsersRequest(params)
        // }
    }

    // state setup
    triggerAddModel = (val) => {
        let {dataset} = this.props.dataset;
        let currentDataset = dataset.data.find(o=>o.id === this.state.id)
        if(currentDataset && Object.keys(currentDataset.attributes.fields).length === 0){
            showNotification('error', 'No form found.')
        } else {
            this.setState({addModel: !this.state.addModel});
        }
    }

    triggerViewModel = () => {
        this.setState({viewModel: !this.state.viewModel})
    }

    select = (list) => {
        let data = {};
        if (Array.isArray(list.fields)) {
            list.fields.map(item => {
                if (item.id) {
                    data[item.id] = item;
                }
            })
        } else {
            console.log(list.fields)
        }
        list.fields = data;
        this.setState({viewModel: !this.state.viewModel, selectedData: list})
    }

    selectCheckBox = (ids) => {
        // if (deletedIds.length > 0) {
        //     deletedIds = ids.filter(function (obj) {
        //         return deletedIds.indexOf(obj) === -1;
        //     });
        // } else {
            deletedIds = ids
        // }

        if (ids.length > 0) {
            this.setState({showDelete: true})
        } else {
            this.setState({showDelete: false})
        }
    }

    handleMenu = (id, e) => {
        if (e.key === 'delete') {
            confirm({
                title: 'Do you want to delete this dataset?',
                content: '',
                okText: 'Yes',
                cancelText: 'No',
                onOk: () => {
                    this.setState({redirect: true}, () => {
                        this.props.deleteDatasetID(id)
                })},
                onCancel: () => {
                    console.log('Cancel');
                }
            });
        }
        if (e.key === 'edit') {
            this.setState({redirectToForm: true})
        }
    }

    paginationSet = (size) => {
        this.setState({pageSize: size})
    }

    render() {
        const {addModel, viewModel, selectedData, redirect, redirectToForm, showDelete, pageSize, loading} = this.state;
        let {record, dataset_id} = this.props;
        let {dataset} = this.props.dataset;
        const loader = <Row type={'flex'} align={'middle'} justify={'center'} style={{minHeight: '70vh'}}><Spin size={"large"}/></Row>
        // if(loading){
        //     return loader
        // }
        if(dataset){
            let currentDataset = dataset.data.find(o=>o.id === this.state.id)

            if(currentDataset && Object.keys(currentDataset.attributes.fields).length === 0 && this.props.location.state && this.props.location.state.redirect){
                return <Redirect to={`/app/form/edit/dataset/${currentDataset.id}`}/>
            } else {
                if(currentDataset){
                    if (redirect) {
                        return <Redirect to={'/app/dataset'} push/>
                    } else if (redirectToForm) {
                        return <Redirect to={`/app/form/edit/dataset/${this.state.id}`}/>
                    }
                    let arrayField, arrayDataset = {...currentDataset.attributes.fields}, arrayData = {...currentDataset};
                    if (Object.keys(currentDataset.attributes.fields).length > 0) {
                        arrayField = Object.keys(currentDataset.attributes.fields).map(currentFields => {
                            return currentDataset.attributes.fields[currentFields];
                        })
                    }

                    return (
                        <Row>
                            <Header
                                data={arrayData}
                                showDelete={showDelete}
                                edit={null}
                                updateDataset={this.updateDataset}
                                paginationSet={this.paginationSet}
                                delete={this.deleteRecord}
                                create={this.triggerAddModel}
                                handleMenu={this.handleMenu}
                                currentDataset={currentDataset}
                            />
                            <List
                                dataset_id={dataset_id}
                                data={arrayData}
                                // record={record.data}
                                select={this.select}
                                paginationSize={pageSize}
                                selectCheckBox={this.selectCheckBox}
                            />
                            <AddRecord
                                data={arrayField}
                                dataSet={arrayData}
                                objectFields={currentDataset.attributes.fields}
                                visible={addModel}
                                create={this.createRecord}
                                onCloseModel={this.triggerAddModel}
                                attachments={this.props.attachments}
                                fetchAttachments={this.props.fetchAttachments}
                                deleteAttachment={this.props.deleteAttachment}
                             />
                            <ViewRecord
                                data={selectedData}
                                dataSet={arrayData}
                                objectFields={arrayDataset}
                                visible={viewModel}
                                update={this.updateRecord}
                                onCloseModel={this.triggerViewModel}
                                attachments={this.props.attachments}
                                fetchAttachments={this.props.fetchAttachments}
                                deleteAttachment={this.props.deleteAttachment}
                            />
                        </Row>
                    )
                } else {
                    return loader
                }
            }
        } else {
            return loader
        }
    }
}

// redux setup
const mapStateToProps = (state, ownProps) => {
    let { match : { params : { id } } } = ownProps;
    
    let mDataset =  state.dataset && state.dataset.dataset && state.dataset.dataset.data 
                    && state.dataset.dataset.data.find(d => d.id === id);
    
    // console.log("mDataset", mDataset);

    return {
        mDataset: mDataset,
        dataset_id: id,
        users: state.users,
        teams: state.teams,
        // record: state.record,
        dataset: state.dataset,
        attachments: state.attachments,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        deleteDatasetID: (id) => dispatch(deleteDataset(id)),
        // fetchDatasets: () => dispatch(fetchDatasets()),
        updateDataset: (data) => dispatch(updateDataset(data)),
        fetchAttachments: (params) => dispatch(fetchAttachments(params)),
        deleteAttachment: (attachment) => dispatch(deleteAttachment(attachment)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecordContainer);

