import {eventChannel} from 'redux-saga';
import { take, put } from 'redux-saga/effects';

const makeConnectivityChannel = () =>
    eventChannel(emitter => {
        // Expose online status to watcher
        const online = () => emitter(true)
        const offline = () => emitter(false)

        window.addEventListener('online', online)
        window.addEventListener('offline', offline)

        // Unsubscribe
        return () => {
            window.removeEventListener('online', online)
            window.removeEventListener('offline', offline)
        }
    })

function* connectionSaga () {
    const connectivityChannel = makeConnectivityChannel()

    while (true) {
        const online = yield take(connectivityChannel)
        yield put({ type: online ? 'ONLINE' : 'OFFLINE' })
    }
}

export default connectionSaga;

