import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Prompt } from 'react-router';
import { Row } from 'antd';
import Header from './components/header'
import FormBuilderContainer from './formBuilder/formBuilderContainer'
import { guidGenerator, showNotification } from '../utils/commonFunctions'
import { updateProcessRequest } from '../process/duck/ProcessActions'
import { fetchProject, updateProject } from '../project/duck/ProjectActions'
import appUrl from "../constants/AppUrl";
import axios from "axios";
import WorkflowBuilderContainer from './workflowBuilder/workflowBuilderContainer';
import $ from "jquery";

const guid = guidGenerator()
class ProcessBuilderContainer extends React.Component {

    state = {
        unsavedProcess: false,
        redirectToProjects: false,
        redirectToProcess: false,
        isProject: false,
        current: 0,
        loading: false,
        draftLoading: false,
        activeProcess: null,
        processBookmarked: false,
        sections: [
            {
                id: guidGenerator(),
                section_name: 'Untitled Section',
                section_description: 'Section Information',
                section_layout: [],
                is_section: true,
                conditions: false,
                dependency: {
                    draw_data: {},
                    logic: {}
                },
            }
        ],
        steps: {
            [guid]: {
                id: guid,
                step_name: '',
                execution: 'user',
                dependency: {
                    draw_data: {},
                    logic: {}
                },
                prev_step_id: [],
                next_step_id: [],
                assignment: {
                    method: 'manual',
                    teams: [],
                    people: []
                },
                fields: {},
                sections: {},
                position: {
                    top: 50,
                    left: ''
                },
                deadline: {
                    duration: null,
                    duration_type: null,
                    action: null
                },
                actions: {
                    stopped: {
                        alias: 'Discarded',
                        action: 'Discard',
                        show: true
                    },
                    submitted: {
                        alias: 'Submitted',
                        action: 'Submit',
                        show: true
                    }
                },
                triggers: {
                    submitted: {
                        email: {
                            text: null,
                            subject: null,
                            to: null,
                            status: 'off'
                        },
                        sms: {
                            text: null,
                            to: null,
                            status: 'off'
                        },
                        webhook: {
                            url: null,
                            status: 'off'
                        }
                    },
                    stopped: {
                        email: {
                            text: null,
                            subject: null,
                            to: null,
                            status: 'off'
                        },
                        sms: {
                            text: null,
                            to: null,
                            status: 'off'
                        },
                        webhook: {
                            url: null,
                            status: 'off'
                        }
                    }
                },
                start: true
            }
        },
        allFields: {},
        draw_data: {},
        dataset:{
            _id : null,
            update_records: false
        }
    }

    createNodeObject = (nodeId, prevNodeId, position, initiator = false) => {
        return (
            {
                [nodeId]: {
                    id: nodeId,
                    step_name: '',
                    execution: 'user',
                    dependency: {
                        draw_data: {},
                        logic: {}
                    },
                    prev_step_id: [prevNodeId],
                    next_step_id: [],
                    assignment: {
                        method: 'manual',
                        teams: [],
                        people: []
                    },
                    fields: {},
                    sections: {},
                    position: {
                        top: parseFloat(position.top),
                        left: parseFloat(position.left)
                    },
                    deadline: {
                        duration: null,
                        duration_type: null,
                        action: null
                    },
                    actions: {
                        rejected: {
                            alias: 'Rejected',
                            action: 'Reject',
                            show: true
                        },
                        stopped: {
                            alias: 'Stopped',
                            action: 'Stop',
                            show: true
                        },
                        assigned: {
                            alias: 'Assigned',
                            action: 'Re-assign',
                            show: true
                        },
                        submitted: {
                            alias: 'Submitted',
                            action: 'Submit',
                            show: true
                        }
                    },
                    triggers: {
                        assigned: {
                            email: {
                                text: null,
                                subject: null,
                                to: null,
                                status: 'off'
                            },
                            sms: {
                                text: null,
                                to: null,
                                status: 'off'
                            },
                            webhook: {
                                url: null,
                                status: 'off'
                            }
                        },
                        rejected: {
                            email: {
                                text: null,
                                subject: null,
                                to: null,
                                status: 'off'
                            },
                            sms: {
                                text: null,
                                to: null,
                                status: 'off'
                            },
                            webhook: {
                                url: null,
                                status: 'off'
                            }
                        },
                        submitted: {
                            email: {
                                text: null,
                                subject: null,
                                to: null,
                                status: 'off'
                            },
                            sms: {
                                text: null,
                                to: null,
                                status: 'off'
                            },
                            webhook: {
                                url: null,
                                status: 'off'
                            }
                        },
                        stopped: {
                            email: {
                                text: null,
                                subject: null,
                                to: null,
                                status: 'off'
                            },
                            sms: {
                                text: null,
                                to: null,
                                status: 'off'
                            },
                            webhook: {
                                url: null,
                                status: 'off'
                            }
                        }
                    },
                    start: false
                }
            }
        )
    }

    drawData = () => {
        let draw_data = {};
        $('#workflow-canvas .wfnode').each(function (i, v) {
            let nodeId = $(this).attr('id')
            draw_data[nodeId] = {
                position: {
                    top: $(this).position().top,
                    left: $(this).position().left,
                },
                step_name: $(this).find('input[type=text]').val()
            }
            if ($(this).data('lines') && $(this).data('lines').length > 0) {
                draw_data[nodeId]['lines'] = [];
                for (let j = 0; j < $(this).data('lines').length; j++) {
                    draw_data[nodeId]['lines'].push({
                        x1: $(this).data('lines')[j][0].attributes.x1.value,
                        y1: $(this).data('lines')[j][0].attributes.y1.value,
                        x2: $(this).data('lines')[j][0].attributes.x2.value,
                        y2: $(this).data('lines')[j][0].attributes.y2.value,
                        connectors: $(this).data('lines')[j][0].attributes.connectors.value,
                        id: $(this).data('lines')[j][0].attributes.id.value
                    })
                    if ($(this).data('lines')[j][0].attributes.drop_id)
                        draw_data[nodeId]['lines'][j]['drop_id'] = $(this).data('lines')[j][0].attributes.drop_id.value;
                }
            }
        });
        return draw_data;
    }

    onChangeOfStep = (current) => {
        if (current && Object.keys(this.state.allFields).length === 0) {
            showNotification('info', 'You need to add atleast a single field to create the workflow.')
            return false
        }
        if (!current) {
            this.setState({ draw_data: this.drawData() })
            // console.log(this.drawData())
        }
        this.setState({ current })
    }

    componentDidMount() {
        // console.log(this.createNodeObject(guid,true))
        document.querySelector('.app-content-block').classList.add('overlayWithHeader')
        let isProject = this.checkForProject()
        this.setState({ isProject })
        if (isProject) {
            this.loadProject()
        } else {
            this.loadProcess()
        }
        // console.log('isProject', isProject)
    }

    componentDidUpdate(prevProps) {
        if (this.state.isProject) {
            if (prevProps.project !== this.props.project) {
                let activeProject = this.props.project;
                if (activeProject.sections && activeProject.sections.length > 0) {
                    if (activeProject.fields && Object.keys(activeProject.fields).length > 0) {
                        this.setState({
                            sections: activeProject.sections,
                            allFields: activeProject.fields
                        })
                    } else {
                        this.setState({ sections: activeProject.sections })
                    }
                }
            }
        }
    }

    checkForProject = () => {
        let url = window.location.pathname
        let arr = url.split('/')
        let isProject = arr[arr.length - 2]
        return isProject === 'project' ? true : false
    }

    loadProject = () => {
        let projectId = this.props.match.params.id;
        if (!this.props.project) {
            this.props.fetchProject(projectId)
        } else {
            let activeProject = this.props.project;
            if (activeProject.sections && activeProject.sections.length > 0) {
                if (activeProject.fields && Object.keys(activeProject.fields).length > 0) {
                    this.setState({
                        sections: activeProject.sections,
                        allFields: activeProject.fields
                    })
                } else {
                    this.setState({ sections: activeProject.sections })
                }
            }
        }
    }

    loadProcess = () => {
        let process = this.props.process.data && (this.props.process.data.data && this.props.process.data.data.length > 0) ? this.props.process.data.data : [];
        let activeProcess;
        if (this.props.match.params.id) {
            activeProcess = process.find(p => p._id === this.props.match.params.id)
            if (activeProcess) {
                this.setActiveProcess(activeProcess)
            } else {
                const hide = showNotification('info', 'Fetching a process, please wait...', 1.5);
                axios({
                    url: appUrl.PROCESS_URL + '/' + this.props.match.params.id,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` }
                }).then(res => {
                    if (res.data.data) {
                        this.setActiveProcess(res.data.data)
                    }
                    setTimeout(hide)
                    showNotification('success', 'Process fetched successfully');
                })
            }
        }
    }

    setActiveProcess = (activeProcess) => {
        // console.log('activeProcess',activeProcess)
        this.setState({ activeProcess });
        if (activeProcess.sections && activeProcess.sections.length > 0) {
            if (activeProcess.fields && Object.keys(activeProcess.fields).length > 0) {
                if (activeProcess.steps && Object.keys(activeProcess.steps).length > 0) {
                    this.setState({
                        sections: activeProcess.sections,
                        allFields: activeProcess.fields,
                        steps: activeProcess.steps,
                        draw_data: activeProcess.draw_data,
                        processBookmarked:activeProcess.bookmarked ? activeProcess.bookmarked: false,
                        dataset:activeProcess.dataset ? activeProcess.dataset : this.state.dataset
                    })
                } else {
                    this.setState({
                        sections: activeProcess.sections,
                        allFields: activeProcess.fields,
                    })

                }
            } else {
                this.setState({ sections: activeProcess.sections })
            }
        }
    }

    componentWillUnmount() {
        document.querySelector('.app-content-block').classList.remove('overlayWithHeader')
    }

    onPublish = (name, description) => {
        if (this.state.isProject) {
            this.publishProject()
        } else {
            this.publishProcess(name, description)
        }
    }

    publishProject = () => {
        this.setState({ loading: true })
        let _this = this;
        let activeProject = Object.assign({}, this.props.project);
        activeProject.fields = this.state.allFields
        activeProject.sections = this.state.sections
        console.log('Project template maker data ----->', activeProject)
        this.props.updateProject(activeProject)
        setTimeout(function () {
            _this.setState({ loading: false, redirectToProjects: true })
            showNotification('success', 'Form saved successfully');
        }, 1000)
    }

    publishProcess = (name, description) => {
        const { activeProcess, allFields, sections, steps, current, draw_data, processBookmarked, dataset } = this.state
        let count = 0
        $('.wfnode.error-alert').removeClass('error-alert')
        Object.keys(steps).map(step => {
            if (steps[step].step_name.trim() === '') {
                count++;
                $('#' + step).addClass('error-alert').effect('shake');
            }
        })
        if (count > 0) {
            showNotification('error', 'Step name should not be blank')
            return false
        }
        this.setState({ loading: true })
        let _this = this;
        let data = {
            name        : name,
            description : description,
            fields      : allFields,
            sections    : sections,
            steps       : steps,
            draw_data   : current ? this.drawData() : draw_data,
            draft       : false,
            bookmarked  : processBookmarked,
            dataset     : dataset
        }
        updateProcessRequest(activeProcess._id, data)
        console.log('Process maker data ----->', data)
        setTimeout(function () {
            _this.setState({ loading: false, redirectToProcess: true })
            showNotification('success', data.name + ' process updated successfully');
        }, 1000)
    }

    saveAsDraft = (name, description) => {
        this.setState({ draftLoading: true })
        const {activeProcess, allFields, sections, steps, current, draw_data, processBookmarked, dataset} = this.state
        let _this = this;
        let data = {
            name        : name,
            description : description,
            fields      : allFields,
            sections    : sections,
            steps       : steps,
            draw_data   : current ? this.drawData() : draw_data,
            draft       : true,
            bookmarked  : processBookmarked,
            dataset     : dataset
        }
        updateProcessRequest(activeProcess._id, data)
        console.log('Process maker data ----->', data)
        setTimeout(function () {
            _this.setState({ draftLoading: false, redirectToProcess: true })
            showNotification('success', data.name + ' process saved as draft');
        }, 1000)
    }


    bookmarkProcess = (e, name, description) => {
        console.log(e.target.checked, this.state.activeProcess._id)
        this.setState({ processBookmarked: e.target.checked })
    }

    updateSections = (data) => {
        this.setState({ sections: data, unsavedProcess: true });
    }

    updateFields = (fields) => {
        this.setState({ allFields: fields, unsavedProcess: true })
    }

    updateSteps = (steps) => {
        this.setState({ steps, unsavedProcess: true })
    }

    toggleDatasetUpdation = () => {
        let obj = Object.assign({}, this.state.dataset)
        obj.update_records = !obj.update_records
        this.setState({dataset: obj})
    }

    render() {
        let { current, activeProcess, steps, isProject, unsavedProcess } = this.state;
        let activeStep;
        // let loader = this.props.process.loading;
        if (isProject) {
            activeProcess = this.props.project
            if (this.state.redirectToProjects) {
                return <Redirect to={`/app/projects/${this.props.match.params.id}`} />
            }
        } else {
            if (this.state.redirectToProcess) {
                return <Redirect to={`/app/processes`} />
            }
        }
        Object.keys(steps).map(id => {
            if (steps[id].start) {
                activeStep = steps[id]
            }
        })
        console.log('activeProcess', activeProcess)
        return (
            <Row className='fh' style={{ height: current ? '300%' : 'auto' }}>
                <Prompt when={unsavedProcess} message="Changes that you made to this process are not saved! Are you sure you want to leave?" />
                <Header
                    name                = {activeProcess && (!isProject ? activeProcess.name : activeProcess.name)}
                    description         = {activeProcess && (!isProject ? activeProcess.description : activeProcess.description)}
                    onPublish           = {this.onPublish}
                    saveAsDraft         = {this.saveAsDraft}
                    onChangeOfStep      = {this.onChangeOfStep}
                    current             = {current}
                    loader              = {this.state.loading}
                    draftLoading        = {this.state.draftLoading}
                    isProject           = {isProject}
                    projectId           = {this.props.match.params.id}
                    bookmarkProcess     = {this.bookmarkProcess}
                    processBookmarked   = {this.state.processBookmarked}
                    dataset             = {this.state.dataset}
                    toggleDatasetUpdation = {this.toggleDatasetUpdation}
                />
                {
                    !current
                        ?
                        <FormBuilderContainer
                            activeProcess   = {activeProcess}
                            sections        = {this.state.sections}
                            allFields       = {this.state.allFields}
                            updateSections  = {this.updateSections}
                            updateFields    = {this.updateFields}
                            // these props are for workflow, to handle execution on field deletion
                            isProject       = {isProject}
                            steps           = {steps}
                            updateSteps     = {this.updateSteps}
                        />
                        :
                        <WorkflowBuilderContainer
                            activeProcess   = {activeProcess}
                            steps           = {steps}
                            sections        = {this.state.sections}
                            allFields       = {this.state.allFields}
                            updateSteps     = {this.updateSteps}
                            users           = {this.props.users}
                            teams           = {this.props.teams}
                            activeStep      = {activeStep}
                            createNodeObject= {this.createNodeObject}
                            draw_data       = {this.state.draw_data}
                        />
                }
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        process: state.process,
        project: state.project.active && state.project.projects[state.project.active],
        users  : state.users && state.users.data && state.users.data.data,
        teams  : state.teams && state.teams.data && state.teams.data.data
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProject: (id) => dispatch(fetchProject(id)),
        updateProject: (project) => dispatch(updateProject(project)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessBuilderContainer);
