import React from 'react'
import { Divider, Tag, Icon, message, Tooltip } from 'antd';
import { Container } from "react-smooth-dnd";
// import Task from '../../../tasks/components/TaskCard';
// import TaskForm from '../../../tasks/components/TaskCard/TaskForm';
import SegmentForm from '../Segment/SegmentForm';

import './styles.css';

class LaneSegment extends React.Component {

  state = {
    showCreateNewSegment: false,
    showEditSegmentName: false,
    dragging: false
  }

  showCreateNewSegment = () => {
    this.setState({
      showCreateNewSegment: true
    })
  }

  hideCreateNewSegment = () => {
    this.setState({
      showCreateNewSegment: false
    });
  }

  createNewSegment = (segment) => {
    this.hideCreateNewSegment();
    this.props.createNewSegment(segment);
  }

  handleTaskDrop = (segmentIndex, dropResult) => {
    if (dropResult.removedIndex !== null) {
      this.props.removeTask(segmentIndex, dropResult.removedIndex);
    }
    if (dropResult.addedIndex !== null) {
      this.props.addTask(segmentIndex, dropResult.addedIndex, dropResult.payload._id)
    }
  }

  showEditSegmentName = () => {
    this.setState({
      showEditSegmentName: true
    })
  }

  hideEditSegmentName = () => {
    this.setState({
      showEditSegmentName: false
    })
  }

  changeSegmentName = (form) => {
    const { segmentIndex, updateSegment } = this.props;
    const segment = Object.assign({}, this.props.segment)
    segment.name = form.name;
    this.hideEditSegmentName();
    updateSegment(segment, segmentIndex);
  }

  addTask = (task) => {
    const { segmentIndex } = this.props;
    this.props.createTask(task, segmentIndex);
  }

  deleteSection = (e) => {
    if (this.props.segment.tasks.length > 0) {
      message.error("This section has tasks and can't be deleted.");
    } else {
      this.props.deleteSection(this.props.segmentIndex);
    }
  }

  deleteTask = (taskIndex, id) => {
    const { segmentIndex } = this.props;
    this.props.deleteTask(segmentIndex, taskIndex, id);
  }

  startDrag = () => {
    this.setState({
      dragging: true
    })
  }

  endDrag = () => {
    this.setState({
      dragging: false
    })
  }

  render() {
    const { showEditSegmentName, showCreateNewSegment } = this.state;
    const { segmentIndex, segment, color, tasks } = this.props;
    const { lastSegment } = this.props;
    const showAddSectionTag = true;
    return (
      <div style={{...this.props.style, height: lastSegment ? "30%" : undefined}}>
        {!showEditSegmentName && segment.name === '+ Add Section' && <Divider
          onClick={this.showEditSegmentName}
          style={{color: color.colorDark || "#2E5BFF"}}
        >
          <Tag
            color={color.colorLight || "rgba(46, 91, 255, 0.15)"}
            style={{color: color.colorDark || "#2E5BFF", textAlign: "center"}}
          >
              + Add Section
          </Tag>
        </Divider>}
        {!showEditSegmentName && segment.name !== '+ Add Section' && <Divider
          className="section-divider"
          style={{color: color.colorDark || "#2E5BFF"}}
        >
          <Tooltip placement="topLeft" title={segment.name.length > 20 ? segment.name : null}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Icon style={{visibility: "hidden"}} type="close-circle" />
              <span onClick={this.showEditSegmentName}>
                  {segment.name.slice(0, 20)}
                  {segment.name.length > 20 ? '...' : null}
              </span>
              <Icon className="icon-section-close" type="close-circle" onClick={this.deleteSection} />
            </div>
          </Tooltip>
        </Divider>}
        {showEditSegmentName && <Divider
          onClick={this.showEditSegmentName}
          style={{color: color.colorDark || "#2E5BFF"}}
        >
          <SegmentForm
            onAdd={this.changeSegmentName}
            name={segment.name}
            onCancel={this.hideEditSegmentName}
          />
        </Divider>}
        <Container
          style={{"--color-light": color.colorLight, "--color-dark": color.colorDark, height: lastSegment ? "100%" : undefined}}
          groupName="segment"
          onDrop={(e) => this.handleTaskDrop(segmentIndex, e)}
          getChildPayload={index => tasks[segment.tasks[index]]}
          dropPlaceholder={{
            animationDuration: 150,
            showOnTop: true,
            className: 'cards-drop-preview'
          }}
          // style={{height: lastSegment ? "100%" : undefined}}
          onDragStart={this.startDrag}
          onDragEnd={this.endDrag}
        >
          {/* {showAddTask && <TaskForm
            color={color}
            onCancel={hideAddTask}
            onAdd={this.addTask}
          />} */}
          {/* {segment.tasks.map((taskId, index) => (
            <Draggable key={taskId}>
              {tasks[taskId] && <Task
                task={tasks[taskId]}
                dragging={this.state.dragging}
                users={this.props.users}
                color={color}
                updateTask={this.props.updateTask}
                deleteTask={(id) => this.deleteTask(index, id)}
                loggedInuser={this.props.loggedInuser}
              />}
            </Draggable>
          ))} */}
          {showAddSectionTag && lastSegment && <Divider
            className="add-section-tag"
            style={{
              color: color.colorDark || "#2E5BFF",
              cursor: "pointer",
            }}
            onClick={this.showCreateNewSegment}
          >
            {showCreateNewSegment && <SegmentForm
              onCancel={this.hideCreateNewSegment}
              onAdd={this.createNewSegment}
            />}
            {!showCreateNewSegment && <Tag
              color={color.colorLight || "rgba(46, 91, 255, 0.15)"}
              style={{color: color.colorDark || "#2E5BFF", textAlign: "center"}}
            >
                + Add Section
            </Tag>}
          </Divider>}
        </Container>
      </div>
    );
  }
}

export default LaneSegment;
