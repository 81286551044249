import React from 'react'
import {Redirect} from 'react-router-dom'
import { Tooltip, Tag, Row, Icon, Button, Menu, Dropdown, Input, Modal, message } from 'antd';
import {Link} from 'react-router-dom'
import { showNotification } from '../../../utils/commonFunctions';
// import { debounce } from 'lodash';
import PublishProjectToMarketplaceModal from '../PublishProjectToMarketplaceModal/';

const { confirm } = Modal;

class Header extends React.Component{

  state = {
    selectedUsers : this.props.project.owner.map(u=>u._id),
    enableProjectNameEdit: false,
    projectName: this.props.project.name,
    projectDescription: this.props.project.description,
    view: this.props.project.view,
    edit: this.props.project.edit,
    owner: this.props.project.owner,
    users: this.props.users,
    savingDraft: false,
    publishingProject: false,
    openPublishToMarketPlaceDialog: false,
    redirect:false
    // users: this.props.users.filter(user => {
    //   if (this.props.project.view.reduce((result, _user) => result || user.id === _user._id, false)) {
    //     return false;
    //   }
    //   if (this.props.project.edit.reduce((result, _user) => result || user.id === _user._id, false)) {
    //     return false;
    //   }
    //   if (this.props.project.owner.reduce((result, _user) => result || user.id === _user._id, false)) {
    //     return false;
    //   }
    //   return true;
    // }),
    // usersMap: this.props.users.reduce((result, user) => {
    //   result[user.id] = user;
    //   return result;
    // }, {})
  };

  constructor(props) {
    super(props);
    this.projectNameRef = React.createRef();
    this.projectDescriptionRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.updatedProject !== this.props.updatedProject && this.props.updatedProject === this.props.project._id) {
      if (this.state.publishingProject) {
        showNotification('success', 'Project published successfully')
        this.setState({redirect:true})
      }
      if (this.state.savingDraft) {
        showNotification('success', 'Project saved as draft')
        this.setState({redirect:true})
      }
      this.setState({
        savingDraft: false,
        publishingProject: false
      });
    }
  }

  enableProjectNameEdit = () => {
    this.setState({
      enableProjectNameEdit: true
    }, () => {
      this.projectNameRef.current.focus();
    })
  }

  enableProjectDescriptionEdit = () => {
    this.setState({
      enableProjectDescriptionEdit: true
    }, () => {
      this.projectDescriptionRef.current.focus();
    });
  }

  updateProjectName = (e) => {
    this.setState({
      projectName: e.target.value
    })
  }

  updateProjectDescription = (e) => {
    this.setState({
      projectDescription: e.target.value
    })
  }

  updateProject = () => {
    const project = Object.assign({}, this.props.project);
    project.name = this.state.projectName;
    project.description = this.state.projectDescription;
    this.props.updateProject(project);
    this.setState({
      enableProjectNameEdit: false,
      enableProjectDescriptionEdit: false
    })
  }

  copyProjectLink = () => {
    const { project } = this.props;
    const url  = `${window.location.origin}/app/projects/${project._id}`;

    const textArea = document.createElement("textarea");

    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    textArea.value = url;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      // var msg = successful ? 'successful' : 'unsuccessful';
      message.success('Link has been copied!');
    } catch (err) {
      message.error("Link can't be copied!");
    }
  }

  deleteProject = () => {
    const { project, deleteProject } = this.props;
    confirm({
      title: `Are you sure you want to delete project ${project.name}`,
      content: 'All the tasks part of this project will be deleted!!',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteProject && deleteProject(project._id);
      },
      onCancel() {
      },
    });
  }

  takeProjectMenuAction = (action) => {
    switch(action.key) {
      case 'copy_project_link':
        this.copyProjectLink();
        break;
      case 'delete':
        this.deleteProject();
        break;
      default:
        break;
    }
  }

  searchUsers = (query) => {
    if (query === '' || query === true) {
      this.setState({
        users: this.props.users.filter(user => {
          if (this.state.view.reduce((result, _user) => result || user.id === _user._id, false)) {
            return false;
          }
          if (this.state.edit.reduce((result, _user) => result || user.id === _user._id, false)) {
            return false;
          }
          if (this.state.owner.reduce((result, _user) => result || user.id === _user._id, false)) {
            return false;
          }
          return true;
        })
      })
    } else {
      const regexp = new RegExp(query, 'i');
      const users = this.props.users.filter(user => {
        return regexp.test(user.attributes.name)
      }).filter(user => {
        if (this.state.view.reduce((result, _user) => result || user.id === _user._id, false)) {
          return false;
        }
        if (this.state.edit.reduce((result, _user) => result || user.id === _user._id, false)) {
          return false;
        }
        if (this.state.owner.reduce((result, _user) => result || user.id === _user._id, false)) {
          return false;
        }
        return true;
      });
      this.setState({
        users
      });
    }
  }

  addUserToSharedList = (value) => {
    this.setState({
      view: [{_id: value}, ...this.state.view]
    });
  }

  selectUsers = (val) => {
    this.setState({selectedUsers:val})
  }

  changeUserRole = (key, userId, oldKey) => {
    // const { view, edit, owner } = this.state;
    const { loggedInuser } = this.props;
    if (oldKey !== "owner" && userId !== loggedInuser.id && key !== oldKey) {
      this.setState({
        [oldKey]: this.state[oldKey].filter(userObj => userObj._id !== userId),
        [key]: [{_id: userId}, ...(this.state[key] || [])]
      });
    } else {
      //message.error("You can't remove yourself from ownership");
    }
  }

  removeUser = (userId) => {
    const view = this.state.view && this.state.view.filter(userObj => userObj._id !== userId);
    const edit = this.state.edit && this.state.edit.filter(userObj => userObj._id !== userId);
    const owner = this.state.owner && this.state.owner.filter(userObj => userObj._id !== userId);
    this.setState({
      view,
      edit,
      owner,
    })
  }

  renderRoleMenu = (userId, oldKey) => {
    const { loggedInuser } = this.props;
    return (
      <Menu>
        <Menu.Item disabled={oldKey==="view" || userId === loggedInuser.id} key="view" onClick={(key) => this.changeUserRole('view', userId, oldKey)}>
            can view
        </Menu.Item>
        <Menu.Item disabled={oldKey==="edit" || userId === loggedInuser.id} key="edit" onClick={(key) => this.changeUserRole('edit', userId, oldKey)}>
            can edit
        </Menu.Item>
        <Menu.Item disabled={oldKey==="owner" || userId === loggedInuser.id} key="owner" onClick={(key) => this.changeUserRole('owner', userId, oldKey)}>
            Project Owner
        </Menu.Item>
        <Menu.Item key="delete" disabled={userId === loggedInuser.id} onClick={(key) => this.removeUser(userId)}>
          {userId !== loggedInuser.id && <span style={{color: "#FF3D57"}}>Remove from Project</span>}
          {userId === loggedInuser.id && 'Remove from Project'}
        </Menu.Item>
      </Menu>
    );
  }

  saveDraft = () => {
    this.setState({
      savingDraft: true
    })
    this.props.saveDraft();
  }

  publishProject = () => {
    this.setState({
      publishingProject: true,
    });
    this.props.publishProject();
  }

  openPublishToMarketPlaceDialog = () => {
    this.setState({
      openPublishToMarketPlaceDialog: true
    });
  }

  closePublishToMarketPlaceDialog = () => {
    this.setState({
      openPublishToMarketPlaceDialog: false
    });
  }

  render() {
    const {
      openPublishToMarketPlaceDialog,
      enableProjectNameEdit,
      projectName,
      projectDescription,
      savingDraft,
      publishingProject,
      enableProjectDescriptionEdit,
    } = this.state;
    const { project, marketplaceApp, categories, loggedInuser } = this.props;
    // const { users=[], view=[], edit=[], owner=[], usersMap={} } = this.state;
    if(this.state.redirect){
      return <Redirect to={`/app/projects`} />
    }
    return (
      <div style={{marginBottom: 20, display: "flex"}}>
        <div style={{flexGrow: 1}}>
          <Row>
            {enableProjectNameEdit && <Input
              maxLength="50"
              style={{width: "50%"}}
              onChange={this.updateProjectName}
              value={projectName}
              ref={this.projectNameRef}
              onKeyDown={e => e.key === 'Enter' && this.updateProject()}
              onBlur={this.updateProject}
            />}
            {!enableProjectNameEdit && <span
              style={{fontSize:'20px',lineHeight:'18px',color: '#44566C',fontWeight:'bold',marginRight:'8px'}}
              onClick={this.enableProjectNameEdit}
            >
                {project.name}
            </span>}
            <Dropdown
              trigger={["click"]}
              overlay={<Menu onClick={this.takeProjectMenuAction}>
                <Menu.Item key="copy_project_link">
                  <a>Copy Project Link</a>
                </Menu.Item>
                <Menu.Item key="delete">
                  <a style={{color: "#FF3D57"}}>Delete</a>
                </Menu.Item>
              </Menu>}>
              <Icon type="more" style={{fontSize: 16, cursor: "pointer"}} />
            </Dropdown>
            {project.draft && <Tag color="rgb(255, 99, 120)" style={{marginLeft: 8}}>Draft</Tag>}
          </Row>
          <Row>
            {enableProjectDescriptionEdit && <Input
              maxLength={70}
              value={projectDescription}
              onChange={this.updateProjectDescription}
              style={{width: "80%"}}
              ref={this.projectDescriptionRef}
              onKeyDown={e => e.key === 'Enter' && this.updateProject()}
              onBlur={this.updateProject}
            />}
            {!enableProjectDescriptionEdit && project.description && <span
              style={{fontSize:'12px',lineHeight:'18px', color:'#8798A8'}}
              onClick={this.enableProjectDescriptionEdit}
            >
              {project.description}
            </span>}
            {!enableProjectDescriptionEdit && !project.description && <span
              style={{fontSize:'12px',lineHeight:'18px', color:'#8798A8'}}
              onClick={this.enableProjectDescriptionEdit}
            >
              Add description
            </span>}
          </Row>
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} >
            {/*<div style={{cursor: "pointer"}}>
            <Icon style={{marginRight: 4}} type="filter" theme="filled" />
            <span style={{color: '#8798A8',marginRight:'28px',lineHeight:'22px'}} > FILTER </span>
            </div>*/}

              {/*<Dropdown
            bordered={false}
            title={null}
            overlay={<Menu>
              <Menu.Item>
                  Deadline
              </Menu.Item>
              <Menu.Item>
                  Priority
              </Menu.Item>
            </Menu>}
          >
            <div style={{cursor: "pointer"}}>
              <Icon style={{marginRight: 4}} type="sort-ascending" />
              <span style={{color: '#8798A8',marginRight:'28px',lineHeight:'22px'}} > SORT </span>
            </div>
            </Dropdown>*/}
          <Link to={`/app/form/edit/project/${project._id}`} style={{marginRight: 10}}>
            <Button type='primary' ghost>Edit Form</Button>
          </Link>
          <Button type='primary' ghost style={{marginRight: 10}} onClick={this.saveDraft} loading={savingDraft}>Save Draft</Button>
          <Button onClick={this.publishProject} loading={publishingProject} type="primary">Publish</Button>
          {loggedInuser.attributes.role_id === 'super_admin' && <Tooltip
            title={project.draft && "Project is in draft state, publish first!"}
          >
            <Button
              onClick={this.openPublishToMarketPlaceDialog}
              style={{marginLeft: 10}}
              type="danger"
              disabled={project.draft}
            >
              Publish to marketplace
            </Button>
          </Tooltip>}
          {loggedInuser.attributes.role_id === 'super_admin' && <PublishProjectToMarketplaceModal
            marketplaceApp={marketplaceApp}
            project={project}
            visible={openPublishToMarketPlaceDialog}
            onCancel={this.closePublishToMarketPlaceDialog}
            categories={categories}
            updateMarketplaceApp={this.props.updateMarketplaceApp}
            updatedApp={this.props.updatedApp}
            updatedAppFail={this.props.updatedAppFail}
          />}
        </div>
      </div>
    );
  }
}
export default Header
