const ACTIONS = {
    
    FETCH_REQUEST       : 'FETCH_PAGES_REQUEST',
    FETCH_SUCCESS       : 'FETCH_PAGES_SUCCESS',
    FETCH_FAIL          : 'FETCH_PAGES_FAIL',
    
    FETCH_ONE_REQUEST   : 'FETCH_ONE_PAGE_REQUEST',
    FETCH_ONE_SUCCESS   : 'FETCH_ONE_PAGE_SUCCESS',
    FETCH_ONE_FAIL      : 'FETCH_ONE_PAGE_FAIL',
    
    FETCH_MORE_REQUEST  : 'FETCH_MORE_PAGES_REQUEST',
    FETCH_MORE_SUCCESS  : 'FETCH_MORE_PAGES_SUCCESS',
    FETCH_MORE_FAIL     : 'FETCH_MORE_PAGES_FAIL',
    
    CREATE_REQUEST      : 'CREATE_PAGE_REQUEST',
    CREATE_SUCCESS      : 'CREATE_PAGE_SUCCESS',
    CREATE_FAIL         : 'CREATE_PAGE_FAIL',
    
    UPDATE_REQUEST      : 'UPDATE_PAGE_REQUEST',
    UPDATE_SUCCESS      : 'UPDATE_PAGE_SUCCESS',
    UPDATE_FAIL         : 'UPDATE_PAGE_FAIL',

    DELETE_REQUEST      : 'DELETE_PAGE_REQUEST',
    DELETE_SUCCESS      : 'DELETE_PAGE_SUCCESS',
    DELETE_FAIL         : 'DELETE_PAGE_FAIL',

    SET_ACTIVE_PAGE     : 'SET_ACTIVE_PAGE'
}

 export default ACTIONS;