import TYPES from './CategoriesActionTypes';

const initialState = {
  categories: undefined,
}

const CategoriesReducer = (state=initialState, action) => {
  switch(action.type) {
    case TYPES.FETCH_CATEGORIES_SUCCESS:
      return Object.assign({}, state, {
        categories: Object.assign({}, state.categories, action.categories)
      });
    default:
      return state;
  }
}

export default CategoriesReducer;
