import {put, takeLatest,call,retry} from 'redux-saga/effects';
import appUrl from '../../constants/AppUrl';
import axios from 'axios';
import {defaultConfig} from '../../config/Axios';
import ACTIONS from './OrganisationActionTypes';


function* retrySaga({apiRequest,data, successType, failType, error}) {
    // if internet was disconnected
    if(error.message === 'Network Error') {
        try {
            const {noOfAttempts,interval} = defaultConfig.retry;
            // Retry api in every 1 second, till 20 attempts
            let organisation = yield retry(noOfAttempts, interval, apiRequest, data);
            if (organisation.data.status) {
                yield put({type: failType, payload: organisation.data})
            }else{
                yield put({type: successType, payload: organisation.data})
            }
        } catch (error) {
            yield put({type: failType, payload: defaultConfig.exception})
        }
    }else{
        console.log(error)
        yield put({type: failType, payload: defaultConfig.exception})
    }
}

const fetchAPI = (params) => axios({
    method: 'GET',
    url:appUrl.ORGANISATION_URL,
    params
})

function* fetchOrganisation (action){
    try {
        let organisation = yield call(fetchAPI,action.payload);
        if(organisation.data.meta.status) {
            yield put({type: ACTIONS.FETCH_SUCCESS, payload: organisation.data});
        }else{
            // response may be like invaid token.
            yield put({type: ACTIONS.FETCH_FAIL, payload: organisation.data});
        }
    } catch (error) {
        yield call(retrySaga,{
            apiRequest:fetchAPI,
            data:action.payload,
            successType:ACTIONS.FETCH_SUCCESS,
            failType:ACTIONS.FETCH_FAIL,
            error
        });
    }
}

//  **** START POINT - ACTION WATCHER  ***
export default function* organisationSaga(){
    yield takeLatest(ACTIONS.FETCH_REQUEST, fetchOrganisation);
}