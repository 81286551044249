import {put, takeLatest,call,retry} from 'redux-saga/effects';
import appUrl from '../../constants/AppUrl';
import axios from 'axios/index';
import ACTIONS from './ProfileActionTypes';
import {defaultConfig} from '../../config/Axios';
import jwtDecode from 'jwt-decode';

function* retrySaga({apiRequest,data, successType, failType, error}) {
    if(error.message === 'Network Error') {
        try {
            const {noOfAttempts,interval} = defaultConfig.retry;
            // Retry api in every 1 second, till 20 attempts
            let users = yield retry(noOfAttempts, interval, apiRequest, data);
            if (users.data.status) {
                yield put({type: failType, payload: users.data})
            }else{
                yield put({type: successType, payload: users.data})
            }
        } catch (error) {
            yield put({type: failType, payload: defaultConfig.exception})
        }
    }else{
        console.log(error)
        yield put({type: failType, payload: defaultConfig.exception})
    }
}

const fetchAPI = () => axios({
    method: 'GET',
    url:appUrl.PEOPLE_URL + `/${jwtDecode(localStorage.getItem('jwt')).user.id}`,
})

const updateAPI = ({data}) => axios({
    method:'PUT',
    url: appUrl.PEOPLE_URL + `/${jwtDecode(localStorage.getItem('jwt')).user.id}`,
    data: {data: {attributes: data}}
})

function* fetchUser (action){
    try {
        let users = yield call(fetchAPI);
        if(users.data.status) {
            yield put({type: ACTIONS.FETCH_FAIL, payload: users.data});
        }else{
            yield put({type: ACTIONS.FETCH_SUCCESS, payload: users.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: fetchAPI,
            data: action.payload,
            successType: ACTIONS.FETCH_SUCCESS,
            failType: ACTIONS.FETCH_FAIL,
            error
        })
    }
}

function* updateUser (action){
    try {
        const user = yield call(updateAPI, action.payload);
        if(user.data.status) {
            yield put({type: ACTIONS.UPDATE_FAIL, payload: user.data});
        }else{
            yield put({type: ACTIONS.UPDATE_SUCCESS, payload: user.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: updateAPI, data: action.payload,
            successType: ACTIONS.UPDATE_SUCCESS,
            failType: ACTIONS.UPDATE_FAIL,
            error
        })
    }
}

// ACTION WATCHER
export default function* profileSaga(){
    // user can't make CRUD operations until data is fetched
    yield takeLatest(ACTIONS.FETCH_REQUEST, fetchUser)
    yield takeLatest(ACTIONS.UPDATE_REQUEST, updateUser);
}
