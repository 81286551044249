const ACTIONS = {
    FETCH_REQUEST: 'FETCH_RECORD_REQUEST',
    FETCH_SUCCESS: 'FETCH_RECORD_SUCCESS',
    FETCH_FAIL: 'FETCH_RECORD_FAIL',
    FETCH_ONE_REQUEST: 'FETCH_ONE_RECORD_REQUEST',
    FETCH_ONE_SUCCESS: 'FETCH_ONE_RECORD_SUCCESS',
    FETCH_ONE_FAIL: 'FETCH_ONE_RECORD_FAIL',
    FETCH_MORE_REQUEST: 'FETCH_MORE_RECORD_REQUEST',
    FETCH_MORE_SUCCESS: 'FETCH_MORE_RECORD_SUCCESS',
    FETCH_MORE_FAIL: 'FETCH_MORE_RECORD_FAIL',
    CREATE_REQUEST: 'CREATE_RECORD_REQUEST',
    CREATE_SUCCESS: 'CREATE_RECORD_SUCCESS',
    CREATE_FAIL: 'CREATE_RECORD_FAIL',
    UPDATE_REQUEST: 'UPDATE_RECORD_REQUEST',
    UPDATE_SUCCESS: 'UPDATE_RECORD_SUCCESS',
    UPDATE_FAIL: 'UPDATE_RECORD_FAIL',
    DELETE_REQUEST: 'DELETE_RECORD_REQUEST',
    DELETE_SUCCESS: 'DELETE_RECORD_SUCCESS',
    DELETE_FAIL: 'DELETE_RECORD_FAIL',
}

 export default ACTIONS;