import { put, takeLatest, takeLeading, fork, call } from 'redux-saga/effects';
import { message } from 'antd';
import axios from 'axios';
import appUrl from '../../constants/AppUrl';
import TYPES from './DatasetActionTypes';
import {
  fetchDatasetFailed,
  fetchDatasetSuccess,
  fetchDatasetsFailed,
  fetchDatasetsSuccess,
  createNewDatasetFailed,
    createNewDatasetSuccess,
  updateDatasetFailed,
    updateDatasetSuccess,
  deleteDatasetSuccess,
} from './DatasetActions';

const fetchDatasetRequest = (id) => {
  return axios({
    method: 'GET',
    url: `${appUrl.DATASET_URL}/${id}`,
  })
}

function* fetchDataset(action) {
  try {
    const response = yield call(fetchDatasetRequest, action.id);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading dataset.');
      yield put(fetchDatasetFailed(response.data))
    } else {
      yield put(fetchDatasetSuccess(response.data.data));
    }
  } catch(error) {
    message.error('Error loading dataset.');
    console.log(error);
  }
}

const fetchDatasetsRequest = (action) => {
  return axios({
    method: 'GET',
    url: appUrl.DATASET_URL,
    params: {
      limit: action.limit || 30
    }
  });
}

function* fetchDatasets(action) {
  try {
    const response = yield call(fetchDatasetsRequest, action);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading datasets.');
      yield put(fetchDatasetsFailed(response.data))
    } else {
      // const projects = response.data.data;
      // yield put(fetchDatasetsSuccess(projects.reduce((result, project) => {
      //   result[project.id] = project;
      //   return result;
      // }, {})));
      yield put(fetchDatasetsSuccess(response.data))
    }
  } catch(error) {
    message.error('Error loading datasets.');
    console.log(error);
  }
}

function* fetchAllDatasets() {
  try {
    const response = yield call(fetchDatasetsRequest, {limit: 50});
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error loading dataset.');
      yield put(fetchDatasetsFailed(response.data))
    } else {
      const projects = response.data.data;
      yield put(fetchDatasetsSuccess(projects.reduce((result, project) => {
        result[project.id] = project;
        return result;
      }, {})));
      // yield put(fetchDatasetsSuccess(response.data))
    }
  } catch(error) {
    message.error('Error loading dataset.');
    console.log(error);
  }
}

const updateDatasetRequest = (dataset) => {
  return axios({
    method: 'PUT',
    url: `${appUrl.DATASET_URL}/${dataset.id}`,
    data:  dataset
  });
}

function* updateDataset(action) {
  try {
    console.log(action.dataset)
    const response = yield call(updateDatasetRequest, action.dataset);
    console.log(response)
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error updating dataset.');
      yield put(updateDatasetFailed(response.data))
    } else {
      yield put(updateDatasetSuccess(response.data.data));
    }
  } catch(error) {
    message.error('Error updating dataset.');
    console.log(error);
  }
}

const createNewDatasetRequest = (data) => {
  return axios({
    method: 'POST',
    url: appUrl.DATASET_URL,
    data
  })
}

function* createNewDataset(action) {
  try {
    const response = yield call(createNewDatasetRequest, action.payload);
    if (!response.data.meta || response.data.meta.status !== 200) {
      message.error('Error creating dataset.');
      yield put(createNewDatasetFailed(response.data))
    } else {
      message.success('Dataset created!!');
      yield put(createNewDatasetSuccess(response.data));
      // const project = response.data.data;
      // yield put(fetchDatasetsSuccess({[project.id]: project}));
      // yield put(fetchDatasetSuccess(project.id));
    }
  } catch(error) {
    message.error('Error creating new project.');
  }
}

const deleteDatasetRequest = (id) => {
  return axios({
    method: 'DELETE',
    url: `${appUrl.DATASET_URL}/${id}`
  })
}

function* deleteDataset(action) {
  const response = yield call(deleteDatasetRequest, action.id);
  if (!response.data.meta || response.data.meta.status !== 200) {
    message.error('Dataset could not be deleted, please try again later.');
  } else {
    message.success('Dataset successfully deleted!');
    yield put(deleteDatasetSuccess());
  }
}

function* watchDataset() {
  yield takeLeading(TYPES.CREATE_NEW_DATASET, createNewDataset)
  yield takeLeading(TYPES.DELETE_DATASET, deleteDataset)
  yield takeLeading(TYPES.FETCH_DATASETS, fetchDatasets)
  yield takeLeading(TYPES.FETCH_ALL_DATASETS, fetchAllDatasets)
  yield takeLatest(TYPES.FETCH_DATASET, fetchDataset)
  yield takeLatest(TYPES.UPDATE_DATASET, updateDataset)

}

export default function* datasetSagas() {
  yield fork(watchDataset);
}
