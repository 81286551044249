import React from 'react'
import { Tag, Input, Icon, Popover, List, Card } from 'antd';

class LaneHeader extends React.Component {

  state = {
    name: this.props.name,
    editNameMode: false
  }

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.state.editNameMode && this.state.editNameMode !== prevProps.editNameMode) {
      this.inputRef.current.focus();
    }
  }

  updateName = () => {
    this.setState({
      editNameMode: false
    });
    this.props.updateName(this.state.name)
  }

  editName = () => {
    this.setState({
      editNameMode: true
    });
  }

  changeName = (e) => {
    this.setState({
      name: e.target.value
    });
  }

  onKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.updateName();
    }
  }

  renderSettings = () => {
    return (
      <List
        header={null}
        footer={null}
        bordered={false}
        dataSource={[{
          key: "delete",
          text: "Delete Column"
        }]}
        renderItem={item => (
          <List.Item key={item.key} style={{padding: 0}}>
            <a onClick={this.props.onDelete}>{item.text}</a>
          </List.Item>
        )}
      />
    );
  }

  render() {
    const { editNameMode, name } = this.state;
    const { segments=[], color, dragHandleSelector } = this.props;
    return (
      <div
        className={dragHandleSelector}
        style={{top: 0, position: "sticky", width: "100%", zIndex: 1, background: "#f8fafb", paddingBottom: 10}}
      >
        <div style={{display: "flex", cursor: "pointer"}}>
          {!editNameMode && <span
            onClick={this.editName}
            style={{fontWeight: 600, fontSize: 16, flexGrow: 1, width: "70%", wordWrap: "break-word"}}
          >
              {name}
          </span>}
          {editNameMode && <Input
            ref={this.inputRef}
            style={{marginRight: 10}}
            size="small"
            maxLength="50"
            value={name}
            onChange={this.changeName}
            onKeyDown={this.onKeyDown}
            onBlur={this.updateName}
          />}
          <Tag
            color={color.colorLight || "rgba(46, 91, 255, 0.15)"}
            style={{color: color.colorDark || "#2E5BFF", width: 40, textAlign: "center", height: "100%", marginLeft: 5}}
          >
              {segments.reduce((result, segment) => result + segment.tasks.length, 0)}
          </Tag>
          <Popover placement="bottomLeft" title={null} content={this.renderSettings()} trigger="click">
            <div style={{justifyContent: "center", alignItems: "center", display: "flex", cursor: "pointer"}}>
              <Icon type="more" />
            </div>
          </Popover>
        </div>
        <Card
          bordered={false}
          style={{
            marginBottom: 8,
            borderLeft: `solid 2px ${color.colorDark || "#2E5BFF"}`,
            padding: 4,
            marginTop: 8,
            cursor: "pointer",
          }}
          className="task"
          bodyStyle={{padding: 0, textAlign: "center", fontWeight: 600,}}
          onClick={this.props.showAddTask}
        >
            +
        </Card>
      </div>
    );
  }
}

export default LaneHeader;
