import { takeEvery, takeLeading, fork, put, call } from 'redux-saga/effects';
import axios from 'axios';
import appUrl from '../../constants/AppUrl';
import TYPES from './AttachmentsActionTypes';
import {
  fetchAttachmentsSuccess,
  deleteAttachmentSucess,
} from './AttachmentsActions';
import {showNotification} from '../../utils/commonFunctions'

const fetchAttachmentsRequest = (params) => {
	return axios({
		method: 'GET',
		url: `${appUrl.ATTACHMENTS}`,
		params: Object.assign({}, params),
	});
}

function* fetchAttachments(action) {
  try {
    const response = yield call(fetchAttachmentsRequest, action.params);

    if (!response.data.meta || response.data.meta.status !== 200) {
      showNotification('error','Error loading attachments');
    } else {
      const attachments = response.data.data;
      yield put(fetchAttachmentsSuccess({
        [action.params.model_id]: attachments.map(attachment => attachment._id),
        ...attachments.reduce((result, attachment) => {
          result[attachment._id] = attachment;
          return result;
        }, {})
      }));
    }
  } catch(error) {
    showNotification('error','Error loading attachments');
    console.log(error);
  }
}

const deleteAttachmentRequest = (id) => {
	return axios({
		method: 'DELETE',
		url: `${appUrl.ATTACHMENTS}/${id}`,
	});
}

function* deleteAttachment(action) {
  try {
    yield put(deleteAttachmentSucess(action.attachment));
    const response = yield call(deleteAttachmentRequest, action.attachment._id);
    if (!response.data.meta || response.data.meta.status !== 200) {
      showNotification('error','Error deleting attachment');
    }
  } catch(error) {
    showNotification('error','Error deleting attachment');
    console.log(error);
  }
}

function* watchAttachments() {
  yield takeEvery(TYPES.FETCH_ATTACHMENTS, fetchAttachments);
  yield takeEvery(TYPES.DELETE_ATTACHMENT, deleteAttachment);
}

export default function* attachmentsSaga(){
  yield fork(watchAttachments);
}
