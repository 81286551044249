import TYPES from "./CategoriesActionTypes";

const fetchCategories = () => {
  return {
    type: TYPES.FETCH_CATEGORIES
  };
}

const fetchCategoriesSuccess = (categories) => {
  return {
    type: TYPES.FETCH_CATEGORIES_SUCCESS,
    categories
  };
}

export {
  fetchCategories,
  fetchCategoriesSuccess,
};
