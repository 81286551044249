import React, { Component } from 'react'
import CreateNewModal from '../../utils/createNewModal'
import FieldTypes from './fieldTypes'
import {updateFieldRequest} from '../../fields/duck/FieldActions'
import {showNotification} from '../../utils/commonFunctions'

export default class CreateFieldModal extends Component {

    state = {
        fieldName   : '',
        helpText    : '',
        selectedType: [],
        options     : [],
        acceptedFileFormat : []
    }

    componentDidUpdate(prevProps){
        if(this.props.field !== prevProps.field){
            let field = this.props.field && this.props.field.attributes
            this.setState({
                fieldName : field.name,
                helpText : field.help_text,
                selectedType : field.type,
                options: field.options
            })
        }
    }

    handleSubmit = (fieldName = this.state.fieldName, helpText = this.state.helpText, selectedType = this.state.selectedType) =>{
        if(fieldName.trim() === ''){
            showNotification('error', 'Field name cannot be blank')
            return false
        }
        // if(selectedType.length  === 0){
        //     showNotification('error', 'Please select field data type')
        //     return false
        // }
        if(fieldName !== ''){
            let data = {
                name        : fieldName,
                help_text   : helpText,
                // type        : selectedType.toString().toLowerCase(),
                options     : selectedType.toString().toLowerCase() === 'file' ? this.state.acceptedFileFormat : this.state.options
            }
            updateFieldRequest(this.props.field.id, data);
            // this.setState({fieldName:'', helpText:'', selectedType : []})
            showNotification('success','Field updated successfully')
            this.props.toggleModal(false)
        }else{
            showNotification('error','Please fill all the details')
        }
    }

    handleName = (val) =>{
        this.setState({fieldName:val})
    }
    
    handleHelpText = (val) =>{
        this.setState({helpText:val})
    }

    selectType = (type) => {
        this.setState({selectedType:type})
    }

    handleOptions = (val) => {
        this.setState({options:val})
    }

    handleFileFormat = (val) => {
        this.setState({acceptedFileFormat: val})
    }

    render() {
        return (
            <CreateNewModal 
                title       = 'Update Field' 
                onSubmit    = {this.handleSubmit}
                modalVisible= {this.props.showModal}
                toggleModal = {this.props.toggleModal}
                btnText     = 'Update'
            >
                <FieldTypes 
                    handleName      = {this.handleName}
                    handleHelpText  = {this.handleHelpText}
                    handleOptions   = {this.handleOptions}
                    selectType      = {this.selectType}
                    fieldName       = {this.state.fieldName}
                    helpText        = {this.state.helpText}
                    selectedType    = {this.state.selectedType}
                    options         = {this.state.options}
                    acceptedFileFormat = {this.state.acceptedFileFormat}
                    handleFileFormat = {this.handleFileFormat}
                />
            </CreateNewModal>
        )
    }
}
