import {put, takeLatest,call,retry, takeEvery} from 'redux-saga/effects';
import appUrl from '../../constants/AppUrl';
import axios from 'axios';
import {defaultConfig} from '../../config/Axios';
import ACTIONS from './RecordActionTypes';
import {showNotification} from '../../utils/commonFunctions'

function* retrySaga({apiRequest,data, successType, failType, error}) {
    // https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    if(error.message === 'Network Error') {
        // if internet was disconnected
        try {
            const {noOfAttempts,interval} = defaultConfig.retry;
            // Retry api in every 1 second, till 20 attempts
            let record = yield retry(noOfAttempts, interval, apiRequest, data);
            if (record.data.status) {
                yield put({type: failType, payload: record.data})
            }else{
                yield put({type: successType, payload: record.data})
            }
        } catch (error) {
            yield put({type: failType, payload: defaultConfig.exception})
        }
    }else{
        console.log(error)
        yield put({type: failType, payload: defaultConfig.exception})
    }
}

const fetchAPI = (params) => axios({
    method: 'GET',
    url:appUrl.RECORD_URL,
    params
})

const createAPI = (data) => axios({
    method:'POST',
    url: appUrl.RECORD_URL,
    data: {data: data}
})

const updateAPI = ({id,data}) => axios({
    method:'PUT',
    url: appUrl.RECORD_URL + `/${id}`,
    data: {data: data}
})

const deleteAPI = (id) => axios({
    method: 'DELETE',
    url: appUrl.RECORD_URL+'/'+id,
})

function* fetchRecord (action){
    try {
        let record = yield call(fetchAPI,action.payload);
        record.data.dataset_id = action.payload && action.payload.id;

        if(record.data.status) {
            // response may be like invaid token.
            yield put({type: ACTIONS.FETCH_FAIL, payload: record.data});
        }else{
            yield put({type: ACTIONS.FETCH_SUCCESS, payload: record.data});
        }
    } catch (error) {
        yield call(retrySaga,{
            apiRequest:fetchAPI,
            data:action.payload,
            successType:ACTIONS.FETCH_SUCCESS,
            failType:ACTIONS.FETCH_FAIL,
            error
        });
    }
}

function* fetchMoreRecord (action) {
    try {
        let record = yield call(fetchAPI, action.payload);
        if(record.data.status) {
            yield put({type: ACTIONS.FETCH_MORE_FAIL, payload: record.data});
        }else{
            yield put({type: ACTIONS.FETCH_MORE_SUCCESS, payload: record.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: fetchAPI,
            data: action.payload,
            successType: ACTIONS.FETCH_MORE_SUCCESS,
            failType: ACTIONS.FETCH_MORE_FAIL,
            error
        })
    }
}

function* deleteRecord (action) {
    try {
        let record = yield call(deleteAPI, action.payload);
        record.data.record_ids = action.payload;
        if(record.data.status) {
            showNotification('error',record.data.message || record.data.meta.message);
            yield put({type: ACTIONS.DELETE_FAIL, payload: record.data});
        }else{
            showNotification('success','Record deleted successfully');
            yield put({type: ACTIONS.DELETE_SUCCESS, payload: record.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: deleteAPI,
            data: action.payload,
            successType: ACTIONS.DELETE_SUCCESS,
            failType: ACTIONS.DELETE_FAIL,
            error
        })
    }
}

function* updateRecord (action) {
    try {
        let record = yield call(updateAPI, action.payload);
        record.data.record_id = action.payload.id;
        if(record.data.status) {
            showNotification('error',record.data.message || record.data.meta.message);
            yield put({type: ACTIONS.UPDATE_FAIL, payload: record.data});
        }else{
           showNotification('success','Record updated successfully');
            yield put({type: ACTIONS.UPDATE_SUCCESS, payload: record.data});
        }
    } catch (error) {
        yield call(retrySaga, {
            apiRequest: updateAPI, data: action.payload,
            successType: ACTIONS.UPDATE_SUCCESS,
            failType: ACTIONS.UPDATE_FAIL,
            error
        })
    }
}

function* createRecord (action) {
    try {
        let record = yield call(createAPI, action.payload);
        record.data.dataset_id = action.payload.dataset_id;
        // wait until api is finished
        // Then check if there is some problem in record's data, like record exists,
        if(record.data.status) {
            showNotification('error',record.data.message || record.data.meta.message);
            yield put({type: ACTIONS.CREATE_FAIL, payload: record.data});
        } else{
            showNotification('success','Record created successfully');
            yield put({type: ACTIONS.CREATE_SUCCESS, payload: record.data});
        }
    } catch (error) {
        // if there is any error, check if the error is due to disconnected net.
        yield call(retrySaga, {
            apiRequest: createAPI, data: action.payload,
            successType: ACTIONS.CREATE_SUCCESS,
            failType: ACTIONS.CREATE_FAIL,
            error
        })
    }
}

//  **** START POINT - ACTION WATCHER  ***
export default function* recordSaga(){
    yield takeEvery(ACTIONS.FETCH_REQUEST, fetchRecord);
    // yield takeLatest(ACTIONS.FETCH_ONE_REQUEST, fetchOneRecord);
    yield takeLatest(ACTIONS.FETCH_MORE_REQUEST, fetchMoreRecord);
    yield takeLatest(ACTIONS.CREATE_REQUEST, createRecord);
    yield takeLatest(ACTIONS.DELETE_REQUEST, deleteRecord);
    yield takeLatest(ACTIONS.UPDATE_REQUEST, updateRecord);
}