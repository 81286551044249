import React, { Component } from 'react'
import {Row, Input, Icon, Popconfirm, Avatar} from 'antd'
import $ from "jquery";
// import Draggable, {DraggableCore} from 'react-draggable';

export default class Step extends Component {

    state = {
        stepName : this.props.step.step_name,
    }

    saveText = (e) =>{
        this.setState({stepName:e.target.value})
    }

	removeLine = () => {
		if($('polyline:last').attr('connectors') === undefined) $('polyline:last').remove();
	}

    render() {
		let {step, users, teams} = this.props;
		let showText = false
		if(step.start){
			if(step.assignment.teams.length > 0 || (step.assignment.people.length > 0 || step.assignment.people === 'all')){
				showText = true
			}
		}
		return (
			<Row className={step.start ? 'wfnode initiator' : 'wfnode' } type='flex' align='middle' justify='center' id={step.id} style={{top:step.position.top, left: step.position.left}}>
				{
					!step.start &&
						<Popconfirm
							title		= "Are you sure delete this step?"
							onConfirm	= {e=>this.props.removeStep(step.id)}
							okText		= "Yes"
							cancelText	= "No"
							placement	= "right"
						>
							<Icon type='delete' className='delete' />
						</Popconfirm>
				}
				<Icon type='edit' className='delete' onClick={e=>this.props.editStep(step.id)} style={{right:step.start ? '10px' : '35px'}}/>
				<Input
					style        = {{border:0, boxShadow:'none',textAlign:'center',textShadow:'0 0'}}
					placeholder  = {step.start ? 'Whats the first step?' : 'Enter the step name'}
					autoFocus    = {step.step_name === '' ? true : false}
					value        = {this.state.stepName}
					onChange     = {this.saveText}
					onPressEnter = {e=>this.props.updateStepName(e,step.id)}
					onBlur       = {e=>this.props.updateStepName(e,step.id)}
				/>
				{
					step.start &&
						<Row type='flex' align='middle' className='pointer' style={{color:'#B4BBC4',fontSize:'12px'}} onClick={e=>this.props.toggleModal(true)}>
							{
								showText
								?
									<Row type='flex' justify='center' align='middle' className='mb-20' style={{fontSize:'12px', color:'#7C8898'}}>
										{
											step.assignment.people === 'all' || step.assignment.people.length > 0
											?
												step.assignment.people === 'all'
												?
													<>
														<Avatar size={30} style={{background:'gold'}} icon='team' className='mr-10' />
														<span>Everyone</span>
													</>
												:
													<>
														<Avatar size={30} style={{background:'gold'}} icon='user' className='mr-10' />
														<span>
															{users && users.find(u=>u.id === step.assignment.people[0]).attributes.name}
															{
																(step.assignment.people.length > 1 || step.assignment.teams.length > 0) &&
																	<span>
																		&nbsp;and {(step.assignment.people.length + step.assignment.teams.length)-1} others
																	</span>
															}
														</span>
													</>
											:
												<>
													<Avatar size={30} style={{background:'gold'}} icon='team' className='mr-10' />
													<span>
														{teams && teams.find(t=>t.id === step.assignment.teams[0]).name}
														{
															step.assignment.teams.length > 1 &&
																<span>
																	&nbsp;and {(step.assignment.teams.length)-1} others
																</span>
														}
													</span>
												</>
										}
									</Row>
								:
									<>
										<i>Who can start this process</i> &nbsp;
										<Icon type="question-circle" theme="twoTone" className='f-16' />
									</>
							}
						</Row>							
				}
				<Row onMouseDown={this.props.handleAnchorPoints} onMouseUp={this.removeLine} type='flex' justify='center' align='middle' className='anchor-points'>
					<Icon type='arrow-down' />
				</Row>
			</Row>
        )
    }
}