import React from 'react';
import {
    Row,
    Button,
    // Input,
    // Select,
    // Col,
    Modal
} from 'antd';
import {showNotification} from "../../utils/commonFunctions";
import FormFields from '../form/formFields'

// const { TextArea } = Input;
// let inputData = {};
// let count = 0;

class viewRecord extends React.Component {
    // setup state
    state = {
        activeDataset: null,
        loading: false,
        inputData: {},
        count: 0,
        mandatoryField: [],
        showErrorAlert: false,
    }

    componentDidMount() {
        let { data } = this.props;
        this.setState({ activeDataset: data });
    }

    componentDidUpdate(prevProps) {
        let { data, fetchAttachments } = this.props;
        if ((!prevProps.data && data && data.id) || (prevProps.data && data && data.id && prevProps.data.id !== data.id)) {
            this.setState({ activeDataset: data });
            // console.log('idsds',data, data.id)
            // fetchAttachments && fetchAttachments({ model_id: data.id });
        }
    }

    toggleErrorAlert = (flag) => {
        this.setState({showErrorAlert: flag})
    }

    updateMandatoryFields = (fields) => {
        this.setState({mandatoryField : fields})
    }

    updateRecord = () => {
        let { inputData } = this.state;
        inputData.fields && Object.keys(inputData.fields).map(key => {
            inputData.fields[key] = {
                value: inputData.fields[key].value && inputData.fields[key].value.hasOwnProperty("row_id") ? inputData.fields[key].value : {row_id: inputData.fields[key].value}
            }
        });
        // if(inputData.name === undefined || ''){
            let {data} = this.props;
            inputData.name = data && data.name;
        // }
        this.setState({
            inputData: inputData,
            // loading: true,
        });
        // setTimeout(()=>{
            if (this.state.mandatoryField.length > 0) {
                this.toggleErrorAlert(true);
                showNotification("error", "Please fill all mandatory field");
            } else {
                this.toggleErrorAlert(false);
                this.props.update(this.props.data.id, inputData);
                // this.setState({loading: false});
            }

        // }, 2000)
    };

    setFieldValue = (info) => {
        let { inputData } = this.state;
        inputData = JSON.parse(JSON.stringify(inputData));
        inputData.fields = Object.assign({}, inputData.fields, info.fields);
        console.log("setFieldValue");

        this.setState({
            inputData: inputData,
            showErrorAlert: false,
        });
    };

    close = () => {
        let { count } = this.state;
        let inputData = {
            name: undefined,
            descriptions: undefined,
            members: []
        };
        count = count - 1;
        this.setState({
            inputData,
            count,
            showErrorAlert: false,
         });
        this.props.onCloseModel();
    };

    render() {
        let { inputData, count, mandatoryField, showErrorAlert } = this.state;
        let { data, attachments } = this.props;
        let mAttachment = [];
        if (data && data.id && attachments && Array.isArray(attachments[data.id])) {
            mAttachment = attachments[data.id].map(id => attachments[id]);
        }

        return <Modal
            visible={this.props.visible}
            footer={null}
            key={count}
            width={'60%'}
            bodyStyle={{ height: '600px' }}
            closable={false}
        >
            <Row type='flex' justify='space-between' key={count}>
                <span style={{ color: '#44566C', fontSize: '30px', fontWeight: 'bold' }}>Update Record</span>
                <Button onClick={() => this.close()} shape="circle" icon="close" style={{ color: '#44566C', backgroundColor: '#F8FAFB', fontSize: '17px' }} />
            </Row>
            {/*<Col style={{marginTop: '20px'}}>*/}
            {/*    <span style={{color: '#44566C'}}>Record Name</span>*/}
            {/*    <Input*/}
            {/*        onChange={(e)=>inputData.name = e.target.value}*/}
            {/*        className='custom-input'*/}
            {/*        defaultValue={data && data.name}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            {/*<Col style={{marginTop: '20px'}}>*/}
            {/*    <span style={{color: '#44566C'}}>Descriptions</span>*/}
            {/*    <TextArea*/}
            {/*        className='custom-input'*/}
            {/*        defaultValue={data && data.description}*/}
            {/*        onChange={(e)=>inputData.description = e.target.value}*/}
            {/*        style={{backgroundColor: '#F8FAFB', marginTop: '5px'}}*/}
            {/*    />*/}
            {/*</Col>*/}
            {data &&
                <Row className='mt-20' style={{overflow:'auto', height:'469px'}}>
                    <FormFields
                        sections={this.props.dataSet.attributes.sections}
                        fields={this.props.dataSet.attributes.fields}
                        activeDataset={data}
                        inputData={inputData}
                        updateRecordFields={this.setFieldValue}
                        attachments={mAttachment}
                        deleteAttachment = {this.props.deleteAttachment}
                        mandatoryField={mandatoryField}
                        showErrorAlert={showErrorAlert}
                        updateMandatoryFields={this.updateMandatoryFields}
                        fetchAttachments={this.props.fetchAttachments}
                    />
                </Row>

            }
            <Row style={{ position: 'absolute', bottom: 0, right: 0, height: 50, width: '100%', background: '#F8FAFB' }}>
                <Row style={{ position: 'absolute', bottom: 10, right: 10 }}>
                    <Button onClick={this.close} style={{ marginRight: '20px' }} >Cancel</Button>
                    <Button loading={this.state.loading} onClick={this.updateRecord} type="primary">Update</Button>
                </Row>
            </Row>
        </Modal>
    }
}
export default viewRecord;
