import React, { Component } from 'react'
import {Select} from 'antd'

export default class Dropdown extends Component {

    state = {
        dropdownValue : (this.props.activeTask && this.props.activeTask.fields && this.props.activeTask.fields[this.props.field.id]) ? this.props.activeTask.fields[this.props.field.id].value : []
    }

    componentDidUpdate(prevProps){
        let {activeTask, field} = this.props
        if(activeTask && activeTask.fields && activeTask.fields[field.id]){
            if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
                this.setState({dropdownValue: activeTask.fields[field.id].value})
            }
        }
    }

    handleDropdown = (options, fieldId) =>{
        this.setState({dropdownValue: options})
        this.props.addValue(fieldId, options)
    }


    render() {
        let {field, showErrorAlert, showError} = this.props
        // console.log(this.props.activeTask)
        return (
            <Select
                getPopupContainer={(e)=>e.parentNode}
                allowClear
                mode        = {field.multi_entry ? 'multiple' : 'single'}
                className   = {showErrorAlert && showError ? 'error custom-select fw' : 'custom-select fw'}
                size        = 'large'
                placeholder = {field.alias_help_text ? field.alias_help_text : field.helpText}
                value       = {this.state.dropdownValue}
                onChange    = {e=>this.handleDropdown(e, field.id)}
            >
                {
                    field.options && field.options.map(opt=>{
                        return <Select.Option key={opt} value={opt}>
                            {opt}
                        </Select.Option>
                    })
                }
            </Select>
        )
    }
}
