import TYPES from "./DatasetActionTypes";

const fetchDatasets = () => {
  return {
    type: TYPES.FETCH_DATASETS,
  };
}
const fetchDatasetsSuccess = (dataset) => {
  return {
    type: TYPES.FETCH_DATASETS_SUCCESS,
    dataset: dataset
  }
}
const fetchDatasetsFailed = (error) => {
  return {
    type: TYPES.FETCH_DATASETS_FAILED,
    error
  }
}

const fetchDataset = (id)  => {
  return {
    type: TYPES.FETCH_DATASET,
    id
  };
}
const fetchDatasetSuccess = (dataset) => {
  return {
    type: TYPES.FETCH_DATASET_SUCCESS,
    dataset
  }
}
const fetchDatasetFailed = (error) => {
  return {
    type: TYPES.FETCH_DATASET_FAILED,
    error
  }
}

const updateDataset = (dataset) => {
  return {
    type: TYPES.UPDATE_DATASET,
    dataset: dataset
  }
}
const updateDatasetSuccess = (dataset) => {
  return {
    type: TYPES.UPDATE_DATASET_SUCCESS,
    dataset: dataset
  }
}
const updateDatasetFailed = (error) => {
  return {
    type: TYPES.UPDATE_DATASET_FAILED,
    error,
  }
}

const createNewDataset = (payload) => {
  return {
    type: TYPES.CREATE_NEW_DATASET,
    payload
  }
}
const createNewDatasetSuccess = (payload) => {
  return {
    type: TYPES.CREATE_NEW_DATASET_SUCCESS,
    payload
  }
}
const createNewDatasetFailed = (payload) => {
  return {
    type: TYPES.CREATE_NEW_DATASET_FAILED,
    payload
  }
}

const deleteDataset = (id) => {
  return {
    type: TYPES.DELETE_DATASET,
    id
  };
}
const deleteDatasetSuccess = () => {
  return {
    type: TYPES.DELETE_DATASET_SUCCESS,
  };
}

const fetchAllDatasets = () => {
  return {
    type: TYPES.FETCH_ALL_DATASETS,
  };
}


export {
  fetchDataset,
  fetchDatasetFailed,
  fetchDatasetSuccess,
  fetchDatasets,
  fetchDatasetsFailed,
  fetchDatasetsSuccess,
  updateDataset,
  createNewDataset,
  createNewDatasetFailed,
  createNewDatasetSuccess,
  updateDatasetFailed,
  updateDatasetSuccess,
  deleteDataset,
  deleteDatasetSuccess,
  fetchAllDatasets,
};
