import React, { Component } from 'react'
import {Row, Col} from 'antd'
import Switch from './switch'

export default class Permission extends Component {

    render() {
        let {activeStep, sections, allFields, setFieldProperties} = this.props
        return (
            <Row>
                <Row style={{fontSize:'30px',color:'#44566C',fontWeight:'500'}} className='mt-20 mb-20'>
                    Which all fields will be visible in this step?
                </Row>
                {
                    sections.map(section=>(
                        <Row key={section.id} className='mt-10'>
                            <Row type='flex' align='middle' className='mb-20'>
                                <Col span={12}>
                                    <Row style={{textShadow:'0 0'}} className='f-16 text-color'>{section.section_name}</Row>
                                    <Row style={{fontSize:12, fontStyle:'italic'}}>On selecting section as  "Hide" all the fields inside it will be hidden.</Row>
                                </Col>
                                <Col span={12}>
                                    <Switch 
                                        activeStep          = {activeStep} 
                                        properties          = {activeStep.sections[section.id]} 
                                        id                  = {section.id} 
                                        isSection           = {true} 
                                        setFieldProperties  = {setFieldProperties}
                                    />
                                </Col>
                            </Row>
                            <Fields 
                                allFields           = {allFields}
                                sectionId           = {section.id} 
                                activeStep          = {activeStep}
                                sectionLayout       = {section.section_layout.sort((a, b) => a.y - b.y)}
                                setFieldProperties  = {setFieldProperties}
                            />
                        </Row>
                    ))
                }
            </Row>
        )
    }
}

const Fields = (props) => {
    let {allFields, activeStep, sectionLayout, setFieldProperties} = props
    // let sectionFields   = []
    // for(let key in allFields){
    //     if(allFields.hasOwnProperty(key)){
    //         if(allFields[key].section_id === sectionId){
    //             sectionFields.push(allFields[key])
    //         }
    //     }
    // }
    return(
        <>
            {
                sectionLayout.map(sec=>{
                    if(allFields[sec.i]){
                        let _disabled = activeStep && activeStep.sections && activeStep.sections[allFields[sec.i].section_id] && activeStep.sections[allFields[sec.i].section_id].show
                        return(
                            <Row className='mb-10' key={allFields[sec.i].id} type='flex' align='middle'>
                                <Col span={12} className='pl-20' style={{color:'#7C8898'}}>
                                    {allFields[sec.i].alias_name || allFields[sec.i].name}
                                    {allFields[sec.i].mandatory && <sup>*</sup>}
                                </Col>
                                <Col span={12} className={!_disabled ? 'disabled': ''}>
                                    <Switch 
                                        activeStep          = {activeStep} 
                                        properties          = {activeStep.fields[allFields[sec.i].id]} 
                                        id                  = {allFields[sec.i].id} 
                                        isSection           = {false} 
                                        setFieldProperties  = {setFieldProperties}
                                        disabled            = {!_disabled}
                                    />
                                </Col>
                            </Row>
                        )
                    }
                })
            }
        </>
    )
}
