// import { put } from 'redux-saga/effects';
import ACTIONS from "./ProcessActionTypes";
import {store} from '../../config/Store';

export function fetchProcessRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function fetchMoreProcessRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function createProcessRequest(processInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:processInfo})
}
export function updateProcessRequest(id, data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}
export function deleteProcessRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

