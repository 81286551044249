import React from 'react';
import { Select } from 'antd';
// const { Option } = Select;
const style = {
    margin:'12px 0'
}

const AggregateFields = (props) => {
    const onDeselect = (e) => {
        console.log('ee', e, e.key.split('&'))
        let key = e.key.split('&')
        props.onSelectAggregateOption({operation:key[1], key:key[0]},'DELETE')
    }

    let selectedAggregateFields;

    if( props.selectedAggregateFields) {
        // selectedAggregateFieldsOptions = props.selectedAggregateFields.map((field, index) => {
        //     return <Select.Option key={field.key} value={field.key}>{field.operation} Of {field.label}</Select.Option>
        // })

        selectedAggregateFields = props.selectedAggregateFields.map((field, index) => ({
            key: field.key+ '&' + field.operation,
            label: `${field.operation} Of ${field.label}`
        }))
    }

    return (
        <Select getPopupContainer={(e)=>e.parentNode} style={style} className='fw custom-select' value={selectedAggregateFields} mode="multiple" placeholder='No aggregate selected' labelInValue size='large'
                onDeselect={onDeselect}>
        </Select>
    );
};

export default AggregateFields;
