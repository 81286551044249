import React, { Component } from 'react'
import {Row, Button, Avatar,notification,Spin,message, Card, Checkbox, Select} from 'antd'
import accounting from '../images/accounting.png'
import { connect } from 'react-redux';
import ChartConfig from './chart/ChartConfig'
import PanelConfig from '../pageBuilder/panelConfig'
import GuageConfig from '../pageBuilder/guageConfig'
import FormConfig from '../pageBuilder/formConfig'
import ReportConfig from '../pageBuilder/reportConfig'
import ButtonConfig from '../pageBuilder/buttonConfig'
import {setActivePage} from './duck/PageActions'
import {updatePageRequest} from './duck/PageActions'
import {setActiveTab} from '../navigation/duck/PageNavigationActions'
import GridLayout from './gridLayout';
import axios from "axios";
import appUrl from "../constants/AppUrl";
import CreateNewModal from '../utils/createNewModal'

class CreatePageContainer extends Component {

    state = {
        pageData        : null,
        layout          : [],
        activePage      : null,
        loading         : false,
        showModal       : false,
        selectedUsers   : [],
        selectedTeams   : [],
        allUsersSelected: false,
        allTeamsSelected: false
    }

    componentDidMount() {
        let {activePage} = this.props.activePage;
        // console.log('activePage', activePage);

        let reportIds = null
        let selectedUsers, selectedTeams, allUsersSelected, allTeamsSelected;
        if(activePage){
            if (activePage.draw_data && activePage.draw_data.reportIds) {
                reportIds = activePage.draw_data.reportIds.join(',')
            }
            if(activePage.shared_with){
                allUsersSelected = !Array.isArray(activePage.shared_with.users)
                allTeamsSelected = !Array.isArray(activePage.shared_with.teams)
                selectedUsers    = Array.isArray(activePage.shared_with.users) ? activePage.shared_with.users : []
                selectedTeams    = Array.isArray(activePage.shared_with.teams) ? activePage.shared_with.teams : []
            }
        }
        if (reportIds) {
            this.setState({loading:true})
            const hide = message.loading('Fetching reports...', 0);
            axios({
                url: appUrl.REPORTS_CONSUME, //REPORTS_CONSUME
                method: 'GET',
                params: {ids: reportIds}
                // params: {reports: reportIds}
            }).then(res => {
                // console.log('REPORTS', res)
                // if(activePage.data){
                let pageData = {data: activePage, reports: res.data.data}; //activePage.data && activePage.data.data;
                // console.log('pageData', pageData);
                setTimeout(hide)
                message.success('All reports fetched successfully');
                this.setState({pageData,loading:false,selectedUsers, selectedTeams, allUsersSelected, allTeamsSelected})
                // }
            })
        } else {
            this.setState({pageData: {data: activePage},selectedUsers, selectedTeams, allUsersSelected, allTeamsSelected})
        }
    }


    cancel = () => {
        setActivePage(null); // to reset active page and get back to page list
        setActiveTab(null);
    }

    resetActiveTab = () => {
        setActiveTab(null); // to reset active tab on page creation side config panel
        this.setState({selectedGraph: null})
    }

    onUpdateChartData = (data, reportId) => {
        let {pageData} = this.state;
        pageData = JSON.parse(JSON.stringify(pageData))
        pageData.reports.forEach(report => {
            if (report.report.data.id === reportId) {
                report.report.data.chart = data.chart.key;
                report.report.data.query = data;
            }
        });
        this.setState({pageData});
    }

    onDeleteChartData = (e) => {
        console.log('this.state.layout', this.state.layout)
        let {pageData, layout} = this.state;
        pageData = JSON.parse(JSON.stringify(pageData));
        layout = JSON.parse(JSON.stringify(layout));
        pageData.reports = pageData.reports.filter(report => report.report.data.id !== e.currentTarget.id);
        layout = layout.filter(layout => layout.i !== e.currentTarget.id);
        this.setState({pageData, layout});
        this.resetActiveTab();

        // axios({
        //     url: appUrl.REPORTS + '/' + e.currentTarget.id, //REPORTS_CONSUME
        //     method: 'DELETE',
        // }).then(res => {
        //     console.log('DELETED REPORTS', res)
        //     let {pageData} = this.state;
        //     pageData = JSON.parse(JSON.stringify(pageData))
        //     pageData.reports = pageData.reports.filter(report => report.report.data.id !== res.data.data.id);
        //     this.setState({pageData});
        //     notification.open({
        //         message: '',//res.data.data,
        //         description:'Graph deleted'
        //     });
        // })
    }


    onSubmitChartData = (data, reportId) => {
        this.activeReport = {data, reportId};
        let {pageData} = this.state;
        let chartType = this.activeReport.data.chart.key;
        let reportData = {
            type: 'reports',
            id: this.activeReport.reportId,
            name: data.name,
            chart: chartType,
            query: this.activeReport.data
        };
        let report = {report: {data: reportData}, report_result: null};

        if (pageData.reports) {
            let reports = [...pageData.reports, report];
            pageData = {...pageData, reports}
        } else {
            pageData = {...pageData, reports: [report]}
        }

        if(pageData.data.draw_data && pageData.data.draw_data.reportIds){
            pageData.data.draw_data.reportIds = [...pageData.data.draw_data.reportIds, reportId]
        } else {
            pageData.data.draw_data = {reportIds:[reportId]}
        }
        console.log('final pageData', reportData)
        // this.setState({loading:true})
        // const hide = message.loading('Fetching reports...', 0);
        axios({
            url: appUrl.REPORTS_CONSUME, //REPORTS_CONSUME
            method: 'GET',
            params: {report: reportData.query}
            // params: {reports: reportIds}
        }).then(res => {
        //      console.log('REPORTS', res)
        //     // if(activePage.data){
        //     // let pageData = {data: activePage, reports: res.data.data}; //activePage.data && activePage.data.data;
        //     // console.log('pageData', pageData);
        //     setTimeout(hide)
        //     message.success('All reports fetched successfully');
        //     // this.setState({pageData,loading:false,selectedUsers, selectedTeams, allUsersSelected, allTeamsSelected})
        //     // }
        })
        this.activeReport = null;
        this.setState({pageData})
    }

    onUpdatePage = () => {
        console.log('activePage', this.props.activePage, this.state.pageData);
        let {pageData, selectedTeams, selectedUsers, allUsersSelected, allTeamsSelected}  = this.state;
        let reportIds   = [];
        if(pageData.reports){
            reportIds = pageData.reports.map(report => report.report.data.id);//[this.activeReport.reportId];
        }
        let attributes = pageData.data;
        this.resetActiveTab();
        let data = {
            // name            : name,
            // description     : description,
            thumb_location  : null,
            draft           : false,
            shared_with     : {
                teams : allTeamsSelected ? 'all' : selectedTeams,
                users : allUsersSelected ? 'all' : selectedUsers
            },
            category        : {},
            draw_data       : {
                reportIds,
                layout: this.state.layout
            }
        }

        updatePageRequest(pageData.data.id, data);
        this.toggleModal(false);
        notification.open({
            // message: name,
            description: 'Page updated successfully'
        });
    }

    changeLayout = (layout) => {
        this.setState({layout})
    }

    showRealData = () => {
        let reportIds = null
        let {pageData} = this.state;

        if (pageData.data.draw_data) {
            reportIds = pageData.data.draw_data.reportIds.join(',')
        }
        if(reportIds){
            axios({
                url: appUrl.REPORTS_CONSUME,
                method: 'GET',
                params: {ids: reportIds}
            }).then(res => {
                let _pageData = {data: pageData.data, reports: res.data.data}; //activePage.data && activePage.data.data;
                console.log('pageData', _pageData);
                this.setState({pageData: _pageData})
            })
        }
    }

    onSelectGraph = (e) => {
        let {pageData} = this.state;
        if (e.currentTarget.id) {
            let graphData = pageData.reports.find(report => report.report.data.id === e.currentTarget.id);
            this.setState({selectedGraph: graphData.report})
        }
    }

    selectUsers = (users) => {
        this.setState({selectedUsers:users})
        console.log(users);
    }

    selectTeams = (teams) => {
        this.setState({selectedTeams:teams})
        console.log(teams);
    }

    selectAllUsers = (e) => {
        this.setState({allUsersSelected:e.target.checked})
    }
    
    selectAllTeams = (e) => {
        this.setState({allTeamsSelected:e.target.checked})
    }

    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    render() {
        let {activeTab}     = this.props.activeTab;
        let {selectedGraph} = this.state;
        let img             = (this.state.pageData && this.state.pageData.reports) ? '' : accounting;
        let title           = {fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', color: '#44566C'}
        let desc            = {fontSize: '12px', lineHeight: '22px', color: '#8798A8'}
        let bkg             = {backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}
        let settingPanel    = {
            padding     : '20px',
            width       : '418px',
            background  : '#fff',
            zIndex      : '100',
            position    : 'fixed',
            height      : 'calc(100% - 64px)',
            left        : '220px',
            top         : '64px',
            // boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1), 0px 32px 32px rgba(169, 194, 209, 0.15)',
            boxShadow   : '5px 0 5px -5px lightgrey',
            overflow    : 'auto'
        }
        let activePage      = (this.props.activePage.activePage && this.props.activePage.activePage.id) ? this.props.activePage.activePage : null;
        let loading;
        if (activePage) {
            loading = this.props.activePage.loading
        }
        let users = (this.props.users && (this.props.users.data.data && this.props.users.data.data.length > 0)) ? this.props.users.data.data : []
        let teams = (this.props.teams && (this.props.teams.data.data && this.props.users.data.data.length > 0)) ? this.props.teams.data.data : []
        return (
                <Row className='fh' style={bkg}>
                    <Row type='flex' justify='space-between'>
                        <Row type='flex'>
                            <Avatar size={45} style={{backgroundColor: '#FDBF5E', borderRadius: '14px'}} icon="read"/>
                            <Row style={{marginLeft: '18px'}}>
                                <span style={title}>{activePage.name}</span>
                                <br/>
                                <span style={desc}>{activePage.description}</span>
                            </Row>
                        </Row>
                        <Row>
                            <Button type='danger' className='mr-10' onClick={this.cancel}>Cancel</Button>
                            <Button type='dashed' className='mr-10' disabled={!activePage.draw_data} onClick={this.showRealData}>Show Live Data</Button>
                            <Button className='mr-10'>Save Draft</Button>
                            <Button loading={loading} type='primary' onClick={e=>this.toggleModal(true)}>
                                Publish
                            </Button>
                            <CreateNewModal 
                                title       = 'Publish' 
                                onSubmit    = {this.onUpdatePage}
                                modalVisible= {this.state.showModal}
                                toggleModal = {this.toggleModal}
                                btnText     = 'Done'
                            >
                                <Card 
                                    title="Users" 
                                    headStyle={{fontWeight:'500',background:'#EAEDF0',fontSize:'15px',color:'#44566C',height:'36px',borderTopRightRadius:'5px',borderTopLeftRadius:'5px',lineHeight:'normal'}}
                                    bodyStyle={{padding:'11px 13px',border:'1px solid #E9EAEC'}}
                                    extra={
                                        <Checkbox className='f15' checked={this.state.allUsersSelected} onChange={this.selectAllUsers}>Select All</Checkbox>
                                    } 
                                    bordered={false}
                                >
                                    <Select
                                        getPopupContainer={(e)=>e.parentNode}
                                        mode        = "multiple"
                                        placeholder = "Enter User Names"
                                        className   = 'fw custom-select'
                                        size        = 'large'
                                        defaultValue= {this.state.selectedUsers}
                                        onChange    = {this.selectUsers}
                                        allowClear
                                        disabled    = {this.state.allUsersSelected}
                                    >
                                        {
                                            users.map(user=>{
                                                return <Select.Option key={user.id}>{user.name}</Select.Option>
                                            })

                                        }
                                    </Select>
                                </Card>
                                <Card
                                    style={{marginTop:'40px'}} 
                                    title="Teams" 
                                    headStyle={{background:'#EAEDF0',fontSize:'15px',color:'#44566C',height:'36px',borderTopRightRadius:'5px',borderTopLeftRadius:'5px',lineHeight:'normal'}}
                                    bodyStyle={{padding:'11px 13px',border:'1px solid #E9EAEC'}}
                                    extra={
                                        <Checkbox className='f15' checked={this.state.allTeamsSelected} onChange={this.selectAllTeams}>Select All</Checkbox>
                                    } 
                                    bordered={false}
                                >
                                    <Select
                                        getPopupContainer={(e)=>e.parentNode}
                                        mode        = "multiple"
                                        placeholder = "Enter Team Names"
                                        className   = 'fw custom-select'
                                        size        = 'large'
                                        defaultValue= {this.state.selectedTeams}
                                        onChange    = {this.selectTeams}
                                        allowClear
                                        disabled    = {this.state.allTeamsSelected}
                                    >
                                        {
                                            teams.map(team=>{
                                                return <Select.Option key={team.id}>{team.name}</Select.Option>
                                            })

                                        }
                                    </Select>
                                </Card>
                            </CreateNewModal>
                        </Row>
                    </Row>
                    <Row style={{marginTop: '30px'}}>
                        <Spin spinning={false} size='large' style={{minHeight:'250px'}}>
                            <GridLayout
                                onSelectGraph={this.onSelectGraph}
                                onDeleteChartData={this.onDeleteChartData}
                                pageData={this.state.pageData}
                                setActiveTab={setActiveTab}
                                activePage={activePage}
                                changeLayout={this.changeLayout}
                            />
                        </Spin>
                    </Row>
                    {
                        (activeTab) &&
                        <Row style={settingPanel}>
                            {activeTab === 'Panel' && <PanelConfig />}
                            {
                                activeTab === 'Chart' &&
                                <ChartConfig
                                    selectedGraph={selectedGraph}
                                    onUpdateChartData={this.onUpdateChartData}
                                    onSubmitChartData={this.onSubmitChartData}
                                    resetActiveTab={this.resetActiveTab}
                                />
                            }
                            {activeTab === 'Guage' && <GuageConfig/>}
                            {activeTab === 'Form' && <FormConfig/>}
                            {activeTab === 'Report' && <ReportConfig/>}
                            {activeTab === 'Widget' && <ReportConfig/>}
                            {activeTab === 'Table' && <ReportConfig/>}
                            {activeTab === 'Button' && <ButtonConfig/>}
                        </Row>
                    }
                </Row>

        )

    }
}


const mapStateToProps = (state) => {
    return {
        activeTab   : state.activePageTab,
        activePage  : state.activePage,
        users       : state.users,
        teams       : state.teams,
    }
}

export default connect(mapStateToProps)(CreatePageContainer)