import React from 'react';
import PrivateRoute from "./PrivateRoute";
import CreatePageContainer from '../pageBuilder/createPageContainer';


const PageRouters = (props) => {


    return  <React.Fragment>
                <PrivateRoute path='/app/pages/creator/:id' component={CreatePageContainer}  />
            </React.Fragment>
};

export default PageRouters;
