import TYPES from "./AttachmentsActionTypes";

const fetchAttachments = (params = {}) => {
	return {
		type: TYPES.FETCH_ATTACHMENTS,
		params
	}
}

const fetchAttachmentsSuccess = (attachments) => {
  return {
    type: TYPES.FETCH_ATTACHMENTS_SUCCESS,
    attachments,
  }
}

const createAttachmentSuccess = (attachment) => {
  return {
    type: TYPES.CREATE_ATTACHMENT_SUCCESS,
    attachment
  };
}

const deleteAttachment = (attachment) => {
  return {
    type: TYPES.DELETE_ATTACHMENT,
    attachment
  };
}

const deleteAttachmentSucess = (attachment) => {
  return {
    type: TYPES.DELETE_ATTACHMENT_SUCCESS,
    attachment
  };
}

export {
  fetchAttachments,
  fetchAttachmentsSuccess,
  createAttachmentSuccess,
  deleteAttachment,
  deleteAttachmentSucess,
};
