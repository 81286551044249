import React from 'react'
import { Input, Form, Modal, Select, Button } from 'antd';
import { showNotification } from '../../../utils/commonFunctions';

class ProjectCreatorModal extends React.Component {

  state = {
    showCreateFromTemplate: true,
    name: undefined,
    description: undefined,
    nameInvalid: true,
    descriptionInvalid: true,
    loading : false,
    category: undefined
  }

  componentDidMount() {
    this.props.fetchProjectTemplates && this.props.fetchProjectTemplates();
  }

  showCreateNew = () => {
    this.setState({
      showCreateFromTemplate: false
    });
  }

  showCreateFromTemplate = () => {
    this.setState({
      showCreateFromTemplate: true
    });
  }

  setName = (e) => {
    let testRegExp = new RegExp('^[a-zA-Z0-9 ]*$');
    let nameInvalid = true;
    if (e.target.value && testRegExp.test(e.target.value)) {
      nameInvalid = false;
    }
    this.setState({
      name: e.target.value,
      nameInvalid,
    })
  }

  setCategory = (value) => {
    this.setState({
      category: value
    })
  }

  setDescription = (e) => {
    let descriptionInvalid = true;
    if (e.target.value) {
      descriptionInvalid = false;
    }
    this.setState({
      description: e.target.value,
      descriptionInvalid
    })
  }

  renderCreateNewForm = () => {
    const formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
      labelAlign: "left"
    };

    const { nameInvalid, name, description } = this.state;
    const { categories } = this.props;

    return (
      <Form {...formLayout}>
        <Form.Item
          label={<span className='text-color'>Name <span style={{color: "#FF3D57"}}>*</span></span>}
          colon={false}
          validateStatus={name && nameInvalid ? "error" : "success"}
          help={name && nameInvalid && "Should be combination of numbers & alphabets only"}
        >
          <Input placeholder="Mars Life" onChange={this.setName} size='large' className='custom-input' value={name} maxLength={50}/>
        </Form.Item>
        <Form.Item label={<span className='text-color'>Description</span>} colon={false}>
          <Input.TextArea
            onChange={this.setDescription}
            autosize={{minRows: 4}}
            value={description}
            // maxLength={70}
            placeholder="Taking mankind forward"
            className='custom-input'
          />
        </Form.Item>
        <Form.Item label={<span className='text-color'>Category</span>} style={{marginBottom:'0'}} colon={false}>
          <Select
            getPopupContainer={(e)=>e.parentNode}
            allowClear
            size='large'
            mode="multiple"
            className="custom-select"
            placeholder="Select project category"
            onChange={this.setCategory}
            notFoundContent="No category found"
            filterOption={(inputValue, option) => {
              return option.props.category &&
                option.props.category.name &&
                option.props.category.name.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
            }}
          >
            {categories && categories.map(category => <Select.Option
              category={category}
              value={category._id}
              key={category._id}
            >
              {category.name}
            </Select.Option>)}
          </Select>
        </Form.Item>
      </Form>
    );
  }

  createFromTemplate = (index) => {
    const { templates } = this.props;
    console.log(templates[index]);
    this.props.createNewProject && this.props.createNewProject({
      data: Object.assign({}, templates[index], {
        template: true
      })
    })
    this.setState({
      showCreateFromTemplate: true,
      name: undefined,
      description: undefined,
      nameInvalid: true,
      descriptionInvalid: true,
    });
  }

  createNewProject = () => {
    if(this.state.name.trim() === '') {
      showNotification('error', 'Project name cannot be blank')
      return false;
    }
    this.setState({loading:true})
    this.props.createNewProject && this.props.createNewProject({
      data: {
        name: this.state.name,
        description: this.state.description,
        category: this.state.category,
        template : true,
        draft: true,
      }
    });
    this.setState({
      showCreateFromTemplate: true,
      name: undefined,
      description: undefined,
      nameInvalid: true,
      descriptionInvalid: true,
      loading:false
    });
  }

  render() {
    // const { showCreateFromTemplate, nameInvalid, descriptionInvalid } = this.state;
    const { visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        className="custom-modal"
        title={<>
          {/* {!showCreateFromTemplate && <Icon
            type="left-circle"
            style={{float: "left", cursor: "pointer"}}
            onClick={this.showCreateFromTemplate}
          />} */}
          <span>Create Project</span>
        </>}
        onCancel={onCancel}
        footer={
          <>
            {/* {showCreateFromTemplate && templates && templates.length > 0 && <Button
              type="primary"
              // style={{width: "50%"}}
              onClick={this.showCreateNew}
            >
                Create New
            </Button>} */}
            {<Button
              // disabled={nameInvalid}
              type="primary"
              style={{border: "none"}}
              onClick={this.createNewProject}
              loading={this.state.loading}
            >
                Create New
            </Button>}
          </>
        }
      >
    {/* {showCreateFromTemplate && templates && templates.length > 0 && <List
          header={null}
          footer={null}
          bordered={false}
          dataSource={templates}
          renderItem={(template, index) => (
            <List.Item style={{display: "flex", cursor: "pointer"}} onClick={e => this.createFromTemplate(index)} key={index}>
              <Avatar style={{backgroundColor: getAvatarColor(template.created_by.id)}} size={40}>
                  {getNameInitials(template.created_by.name)}
              </Avatar>
              <div style={{flexGrow: 1, marginLeft: 15}}>
                <div>{template.name}</div>
                <div>{template.description}</div>
              </div>
            </List.Item>
          )}
        />} */}
        {/* {(!showCreateFromTemplate || !(templates && templates.length > 0)) && this.renderCreateNewForm()} */}
        {this.renderCreateNewForm()}
      </Modal>
    )
  }
}

export default ProjectCreatorModal;
