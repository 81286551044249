// import { put } from 'redux-saga/effects';
import ACTIONS from "./PageActionTypes";
import {store} from '../../config/Store';

export function fetchPagesRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function fetchMorePagesRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function createPageRequest(pageInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:pageInfo})
}
export function updatePageRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}
export function deletePageRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}
export function setActivePage(pageData) {
    store.dispatch({type:ACTIONS.SET_ACTIVE_PAGE, pageData})
}

