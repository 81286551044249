import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Row, Popconfirm} from 'antd'
// import Column from './column'
import WhoCanInitiateModal from './components/whoCanInitiateModal';
import Step from './components/step';
import WorkflowSettings from './components/workflowSettings';
// import { Container, Draggable } from "react-smooth-dnd";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui.js";
import {guidGenerator, showNotification} from '../../utils/commonFunctions'

// const s1 = {
//     fontSize:'14px',
//     color:'#8697A8', 
//     fontWeight:'normal'
// }

class WorkflowBuilderContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            showModal   : (this.props.activeStep.assignment.people.length === 0 && this.props.activeStep.assignment.teams.length === 0) ? true : false,
            openPanel   : false,
            activeStep  : this.props.activeStep
        }
        this.nodesConnection  = [];
    }

    componentDidMount() {
        this.redrawUpdateLines()
        this.init()
        $('.app-content-block').css('overflow','auto')

        let fields      = Object.assign({}, this.props.allFields)
        let sections    = this.props.sections
        let steps       = Object.assign({}, this.props.steps)
        Object.keys(fields).forEach(fieldId => {
            Object.keys(steps).forEach(stepId => {
                if(!steps[stepId].fields[fieldId]){
                    steps[stepId].fields[fieldId] = {
                        show     : true,
                        editable : steps[stepId].start ? true : false
                    }
                }
            })
        })

        sections.forEach(section => {
            Object.keys(steps).forEach(stepId => {
                if(!steps[stepId].sections[section.id]){
                    steps[stepId].sections[section.id] = {
                        show : true,
                        open : true
                    }
                }
            })
        })
        console.log('steps', steps)
    }

    componentWillUnmount(){
        $('.app-content-block').css('overflow','auto')
    }

    init = () => {
        let _self               = this;
        let flag                = 1;
        let x1                  = 0;
        let y1                  = 0;
        let do_not_create       = 0
        let already_connected   = 0
        // let nodesConnection     = []

        $('.wfnode').draggable({
            containment: "#workflow-canvas",
            drag: function( event, ui ) {
                let lines       = $(this).data('lines');
                let con_item    = $(this).data('connected-item');
                let con_lines   = $(this).data('connected-lines');
                // console.log('Lines',lines, con_item, con_lines)
                if(lines) {
                    let multiplier = 0;
                    lines.forEach(function(line,id){
                        let _x1 = ($(this).position().left+($(this).outerWidth()/2));
                        let _y1 = ($(this).position().top+$(this).outerHeight())-25;
                        if(lines.length>1)
                        {
                            if(lines.length%2 === 0)
                            {
                                if(id%2 === 0){
                                    multiplier++;
                                }
                            }
                            else{
                                if(id !== 0){
                                    if(id%2 !== 0){
                                        multiplier++;
                                    }
                                }
                            }
                            let _wd = 20*multiplier;
                            // _x1 = (id%2 !== 0) ? _x1 - _wd : _x1 + _wd;
                        }
                        $(line).attr('x1',_x1).attr('y1',_y1);
                        $(line).attr('points',_self.evaluatePoints(_x1,_y1,$(line).attr('x2'),$(line).attr('y2')))
                        // if($('.diamond-shape[id="'+$(line).attr('connectors')+'"]').length>0)
                        // {
                        //     $('.diamond-shape[id="'+$(line).attr('connectors')+'"]').css({left:$(line).attr('x2'),top:_self.dHeight($(line).attr('x1'),$(line).attr('y1'),$(line).attr('x2'),$(line).attr('y2'))});
                        // }
                        // $('polyline[drop_id]').each(function(i,v){
                        //     if($(this).attr('drop_id').split('-')[1]==$(line).attr('connectors').split('-')[1])
                        //     {
                        //         if($(this).attr('id') != $(line).attr('id') && $('.diamond-shape[id="'+$(line).attr('connectors')+'"]').length>0 )
                        //         {
                        //             let _y2 = $('.diamond-shape[id="'+$(line).attr('connectors')+'"]').position().top+28;
                        //             $(this).attr('points',_self.evaluatePoints($(this).attr('x1'),$(this).attr('y1'),$(this).attr('x2'),_y2));
                        //         }
                        //     }
                        // });
                    }.bind(this));
                }
                if(con_lines){
                    // let multiplier  = 0;
                    // let count       = $('.diamond-shape[id$="-'+$(this).attr('guid')+'"]').length;
                    // let _cid        = 0;
                    con_lines.forEach(function(con_line,id){
                        if($(con_line).attr('drop_id') === undefined){
                            let _x2 = ($(this).position().left+($(this).outerWidth()/2));
                            let _y2 = ($(this).position().top)-29;
                            // if(count>1)
                            // {
                            //     if(count%2 == 0)
                            //     {
                            //         if(_cid%2 == 0){
                            //             multiplier++;
                            //         }
                            //     }
                            //     else{
                            //         if(_cid!=0){
                            //             if(_cid%2 != 0){
                            //                 multiplier++;
                            //             }
                            //         }
                            //     }
                            //     let _wd = 35*multiplier;
                            //     _x2 = (_cid%2 != 0) ? _x2 - _wd : _x2 + _wd;
                            // }
                            // _cid++;
                            $(con_line).attr('x2',_x2).attr('y2',_y2);
                            $(con_line).attr('points',_self.evaluatePoints($(con_line).attr('x1'),$(con_line).attr('y1'),_x2,_y2));
                            // $('.diamond-shape[id="'+$(con_line).attr('connectors')+'"]').css({left:_x2-20,top:_self.dHeight($(con_line).attr('x1'),$(con_line).attr('y1'),$(con_line).attr('x2'),$(con_line).attr('y2'))});
                        }
                        else
                        {
                            $('.diamond-shape').each(function(i,v){
                                if($(this).attr('id') == $(con_line).attr('drop_id')){
                                    let _x2 = $(this).position().left+20;
                                    let _y2 = $(this).position().top+28;
                                    $(con_line).attr('x2',_x2).attr('y2',_y2);
                                    $(con_line).attr('points',_self.evaluatePoints($(con_line).attr('x1'),$(con_line).attr('y1'),_x2,_y2));
                                }
                            });
                        }
                    }.bind(this));
                }
            },
            stop: function(e, ui) {
                let steps           = Object.assign({},_self.props.steps)
                let step            = steps[$(this).attr('id')]
                step.position.top   = $(this).position().top
                step.position.left  = $(this).position().left
                _self.props.updateSteps(steps)
            }
        });

        $('.wfnode, .diamond-shape').droppable({
            accept: '.anchor-points',
            hoverClass: "ui-state-highlight",
            drop: function(event,ui) {
                let item    = ui.draggable.closest('.wfnode');
                let tmp     = "";
                let _that   = $(this);
                let _guid   = _that.attr('id');

                do_not_create     = 0;
                already_connected = 0;
                item.data('lines').push(item.data('line'));
                ui.draggable.css({top:'90%',left:'44%'});
                _that.data('connected-item',item);

                if(_that.data('connected-lines')){
                    already_connected = 1;
                    _that.data('connected-lines').push(item.data('line'));
                }
                else
                {
                    _that.data('connected-lines',[item.data('line')]);
                }

                if(_that.hasClass('diamond-shape'))
                {
                    // let _cid = _that.attr('id').split('-')[1];
                    // if(_cid == item.attr('guid')) return false;
                    // $('.ui-item[guid="'+_cid+'"]').data('connected-item',item)
                    // tmp = item.attr('guid')+'-'+_cid;

                    // if(_that.data('connected-lines'))
                    // {
                    //     $('.ui-item[guid="'+_cid+'"]').data('connected-lines').push(item.data('line'));
                    // }
                    // else
                    // {
                    //     $('.ui-item[guid="'+_cid+'"]').data('connected-lines',[item.data('line')]);
                    // }
                    // $('polyline:last').attr('connectors',item.attr('guid')+'-'+_cid);
                    // $('polyline:last').attr('drop_id',_that.attr('id'));

                }
                else
                {
                    // $('.diamond-shape').each(function(i,v){
                    //     if($(this).attr('id').split('-')[1] == _guid){
                    //         $(this).data('connected-item',item);
                    //         if(_that.data('connected-lines'))
                    //         {
                    //             $(this).data('connected-lines').push(item.data('line'));
                    //         }
                    //         else
                    //         {
                    //             $(this).data('connected-lines',[item.data('line')]);
                    //         }
                    //     }
                    // });
                    tmp = item.attr('id')+'-'+_guid;
                    $('polyline:last').attr('connectors',item.attr('id')+'-'+_guid);

                }
                console.log(_self.nodesConnection)
                if($.inArray(tmp, _self.nodesConnection) >=0){
                    do_not_create = 1;
                    // if(_that.hasClass('diamond-shape'))
                    // {
                    //     let _cid = _that.attr('id').split('-')[1];
                    //     $('.wfnode[guid="'+_cid+'"]').data('connected-lines').pop();
                    // }
                    // else
                    // {
                    //     $('.diamond-shape').each(function(i,v){
                    //         if($(this).attr('id').split('-')[1] == _guid){
                    //             $(this).data('connected-lines').pop();
                    //         }
                    //     });
                    // }
                    _that.data('connected-lines').pop();
                    item.data('lines').pop();
                }
                else
                {
                    // let curNode;
                    // let _workflow_data  = _self.getWorkFlowDataFromState();
                    // let pTmpId          = _self.getNodeInfo(item.attr('guid')).template_id;
                    // if(!_that.hasClass('diamond-shape'))
                    // {
                    //     $('#workflow-canvas').append('<div id="'+tmp+'" class="diamond-shape" style="left:'+(parseFloat($('polyline:last').attr('x2'))-20)+'px;top:'+_self.dHeight($('polyline:last').attr('x1'),$('polyline:last').attr('y1'),$('polyline:last').attr('x2'),$('polyline:last').attr('y2'))+'px"><i class="material-icons pointer"data-name="mapping" title="Mapping">call_split</i></div>');
                    //     $('.diamond-shape[id="'+tmp+'"]').data('connected-lines',[item.data('line')]);
                    // }
                    // else
                    // {
                    //     curNode  = _workflow_data.nodes.find(node=>node.node_id == $(this).attr('id').split('-')[1]);
                    //     curNode.template.mapping.forEach(map =>{
                    //         if(map.d_id == $(this).attr('id') && pTmpId != null){
                    //             map.map_template_id.push(pTmpId)
                    //         }
                    //     })
                    // }
                    
                    let _key  = _that.hasClass('diamond-shape') ?_that.attr('id').split('-')[1] :_guid;

                    // curNode  = _workflow_data.nodes.find(node=>node.node_id == _key )
                    // curNode.prev_step_id.push(item.attr('guid'));

                    // curNode  = _workflow_data.nodes.find(node=>node.node_id == item.attr('guid') )

                    // curNode.next_step_id.push(_key);

                    let steps = Object.assign({}, _self.props.steps)
                    steps[_key].prev_step_id.push(item.attr('id'))
                    steps[item.attr('id')].next_step_id.push(_key)
                    _self.props.updateSteps(steps)

                    
                }
                _self.nodesConnection.push(tmp);
                item.data('line',null);
                // $('polyline').contextMenu(delete_menu);
            }
        });

        $('.anchor-points').draggable({
            revert : false,
            containment: "#workflow-canvas",
            drag: function( event, ui ) {
                let _end = $(event.target).parent().position();
                let end = $(event.target).position();
                // let points = "";
                if(_end&&end)
                {
                    if(flag === 1)
                    {
                        x1 = parseFloat($(event.target).parent().data('line').attr('x1')-7);
                        y1= parseFloat($(event.target).parent().data('line').attr('y1')-25);
                        flag = 0;
                    }
                    let x2       = parseFloat((end.left+_end.left))+15;
                    let y2       = parseFloat((end.top+_end.top));
                    $(event.target).parent().data('line').attr('x1',x1).attr('y1',y1);
                    $(event.target).parent().data('line').attr('x2',(end.left+_end.left)).attr('y2',(end.top+_end.top));
                    $(event.target).parent().data('line').attr('points',_self.evaluatePoints(x1,y1,x2,y2));
                }
            },
            stop: function(event,ui) {
                if(!ui.helper.closest('.wfnode').data('line'))
                {
                    if(do_not_create === 1)
                    {
                        showNotification('info', 'There is already a link between these two step')
                        $('polyline:last').remove();
                        do_not_create = 0;
                    }
                    flag = 1;
                    return;
                }
                let item     = $(ui.helper).closest('.wfnode');
                
                let top     = item.position().top + $(this).position().top + 28; // node height
                let left    = item.position().left + $(this).position().left - 110; // node width
                let _id     = guidGenerator();
                let obj     = _self.props.createNodeObject(_id, item.attr('id'), {top,left})
                setTimeout(function(){
                    _self.editStep(_id, false)
                },1000)
                
                let steps   = Object.assign({},_self.props.steps)
                steps[item.attr('id')].next_step_id.push(_id.toString())
                steps       = {...steps, ...obj}
                _self.props.updateSteps(steps)
                
                // let lineId   = $('polyline:last').attr('id');
                item.data('lines').push(item.data('line'));
                $('.wfnode[id="'+_id+'"]').data('connected-lines',[item.data('line')]);
                $('polyline:last').attr('connectors',ui.helper.closest('.wfnode').attr('id')+'-'+_id);
                ui.helper.css({top:'90%',left:'44%'});
                _self.nodesConnection.push(ui.helper.closest('.wfnode').attr('id')+'-'+_id);
                item.data('line',null);
                _self.init()
            }
        });
    }

    redrawUpdateLines = () => {
        let _self       = this;
        let draw_data   = this.props.draw_data
        Object.keys(draw_data).map(d=>{
            $('.wfnode[id="'+d+'"]').data('lines',[]);
            $('.wfnode[id="'+d+'"]').data('connected-lines',[]);
        })
        Object.keys(draw_data).map(d=>{
            (draw_data[d].lines && draw_data[d].lines.length > 0) && draw_data[d].lines.map(line=>{
                _self.nodesConnection.push(line.connectors);
                let c = line.connectors.split('-');
                if(line.drop_id){
                    // _line.attr('drop_id',line.drop_id)
                    // $('.diamond-shape[id="'+line.drop_id+'"]').data('connected-lines').push($('polyline[connectors="'+line.connectors+'"]'));
                }
                else{
                    // $('#workflow-canvas').append('<div id="'+line.connectors+'" class="diamond-shape" style="left:'+(parseFloat(line.x2)-20)+'px;top:'+_self.dHeight(line.x1,line.y1,line.x2,line.y2)+'px"><i class="material-icons pointer"data-name="mapping" title="Mapping">call_split</i></div>');
                    // $('.diamond-shape[id="'+line.connectors+'"]').data('connected-lines',[]);
                    // $('.diamond-shape[id="'+line.connectors+'"]').data('connected-lines').push($('polyline[connectors="'+line.connectors+'"]'));
                }
                $('.wfnode[id="'+c[0]+'"]').data('lines').push($('polyline[connectors="'+line.connectors+'"]'));
                if($('.wfnode[id="'+c[1]+'"]').length > 0)
                {
                    $('.wfnode[id="'+c[1]+'"]').data('connected-lines').push($('polyline[connectors="'+line.connectors+'"]'));
                }
            })
        })
    }

    removeStep = (stepId) => {
        console.log('stepId',stepId)
        let _self = this;
        $('polyline').each(function(i,v)
        {
            let lineId      = $(v).attr('id');
            let cnt         = $(v).attr('connectors');
            let stepIds     = cnt.split('-');
            console.log(stepId, stepIds)
            if($.inArray(stepId.toString(), stepIds) >= 0)
            {
                _self.removeLine(lineId)
            }
        })

        let steps = Object.assign({}, this.props.steps)
        delete steps[stepId]
        this.props.updateSteps(steps)
    }

    removeLine = (lineId) => {
        console.log('lineId',lineId)
        let _self           = this;
        let _connectors     = $('polyline[id="'+lineId+'"]').attr('connectors');
        let items           = _connectors.split('-');
        let allLines        = $('.wfnode[id='+items[0]+']').data('lines');
        let connectedLines  = $('.wfnode[id='+items[1]+']').data('connected-lines');
        let connectedItem   = $('.wfnode[id='+items[1]+']').data('connected-item');

        if($('polyline[id="'+lineId+'"]').attr('drop_id') !== undefined && $('polyline[id="'+lineId+'"]').attr('drop_id') !== "")
        {
            let drpId           = $('polyline[id="'+lineId+'"]').attr('drop_id');
            let connectedDLines = $('.diamond-shape[id="'+drpId+'"]').data('connected-lines');
            let connectedDItem  = $('.diamond-shape[id="'+drpId+'"]').data('connected-item');
            $(connectedDLines).each(function(i,v){
                if($(v).attr('connectors') === _connectors)
                {
                    let idx = connectedDLines.indexOf(v);
                    connectedDLines.splice(idx,1);
                }
            })
            $(connectedDItem).each(function(i,v){
                if($(v).attr('id') === items[0])
                {
                    let idx = connectedDItem.index(v);
                    connectedDItem.splice(idx,1);
                }
            })
        }

        $(allLines).each(function(i,v){
            if($(v).attr('connectors') === _connectors)
            {
                let idx = allLines.indexOf(v);
                allLines.splice(idx,1);
            }
        })
        $(connectedLines).each(function(i,v){
            if($(v).attr('connectors') === _connectors)
            {
                let idx = connectedLines.indexOf(v);
                connectedLines.splice(idx,1);
            }
        })
        $(connectedItem).each(function(i,v){
            if($(v).attr('id') === items[0])
            {
                let idx = connectedItem.index(v);
                connectedItem.splice(idx,1);
            }
        })

        let idx = _self.nodesConnection.indexOf(_connectors);
        _self.nodesConnection.splice(idx,1);

        // _self.changeConditionalDrawData(items[0]);
        // let _workflow_data  = _self.getWorkFlowDataFromState();

        let steps = Object.assign({}, this.props.steps)

        let parentNode = steps[items[0]]

        // let parentNode = _workflow_data.nodes.find(node=>node.node_id === items[0])
        
        let _idx = parentNode.next_step_id.indexOf(items[1]);
        parentNode.next_step_id.splice(_idx,1);

        // let curNode = _workflow_data.nodes.find(node => node.node_id == items[1]);
        let curNode = steps[items[1]]

        let _idx1 = curNode.prev_step_id.indexOf(items[0]);
        curNode.prev_step_id.splice(_idx1,1);
       

        let flag = 0;
        $('.diamond-shape').each(function(){
            if(_connectors === $(this).attr('id')){
                $('polyline[drop_id]').each(function(){
                    let id = $(this).attr('id');
                    if($(this).attr('drop_id') === _connectors)
                    {
                        $('polyline[connectors='+_connectors+'],.diamond-shape[id="'+_connectors+'"]').remove();
                        // _self.setWorkFlowDataInState(_workflow_data);
                        _self.updateSteps(steps)
                        flag = 1;
                        _self.removeLine(id);

                    }
                })
            }
        });
        if (!flag) {
            $('polyline[connectors='+_connectors+'],.diamond-shape[id="'+_connectors+'"]').remove();
            // _self.setWorkFlowDataInState(_workflow_data);
            _self.props.updateSteps(steps)
        }
    }

    handleAnchorPoints = (e) => {
        let cur_ui_item = $(e.target).closest('.wfnode');
        let connector = $('#connector-canvas');
        let cur_con;

        if(!$(cur_ui_item).data('lines')) $(cur_ui_item).data('lines',[]);

        if(!$(cur_ui_item).data('line')){
            cur_con = $(document.createElementNS('http://www.w3.org/2000/svg','polyline'));
            cur_con.attr('id',guidGenerator());
            cur_con.attr('marker-end','url(#triangle)');
            cur_ui_item.data('line',cur_con);
        }
        else cur_con = cur_ui_item.data('line');

        connector.append(cur_con);
        let start    = cur_ui_item.position();
        let tp       = $(cur_ui_item).outerHeight();
        let lt       = $(cur_ui_item).outerWidth()/2;

        let points   = this.evaluatePoints(start.left+lt+7,start.top+tp,start.left+77,start.top+5)

        cur_con.attr('points',points);
        cur_con.attr('x1',start.left+lt+7).attr('y1',start.top+tp);// ui-item height width
        cur_con.attr('x2',start.left+77).attr('y2',start.top+5);
    }

    evaluatePoints = (x1, y1, x2, y2) =>{
        x1             = parseFloat(x1);
        y1             = parseFloat(y1);
        x2             = parseFloat(x2);
        y2             = parseFloat(y2);
        let vertical   = parseFloat(y2-y1);
        let horizontal = parseFloat(x2-x1);
        let points     = "";
        let box_wd     = 250;
        let box_ht     = 90;
        let h_box_wd   = parseFloat(box_wd/2);

        if((horizontal<(x1+h_box_wd) ||horizontal>(x1-h_box_wd)) && (vertical>-box_ht && vertical<0) || horizontal === -5)
            points = x1+','+y1+' '+x1+','+(y1+20)+' '+(x1+(h_box_wd + 20))+','+(y1+20)+' '+(x1+(h_box_wd + 20))+','+((y1-box_ht)-40)+' '+x2+','+((y1-box_ht)-40)+' '+x2+','+(y2);
        else if(vertical<=40)
            points = x1+','+y1+' '+x1+','+(y1+20)+' '+(x1+(horizontal/2))+','+(y1+20)+' '+(x1+(horizontal/2))+','+(y2-40)+' '+x2+','+(y2-40)+' '+x2+','+y2;
        else if(vertical>40)
            points = x1+','+y1+' '+x1+','+(y1 + ((vertical)/2))+' '+x2+','+(y1 + ((vertical)/2))+' '+x2+','+y2;
        return points;
    }

    toggleModal = (val, initiators = []) => {
        if(!val && initiators.length === 0){
            return false
        }else{
            this.setState({showModal:val})
        }
    }

    togglePanel = (val) => {
        this.setState({openPanel:val})
    }

    handleSubmit = (val, stepId) => {
        this.whoCanInitiate(val,stepId)
        this.toggleModal(false, val)
        console.log('on sublit of modal called---')
    }

    editStep = (stepId, toggle = true) => {
        let allFields        = this.props.allFields
        let sections         = this.props.sections
        let steps            = Object.assign({},this.props.steps)
        let activeStep       = steps[stepId]

        for(let key in allFields){
            if(allFields.hasOwnProperty(key)){
                if(!activeStep.fields[key]){
                    activeStep.fields[key] = {
                        show     : true,
                        editable : activeStep.start ? true : false
                    }
                }
            }
        }
        sections.forEach(section => {
            if(!activeStep.sections[section.id]){
                activeStep.sections[section.id] = {
                    show: true,
                    open: true
                }
            }
        })
        this.setState({activeStep})
        this.props.updateSteps(steps)
        if(toggle){
            this.togglePanel(true)
        }
    }

    updateStepName = (e, stepId) => {
        // if(e.target.value.trim() !== ''){
            let steps            = Object.assign({},this.props.steps)
            let activeStep       = steps[stepId]
            activeStep.step_name = e.target.value.trim()
            this.props.updateSteps(steps)
            document.activeElement.blur();
        // }
    }

    whoCanInitiate = (value, stepId) => {
        let steps   = Object.assign({},this.props.steps)
        let step    = steps[stepId]
        if(step.start){
            let _all    = value.findIndex(a=>a === 'all')
            if(_all >= 0){
                step.assignment.people = 'all'
                step.assignment.teams  = []
            }else{
                let teams   = []
                let people  = []
                value.map(v=>{
                    if(v.split('_').length > 1){
                        teams.push(v.split('_')[1])
                    }else{
                        people.push(v)
                    }
                })
                step.assignment.teams  = teams
                step.assignment.people = people
            }
            this.props.updateSteps(steps)
        }
    }

    render() {
        let {steps, teams, users, updateSteps, draw_data} = this.props;
        let activeStep = this.state.activeStep

        return (
            <Row id='workflow-canvas' type='flex' style={{paddingTop:'26px'}} className='wf-gradient fh'>
                <svg id='connector-canvas'>
                    <defs>
                        <marker id="triangle" refX="8" refY="5" markerWidth="3" markerHeight="5" orient="auto" viewBox="3 0 10 10"><path d="M 0 0 L 10 5 L 0 10 z"></path></marker>
                    </defs>
                    {
                        Object.keys(draw_data).map(d=>{
                            return (draw_data[d].lines && draw_data[d].lines.length > 0) && draw_data[d].lines.map(line => {
                                return  <Popconfirm key = {line.id} placement="right" title={'Are you sure to delete this line?'} onConfirm={e=>this.removeLine(line.id)} okText="Yes" cancelText="No">
                                            <polyline
                                                id          = {line.id}
                                                x1          = {line.x1}
                                                x2          = {line.x2}
                                                y1          = {line.y1}
                                                y2          = {line.y2}
                                                connectors  = {line.connectors}
                                                points      = {this.evaluatePoints(line.x1, line.y1, line.x2, line.y2)}
                                                markerEnd   = "url(#triangle)"
                                            >
                                            </polyline>
                                        </Popconfirm>
                            })
                        })
                    }
                </svg>
                {
                    Object.keys(steps).map(id=>(
                        <Step 
                            key                 = {steps[id].id}
                            step                = {steps[id]}
                            updateStepName      = {this.updateStepName}
                            editStep            = {this.editStep}
                            handleAnchorPoints  = {this.handleAnchorPoints}
                            removeStep          = {this.removeStep}
                            draw_data           = {draw_data[id]}
                            toggleModal         = {this.toggleModal}
                            users               = {users}
                            teams               = {teams}
                        />
                    ))
                }
                <WorkflowSettings
                    key         = {activeStep.id}
                    openPanel   = {this.state.openPanel}
                    togglePanel = {this.togglePanel}
                    sections    = {this.props.sections}
                    allFields   = {this.props.allFields}
                    activeStep  = {activeStep}
                    updateSteps = {updateSteps}
                    steps       = {steps}
                    users       = {this.props.users}
                />
                <WhoCanInitiateModal
                    handleSubmit    = {this.handleSubmit}
                    users           = {users}
                    teams           = {teams}
                    showModal       = {this.state.showModal}
                    toggleModal     = {this.toggleModal}
                    whoCanInitiate  = {this.whoCanInitiate}
                    activeStep      = {activeStep}
                />
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fields: state.fields
    }
}

export default connect(mapStateToProps)(WorkflowBuilderContainer);