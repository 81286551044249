import validator from 'validator';

export function isvalidEmail(email){
    email = email.trim()
    let emailRegex=/^\w+([\.-]?\w+[\+]?)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

    if(emailRegex.test(email) ){
        return validator.isEmail(email);
    }
    return false;
}

export function isvalidOrganisationName(organisationName){
    return !validator.isEmpty(organisationName);
}

export function isvalidPassword(password)
{
    //var passwordRegExp = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&amp;*()_+}{&quot;:;'?/&gt;.&lt;,])(?!.*\s).*$/
    if(validator.isLength(password,{min:4}) ){
        return true;
    }
    return false;
}

export function isvalidPhone(phoneNumber){
    return validator.isNumeric(phoneNumber) && validator.isLength(phoneNumber,{min:10});
}

export function isvalidName(name){
    // let nameRegExp = /^[a-z ,.'-]+$/i;
    let nameRegExp = /^[a-z0-9]+$/i;
    if(nameRegExp.test(name)){
        return true;
    }
    return false;
}