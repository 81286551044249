import React from 'react';
import { message, Row, Modal, Card, Skeleton, Button, Tag } from 'antd';
// import { Link } from 'react-router-dom'

import './styles.css';

const { confirm } = Modal;

class Marketplace extends React.Component {

  state = {
    installingApp: false,
    showPreview: undefined,
  }

  componentDidUpdate(prevProps) {
    if (this.props.installAppFailed && this.props.installAppFailed !== prevProps.installAppFailed) {
      this.setState({
        installingApp: false
      });
      message.error('Error installing app!');
    }
    if (this.props.installAppSuccess && this.props.installAppSuccess !== prevProps.installAppSuccess) {
      this.setState({
        installingApp: false
      })
      message.success('App was installed successfully');
    }
  }

  renderLoadingCards = () => {
    return [1, 2, 3, 4, 5 ,6, 7].map((i, index) =>
      <div className="marketplacelist-item-container" key={index}>
        <Card className="marketplacelist-item-card">
          <Skeleton active loading={true} title={null} paragraph={{rows: 4}}>
          </Skeleton>
        </Card>
        <div className="marketplacelist-item-shadow"></div>
      </div>
    );
  }

  installApp = (id) => {
    this.setState({
      installingApp: true
    })
    this.props.installApp({
      data: {
        type: "Project",
        app_id: id,
        template: true
      }
    })
  }

  deactivateApp = (app) => {
    const { updateMarketplaceApp } = this.props;

    confirm({
      title: `Are you sure you want to deactivate project ${app.data.name}`,
      content: 'It will deactivated for all the users',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        app.draft = true;
        updateMarketplaceApp({
          data: app
        });
      },
      onCancel() {
      },
    });
  }

  showPreview = (app) => {
    this.setState({
      showPreview: app
    })
  }

  closePreview = () => {
    this.setState({
      showPreview: undefined
    })
  }

  renderPreview = () => {
    const{ showPreview: app, installingApp } = this.state;
    return (
      <Row style={{margin: "auto", width: "90%"}}>
        <Row type="flex">
          <div style={{flexGrow: 1}}>
            <div style={{fontSize: 30}}>
              {app.data.name}
            </div>
            <div style={{fontSize: 16}}>
              {app.data.description}
            </div>
          </div>
          <Row type="flex" justify="center" align="middle">
            <Button loading={installingApp} type="primary" onClick={() => this.installApp(app._id)}>+ Use </Button>
          </Row>
        </Row>
        {app.meta.details_sections.map((section, index) =>
          <div style={{marginTop: 50}} key={index}>
            <div style={{fontSize: 24, fontWeight: 600, color: "#44566C"}}>{section.title}</div>
            <div style={{color: "#44566C", fontSize: 16}}>{section.description}</div>
            <div>
              {section.images.map((image, index) =>
                <div style={{margin: "20px 0px"}} key={index}>
                  <img style={{width: "100%"}} src={image.location} alt='Procezo'/>
                </div>
              )}
            </div>
          </div>
        )}
      </Row>
    );
  }

  renderApp = (app, index) => {
    const { installingApp } = this.state;
    const { loggedInuser } = this.props;
    return (
      <>
        <Card
          className="marketplacelist-item-card"
          bodyStyle={{height: "100%", display: "flex", flexDirection: "column", padding: 0}}
        >
          <div className="marketplace-draft">
            {app.draft && <Tag color="rgb(255, 99, 120)">Draft</Tag>}
          </div>
          <div className="marketplace-name">{app.data.name}</div>
          <div className="marketplace-action">
            <Button className="marketplace-action-button" type="primary" onClick={(e) => {e.stopPropagation(); this.showPreview(app)}} ghost>Show Preview</Button>
            {loggedInuser.attributes.role_id !== 'super_admin' && <Button
              className="marketplace-action-button"
              loading={installingApp}
              type="primary"
              onClick={(e) => {e.stopPropagation(); this.installApp(app._id)}}
            >
                Use
            </Button>}
            {loggedInuser.attributes.role_id === 'super_admin' && <Button
              className="marketplace-action-button"
              loading={installingApp}
              type="primary"
              onClick={(e) => {e.stopPropagation(); this.deactivateApp(app)}}
            >
                Deactivate
            </Button>}
          </div>
        </Card>
        <div className="marketplacelist-item-shadow"></div>
      </>
    );
  }

  renderApps = () => {
    const { apps = [] } = this.props;
    const { showPreview } = this.state;
    return (
      <div>
        {apps.map((app, index) => !app.draft && (
          <div className="marketplacelist-item-container" key={index} onClick={() => this.showPreview(app)}>
            {this.renderApp(app, index)}
          </div>
        ))}
        <Modal
          className="custom-modal"
          onCancel={this.closePreview}
          title={<div style={{textAlign: "center"}}>Preview</div>}
          footer={null}
          width="80%"
          visible={!!showPreview}
        >
          {showPreview && this.renderPreview()}
        </Modal>
      </div>
    );
  }

  render() {
    const { loading } = this.props;
    return (
      <>
        {loading && this.renderLoadingCards()}
        {!loading && this.renderApps()}
      </>
    );
  }
}

export default Marketplace;
