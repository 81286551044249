import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
import CreateNewModal from '../utils/createNewModal'
import {showNotification} from '../utils/commonFunctions'
import {Row, Col, Input, Typography} from 'antd'
import {createProcessRequest} from '../process/duck/ProcessActions'

class ProcessCreatorContainer extends Component {

    state = {
        showModal   : false,
        processName : '',
        description : '',
        creating    : false,
        redirect    : false,
        processId   : null
    }

    componentDidUpdate(prevProps) {
        if (this.state.creating && this.props.process !== prevProps.process) {
            this.setState({
                creating : false,
                redirect : true,
                processId : this.props.process[0]._id
            })
        }
    }

    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    handleSubmit = (processName = this.state.processName, description = this.state.description) =>{
        let nameRegExp = /^[a-z0-9 &]+$/i;
        if(processName.trim() === ''){
            showNotification('error', 'Process name cannot be blank')
            return false
        }
        if(!nameRegExp.test(processName.trim())){
            showNotification('error', 'Special characters not allowed')
            return false
        }
        if(processName !== ''){
            let data = {
                name        : processName,
                description : description,
                draft       : true,
                bookmarked: false
            }
            createProcessRequest(data);
            showNotification('success','Process created successfully')
            this.setState({showModal:false, processName:'', description:'', creating: true})
        }else{
            showNotification('error','Please fill all the details')
        }
    }

    handleNameChange = (e) => {
        this.setState({processName:e.target.value})
    }

    handleDescriptionChange = (e) => {
        this.setState({description:e.target.value})
    }

    render() {
        return (
            <>
                {this.state.redirect && this.state.processId && <Redirect to={`/app/process/creator/${this.state.processId}`} />}
                <a href="#" onClick={e=>this.toggleModal(true)}>Process</a>
                <CreateNewModal 
                    title       = 'Create Process' 
                    onSubmit    = {this.handleSubmit}
                    modalVisible= {this.state.showModal}
                    toggleModal = {this.toggleModal}
                    btnText     = 'Create'
                >
                    <Row type='flex' align='middle'>
                        <Col span={6}>
                            <span className='text-color'>Name</span>
                            <Typography.Text type="danger" className='ml-5'>*</Typography.Text>
                        </Col>
                        <Col span={18}>
                            <Input 
                                onChange    = {this.handleNameChange} 
                                value       = {this.state.processName} 
                                size        = "large" 
                                className   = 'custom-input' 
                                placeholder = "Reimbursement" 
                                allowClear
                            />
                        </Col>
                    </Row>
                    <Row type='flex' align='top' className='mt-20'>
                        <Col span={6}>
                            <span className='text-color'>Description</span>
                        </Col>
                        <Col span={18}>
                            <Input.TextArea
                                className   = 'custom-input'
                                placeholder = "All your expenses reversed by us"
                                autosize    = {{ minRows: 3, maxRows: 6 }}
                                onChange    = {this.handleDescriptionChange}
                                value       = {this.state.description}
                            />
                        </Col>
                    </Row>
                    {/* <Row type='flex' align='middle' className='mt-20'>
                        <Col span={6}>
                            <span className='text-color'>Category</span>
                        </Col>
                        <Col span={18}>
                            <Select
                                getPopupContainer={(e)=>e.parentNode}
                                allowClear
                                className='custom-select fw'
                                size='large'
                                placeholder='Select category'
                            >

                            </Select>
                        </Col>
                    </Row> */}
                    
                </CreateNewModal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        process: state.process && state.process.data && state.process.data.data,
    };
}

export default connect(mapStateToProps)(ProcessCreatorContainer);
