import React, { Component } from 'react'
import {Row, Icon} from 'antd'

export default class PanelConfig extends Component {
    render() {
        
        let mb      = {marginBottom:'30px'}
        let query   = {background:'#F8FAFB',padding:'12px',borderRadius:'5px',color:'#44566C',marginBottom:'30px'}
        return (
            <React.Fragment>
                <Row style={query} type='flex' justify='space-between'>
                    Panel
                    <Icon type="right-circle" style={{fontSize:'20px'}}/>
                </Row>
                <Row style={mb}>
                    
                </Row>
            </React.Fragment>
        )
    }
}
