import React from 'react';
// import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {Row, Input, Icon, Col, Popconfirm, Empty, Select, Spin} from 'antd';
import {deleteTagRequest} from './duck/TagsActions'
import UpdateTagModal from './updateTagModal'
import {showNotification} from '../utils/commonFunctions'

class TagContainer extends React.Component {

    state = {
        showModal   : false,
        activetag   : null,
        searchText  : '',
        sortBy      : [],
        current:    1
    }


    toggleModal = (val) => {
        this.setState({showModal:val})
    }

    deleteTag = (id) => {
        console.log(id)
        deleteTagRequest(id)
        showNotification('success','Tag deleted successfully')
    }

    editTag = (tag) => {
        this.setState({
            showModal   : true,
            activeTag : tag
        })
    }

    sort = (val) => {
        this.setState({sortBy:val})
    }

    searchTags = (e) => {
        this.setState({searchText:e.target.value})
    }

    // onChange = (pageNumber) => {
    //     console.log('Page: ', pageNumber);
    //     this.setState({
    //         current: pageNumber,
    //       });
    //     // fetchMoretagsRequest({limit:2,offset:pageNumber})
    // }

    render(){
        let tags   = this.props.tags && this.props.tags.data && this.props.tags.data.data
        if(this.state.searchText.trim() !== ''){
            tags   = tags && tags.filter(f=>f.attributes.tag.toLowerCase().includes(this.state.searchText.toLowerCase()))
        }
        if(this.state.sortBy === 'alphabetical'){
            let tg = [...tags]
            tags  = tg.sort((a, b) => a.attributes.tag.localeCompare(b.attributes.tag))
        }else if(this.state.sortBy === 'creation'){
            let tg = [...tags]
            tags  = tg.reverse()
        }
        // console.log(totalCount)
        return(
            <Spin spinning={this.props.tags.loading} style={{minHeight:'60vh'}} size='large'>
                <Row gutter={16} type='flex' justify='end' style={{marginBottom:'40px'}}>
                    <Col span={4}>
                        <Select
                            getPopupContainer={(e)=>e.parentNode}
                            style       = {{boxShadow: '0px 8px 16px rgba(169, 194, 209, 0.1)'}}
                            className   = 'fw custom-select white'
                            size        = 'large'
                            placeholder = 'Sort by'
                            defaultValue= {this.state.sortBy}
                            onChange    = {this.sort}
                            allowClear

                        >
                            <Select.Option value='alphabetical'>
                                <Icon type="sort-ascending" className='mr-10' style={{fontSize:'18px'}}/>
                                Alphabetical
                            </Select.Option>   
                            <Select.Option value='creation'>
                                <Icon type="calendar" className='mr-10'/>
                                Creation Date
                            </Select.Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Input
                            placeholder="Search tags"
                            prefix={<Icon type="search" style={{fontSize:'20px', color: 'rgba(0,0,0,.25)' }} />}
                            size='large'
                            className='input-box searchbox'
                            onKeyUp = {this.searchTags}
                        />
                    </Col>
                </Row>
                {
                    tags && tags.length > 0
                    ?
                        tags.map((tag, index)=>{
                            return  <Row className='fieldItem' key={tag.id}> 
                                        <Row type='flex' justify='space-between'>
                                            <Row type='flex' align='middle'>
                                                <div className='mr-10' style={{height:'10px',width:'10px',backgroundColor:tag.attributes.color,borderRadius:'50%'}} />
                                                <span style={{color:'#56667A'}}>{tag.attributes.tag}</span>
                                            </Row>
                                            <Row type='flex' justify='end' className='edit'>
                                                <Icon type='edit' onClick={e=>this.editTag(tag)} />
                                                <Popconfirm
                                                    title="Are you sure delete this tag?"
                                                    onConfirm={e=>this.deleteTag(tag.id)}
                                                    // onCancel={cancel}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <Icon type='delete' style={{marginRight:0}}/>
                                                </Popconfirm>
                                            </Row>
                                        </Row>
                                    </Row>

                        })
                    :
                        <Row style={{minHeight:'50vh'}} type='flex' align='middle' justify='center'>
                            <Empty description={false} />
                        </Row>
                }
                {/* <Pagination defaultCurrent={this.state.current} total={totalCount} onChange={this.onChange} pageSize={2} /> */}
                <UpdateTagModal
                    toggleModal = {this.toggleModal}
                    showModal   = {this.state.showModal}
                    tag         = {this.state.activeTag}
                />
            </Spin>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        tags : state.tags
    }
}

export default connect(mapStateToProps)(TagContainer);