import React, { Component } from 'react'
import {Modal, Button} from 'antd'

export default class CreateNewModal extends Component {
    
    state = {
        loading  : false
    }
    
    setModalVisibility = (val) => {
        this.props.toggleModal(val)
    }

    handleSubmit = () => {
        this.setState({ loading: true });
        setTimeout(() => {
            this.props.onSubmit();
            this.setState({ loading: false});
        }, 700)
    }

    render() {
        let title                               = this.props.title || 'Text goes here'
        let {modalVisible,btnText, closable}    = this.props
        return (
            <Modal
                maskClosable= {false}
                className   = 'custom-modal'
                centered
                title       = {title}
                visible     = {modalVisible}
                onCancel    = {e=>this.setModalVisibility(false)}
                closable	= {closable}
                footer={[
                    <Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleSubmit}>
                        {btnText}
                    </Button>
                ]}
            >
                {this.props.children}
            </Modal>
        )
    }
}
