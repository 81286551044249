import React, { Component } from 'react'
import {Select} from 'antd'
import { connect } from 'react-redux'
import { fetchRecordRequest } from '../../../record/duck/RecordActions';

class Dataset extends Component {

    state = {
        dropdownValue: (this.props.activeTask && this.props.activeTask.fields && this.props.activeTask.fields[this.props.field.id])
            ? this.props.activeTask.fields[this.props.field.id].value
            : null
    }

    componentDidMount() {
        let { field } = this.props;
        if (field && field.id) {
            fetchRecordRequest({id: field.id});
        }
    }

    componentDidUpdate(prevProps){
        let {activeTask, field} = this.props
        if(activeTask && activeTask.fields && activeTask.fields[field.id]){
            if(activeTask.fields[field.id].value !== prevProps.activeTask.fields[field.id].value){
                this.setState({dropdownValue: activeTask.fields[field.id].value})
            }
        }
    }

    handleDropdown = (options, fieldId) =>{
        this.setState({dropdownValue: options})
        this.props.addValue(fieldId, options)
    }


    render() {
        let {field, mRecords, mDataset, showError, showErrorAlert, users} = this.props;
        let { dropdownValue } = this.state;
        let selectedRecord;

        if (dropdownValue && Array.isArray(mRecords) && mRecords.length > 0) {
            selectedRecord = mRecords.find(r => r.id === dropdownValue);
        }

        // console.log("columnsSelected", field && field.columnsSelected);
        // console.log("selectedRecord", selectedRecord);

        return (
            <>
                <Select
                    getPopupContainer={(e)=>e.parentNode}
                    allowClear
                    mode        = {field.multi_entry ? 'multiple' : 'single'}
                    className   = {showErrorAlert && showError ? 'error custom-select fw' : 'custom-select fw'}
                    size        = 'large'
                    placeholder = {field.alias_help_text ? field.alias_help_text : field.helpText}
                    value       = {this.state.dropdownValue || []}
                    onChange    = {e=>this.handleDropdown(e, field.id)}
                >
                    {
                        field.options && field.options.map(opt=>{
                            return <Select.Option key={opt.id} value={opt.id}>
                                {opt.value}
                            </Select.Option>
                        })
                    }
                </Select>

                {
                    selectedRecord && selectedRecord.attributes && Array.isArray(selectedRecord.attributes.sections) &&
                    selectedRecord.attributes.sections.length > 0 &&
                    <table className='fw mt-5' style={{ borderRadius:4 }}>
                        <tbody>
                            {
                                selectedRecord.attributes.sections.map(section => {
                                    let rowSpan = section.section_layout.length;
                                    if (field && Array.isArray(field.columnsSelected) && field.columnsSelected.length > 0) {
                                        let columnsSelected = section.section_layout.filter(l => field.columnsSelected.includes(l.i));
                                        rowSpan = columnsSelected && columnsSelected.length || 0;
                                    }

                                    return section.section_layout.map((l, index) => {
                                        if (field.columnsSelected.length === 0 || field.columnsSelected.includes && field.columnsSelected.includes(l.i)) {
                                            let field = mDataset && mDataset.attributes && mDataset.attributes.fields[l.i] && mDataset.attributes.fields[l.i];
                                            let mField = selectedRecord.attributes.fields[l.i];

                                            return(
                                                <tr key={`${index}`}>
                                                    {
                                                        index === 0 &&
                                                        <td
                                                            style={{ border: "1px solid #d9d9d9", padding: "15px", width:'20%' }}
                                                            rowSpan={`${rowSpan}`}
                                                        >{section.section_name}</td>
                                                    }
                                                    <td style={{ border: "1px solid #d9d9d9", padding: "15px", width:'25%' }} >{field && (field.alias_name || field.name)}</td>
                                                    <td style={{ border: "1px solid #d9d9d9", padding: "15px", width:'55%' }} >
                                                        {
                                                            field && field.dataType === 'people' 
                                                            ?
                                                            <>{mField && mField.value && mField.value.row_id && users && users.find(u => u.id === mField.value.row_id).attributes.name}</>
                                                            :
                                                            <>{mField && mField.value && mField.value.row_id}</>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    })
                                })
                            }
                        </tbody>
                    </table>
                }

            </>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let { field } = ownProps;
    let mDatasetData = field && field.id && state.record[field.id];
    let mRecords = mDatasetData && mDatasetData.list && mDatasetData.list.map(id => state.record.records[id]);
    let mDataset = state.dataset.dataset && state.dataset.dataset.data && Array.isArray(state.dataset.dataset.data) && state.dataset.dataset.data.length > 0
        && state.dataset.dataset.data.find(d => d.id === field.id);
    let searchableField = field && field.searchableField;
    let opts = [];

    if (Array.isArray(mRecords) && mRecords.length > 0) {
        if (!searchableField) {
            searchableField = mRecords[0].attributes.sections[0].section_layout[0].i;
        }

        mRecords.map(r => {
            let value = r && r.attributes && r.attributes.fields && r.attributes.fields[searchableField].value && r.attributes.fields[searchableField].value.row_id;
            opts.push({id: r.id, value});
        });

        // console.log("field", field);
        // console.log("mRecords", mRecords);
        // console.log("searchableField", searchableField);
        // console.log("opts", opts);
    }

    field.options = opts

    return {
        field: field,
        mRecords: mRecords,
        mDataset: mDataset,
        users: state.users && state.users.data && state.users.data.data
    };
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Dataset);