// import { put } from 'redux-saga/effects';
import ACTIONS from "./TeamsActionTypes";
import {store} from '../../config/Store';

export function fetchTeamsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_REQUEST, payload:params})
}
export function fetchMoreTeamsRequest(params) {
    store.dispatch({type:ACTIONS.FETCH_MORE_REQUEST, payload:params})
}
export function createTeamRequest(userInfo) {
    store.dispatch({type:ACTIONS.CREATE_REQUEST, payload:userInfo})
}
export function updateTeamRequest(id,data) {
    store.dispatch({type:ACTIONS.UPDATE_REQUEST, payload:{id,data}})
}
export function deleteTeamRequest(id) {
    store.dispatch({type:ACTIONS.DELETE_REQUEST, payload:id})
}

