import React from 'react';
import { withRouter } from 'react-router-dom';
import {Menu} from 'antd';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

const Navigation = (props) => {

    const [openKeys, setOpenKeys] = React.useState(["item_1", "item_2"]);
    const { location } = props;

    return (
        <Menu
            // style       = {{overflowY: "scroll", overflowX: "hidden"}}
            className   = 'fh app-sidebar-menu'
            mode        = "inline"
            selectedKeys= {[location.pathname]}
            openKeys    = {openKeys}
            onOpenChange= {setOpenKeys}
        >
            <Menu.Item key="/app/users">
                <Link to='/app/users'>
                    <span>Organisation</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/app/projects">
                <Link to='/app/projects'>
                    <span>Projects</span>
                </Link>
            </Menu.Item>

            <Menu.Item key="/app/processes">
                <Link to='/app/processes'>
                    <span>Processes</span>
                </Link>
            </Menu.Item>
            {/* <Menu.Item key='pages'>
                <Link to='/app/pages'>
                <span>Pages</span>
                </Link>
            </Menu.Item> */}
            <Menu.Item key="/app/fields">
                <Link to='/app/fields'>
                    <span>Fields</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/app/tags">
                <Link to='/app/tags'>
                    <span>Tags</span>
                </Link>
            </Menu.Item>

            <Menu.Item key="/app/dataset">
                <Link to='/app/dataset'>
                    <span>Dataset</span>
                </Link>
            </Menu.Item>
        </Menu>
            // <Menu className='fh app-sidebar-menu' mode="inline">
                
            // </Menu>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser:state.currentUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout(){
            dispatch({type:'LOGOUT_REQUEST'})
        }
    }
}

// const Navbar = withRouter(Navigation)
// export default connect(mapStateToProps,mapDispatchToProps)(Navigation);

const ConnectedNavigation = connect(mapStateToProps, mapDispatchToProps)(Navigation);

export default withRouter((props) => <ConnectedNavigation {...props} />);

