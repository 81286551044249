import React, {Component} from 'react';
import {Row, Select, Icon} from "antd";
import {connect} from "react-redux";

class ChartList extends Component {
    render() {
        let {chartList, selectedChart,onSelectChart,rowStyle, chartNotSelected} = this.props;
        chartList = chartList.map((chart,key)=>{
            return  <Select.Option key={key} value={chart.type}><Icon style={{fontSize:'18px'}} className='mr-10' type={chart.icon}/>{chart.type}</Select.Option>
        })

        return (
            <Row style={rowStyle}>
                <label className='f15' style={{color:'#8697A8'}}>Chart type</label>
            <Select getPopupContainer={(e)=>e.parentNode} className='fw custom-select' placeholder='Select chart type' size='large' labelInValue value={selectedChart} style={{marginTop:'12px'}}
                    suffixIcon={chartNotSelected ? <Icon type="exclamation-circle" style={{color:'red'}} /> : null}
                    onSelect={onSelectChart}>
               {chartList}
            </Select>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state
    }
}

export default connect(mapStateToProps)(ChartList);
