import React from 'react';
import {Row, Dropdown, Menu, Select, Icon, Col} from 'antd';
import AddNewButton from "../../utils/addNewButton";
const {Option} = Select;

const Header = (props)=>{
    function onSearch(val) {
        console.log('search:', val);
    }

    let list = {}, editable = true;
    let menu = (
        <Menu>
            <Menu.Item key="0" onClick={()=>props.handleView('viewTeam')}>
                Edit team settings
            </Menu.Item>
            <Menu.Item key="1" onClick={()=>props.handleView('deleteTeam', props.selectedTeam[0].id)}>
                Delete this team
            </Menu.Item>
        </Menu>
    );

    if(props.selected === 'all'){
        editable = false;
        menu = (<></>);
        list.name = "Organisation";
        list.descriptions = "All member present in the organisation."
    } else {
        list.name = props.selectedTeam[0].name;
        list.descriptions = props.selectedTeam[0].attributes.descriptions;
    }

    return (
        <Row type='flex' justify='space-between'>
                    <span>
                        <Row type='flex' justify='start'>
                            <Col style={{marginLeft: 15}}>
                                <Row>
                                    <span style={{fontSize: 20, color: '#44566C', fontWeight: 'bold'}}>{list.name}</span>
                                    <Dropdown overlay={menu}>
                                        <Icon style={{marginLeft: 30, fontSize: 16}} type={editable ? 'more': null}/>
                                    </Dropdown>
                                </Row>
                                <span className='truncate' style={{fontSize: 12}}>{list.descriptions}</span>
                            </Col>
                        </Row>
                    </span>
            <span style={{marginRight: 20}}>
                <Select
                    getPopupContainer={(e)=>e.parentNode}
                    showSearch
                    style={{width: 200}}
                    placeholder="Select a person"
                    size='large'
                    className='select-box'
                    onSearch={onSearch}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(e)=>{
                        e === 'addTeam' ? 
                            props.handleView('addTeam', e) : 
                            e === 'seemore' ? props.loadMoreTeam() : props.handleView('selectedTeam', e)}
                    }
                    value={props.selected}
                >
                    <Option value="all">All Member</Option>
                    {props.team && props.team.data.length > 0 && props.team.data.map(team => {
                        return (
                            <Option key={team.id}>{team.name}</Option>
                        )
                    })}
                    {props.team && props.team.data.length > 30 && <Option key={'seemore'} style={{color: '#0081FF'}}>See more...</Option>}
                    <Option key={'addTeam'} style={{color: '#0081FF'}}>+ Add new team</Option>
                </Select>
                <AddNewButton disabled={editable} trigger={()=>props.handleView('addPeople')}/>
            </span>
        </Row>
    );

}
export default Header