import React, { Component } from 'react'
import {Drawer, Tabs} from 'antd'
import Permission from './permission'
import Execution from './execution'
import Assignment from './assignment'
import Actions from './actions'
import Deadline from './deadline'
import Triggers from './triggers'

export default class WorkflowSettings extends Component {

    setFieldProperties = (obj, id, isSection) => {
        let steps       = Object.assign({},this.props.steps)
        let activeStep  = steps[this.props.activeStep.id]
        if(isSection){
            activeStep.sections[id] = obj
        }else{
            activeStep.fields[id]   = obj
        }
        this.props.updateSteps(steps)
    }

    render() {
        let {openPanel, togglePanel, activeStep, updateSteps, steps} = this.props
        // console.log('activeStep', activeStep)
        return (
            <Drawer
                width       = {'50%'}
                placement   = "right"
                closable    = {false}
                onClose     = {e=>togglePanel(false)}
                visible     = {openPanel}
            >
                <Tabs defaultActiveKey="permission" className='custom-tabs' >
                    <Tabs.TabPane tab="Permission" key="permission">
                        <Permission 
                            sections            = {this.props.sections}
                            allFields           = {this.props.allFields}
                            activeStep          = {activeStep}
                            setFieldProperties  = {this.setFieldProperties}
                        />
                    </Tabs.TabPane>
                    {
                        !activeStep.start &&
                            <Tabs.TabPane tab="Execution" key="execution" disabled={activeStep.start} >
                                <Execution 
                                    activeStep  = {activeStep}
                                    allFields   = {this.props.allFields}
                                    steps       = {steps}
                                    updateSteps = {updateSteps}
                                    sections    = {this.props.sections}
                                    
                                />
                            </Tabs.TabPane>
                    }
                    {
                        !activeStep.start &&
                            <Tabs.TabPane tab="Assignment" key="assignment" disabled={activeStep.start} >
                                <Assignment 
                                    users       = {this.props.users}
                                    activeStep  = {activeStep}
                                    steps       = {steps}
                                    updateSteps = {updateSteps}
                                />
                            </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab="Actions" key="actions">
                        <Actions 
                            activeStep  = {activeStep}
                            steps       = {steps}
                            updateSteps = {updateSteps}
                        />
                    </Tabs.TabPane>
                    {
                        !activeStep.start &&
                            <Tabs.TabPane tab="Deadline" key="deadline">
                                <Deadline 
                                    activeStep  = {activeStep}
                                    steps       = {steps}
                                    updateSteps = {updateSteps}
                                />
                            </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab="Triggers" key="triggers">
                        <Triggers 
                            activeStep  = {activeStep}
                            steps       = {steps}
                            updateSteps = {updateSteps}
                            allFields   = {this.props.allFields}
                        />
                    </Tabs.TabPane>
                </Tabs>

                {/* <div
                        style={{
                        position: 'absolute',
                        left: 0,
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #e9e9e9',
                        padding: '10px 16px',
                        background: '#fff',
                        textAlign: 'right',
                    }}
                >
                    <Button size='large' className='mr-20' style={{color:'#0081FF',background:'',width:110}}>
                        Cancel
                    </Button>
                    <Button size='large' type="primary" style={{width:110}}>
                        Next
                    </Button>
                </div> */}
            </Drawer>
        )
    }
}
