import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router'
// import { Modal } from 'antd';
import ProjectCreatorModal from './components/ProjectCreatorModal/';
import { fetchProjectTemplates, createNewProject } from './duck/ProjectActions';
import { fetchCategories } from '../categories/duck/CategoriesActions';

class ProjectCreatorContainer extends React.Component {

  state = {
    modalVisible: false,
    creating: false,
    redirect: false
  }

  componentDidMount() {
    this.props.fetchCategories();
  }

  componentDidUpdate(prevProps) {
    if (this.state.creating && this.props.project !== prevProps.project) {
      this.setState({
        creating: false,
        redirect: true
      })
    }
  }

  showModal = () => {
    this.setState({
      modalVisible: true,
      redirect: false,
      creating: false,
    })
  }

  hideModal = () => {
    this.setState({
      modalVisible: false
    })
  }

  createNewProject = (project) => {
    this.setState({
      modalVisible: false,
      creating: true
    })
    this.props.createNewProject(project);
  }

  render() {
    const { modalVisible, redirect } = this.state;
    const { fetchProjectTemplates, categories, templates, project } = this.props;
    return (
      <>
      {redirect && <Redirect to={`/app/projects/${project._id}`} />}
        <a href="#" onClick={this.showModal}>Project</a>
        <ProjectCreatorModal
          visible={modalVisible}
          onCancel={this.hideModal}
          templates={templates}
          fetchProjectTemplates={fetchProjectTemplates}
          createNewProject={this.createNewProject}
          categories={categories}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    templates: state.project.templates,
    project: state.project.active && state.project.projects[state.project.active],
    categories: state.categories.categories && Object.values(state.categories.categories),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjectTemplates: (props) => dispatch(fetchProjectTemplates(props)),
    createNewProject: (props) => dispatch(createNewProject(props)),
    fetchCategories: () => dispatch(fetchCategories()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreatorContainer);
