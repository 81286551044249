const ACTIONS = {
    
    FETCH_REQUEST       : 'FETCH_TAGS_REQUEST',
    FETCH_SUCCESS       : 'FETCH_TAGS_SUCCESS',
    FETCH_FAIL          : 'FETCH_TAGS_FAIL',
    
    FETCH_ONE_REQUEST   : 'FETCH_ONE_TAG_REQUEST',
    FETCH_ONE_SUCCESS   : 'FETCH_ONE_TAG_SUCCESS',
    FETCH_ONE_FAIL      : 'FETCH_ONE_TAG_FAIL',
    
    FETCH_MORE_REQUEST  : 'FETCH_MORE_TAGS_REQUEST',
    FETCH_MORE_SUCCESS  : 'FETCH_MORE_TAGS_SUCCESS',
    FETCH_MORE_FAIL     : 'FETCH_MORE_TAGS_FAIL',
    
    CREATE_REQUEST      : 'CREATE_TAG_REQUEST',
    CREATE_SUCCESS      : 'CREATE_TAG_SUCCESS',
    CREATE_FAIL         : 'CREATE_TAG_FAIL',
    
    UPDATE_REQUEST      : 'UPDATE_TAG_REQUEST',
    UPDATE_SUCCESS      : 'UPDATE_TAG_SUCCESS',
    UPDATE_FAIL         : 'UPDATE_TAG_FAIL',

    DELETE_REQUEST      : 'DELETE_TAG_REQUEST',
    DELETE_SUCCESS      : 'DELETE_TAG_SUCCESS',
    DELETE_FAIL         : 'DELETE_TAG_FAIL',
}

 export default ACTIONS;